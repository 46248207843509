import React, { useState, useEffect, useMemo } from 'react';
import LogoImage from "../../assets/images/common/kistore_logo.svg";
import { useNavigate, Link } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';
import { getCheckoutSimple } from '../../services/GQ_apis/checkout';
import { useLanguage } from '../../services/languageContext';

const TopNav = () => {

  const { user, globalObserver } = useAuthStore();
  const { language, toggleLanguage } = useLanguage(); // 언어 상태와 언어 변경 함수 가져오기

  const navigate = useNavigate();
  const [showTabs, setShowTabs] = useState(false);
  const [shoppingItems, setShoppingItems] = useState([]); // 장바구니 리스트
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  // const [me, setMe] = useMemo({});

  const me = useMemo(()=>{
    const meData = JSON.parse(localStorage.getItem('me'));
    return meData;
  },[localStorage.getItem('me')])

 
  const toggleTabs = () => {
    setShowTabs(!showTabs);
  };

  // 배송지 리스트 불러오는 메소드
  const fetchShopping = () => {
    const vendorIds = Object.keys(localStorage).filter(key => key.startsWith('checkoutToken_'));
    const checkoutPromises = vendorIds.map(vendorKey => {
      const checkoutToken = localStorage.getItem(vendorKey);
      return getCheckoutSimple(checkoutToken);
    });
    Promise.all(checkoutPromises).then(results => {
      const allItems = [];
      results.forEach((res, index) => {
        const vendorId = vendorIds[index].split('checkoutToken_')[1];
        const itemsWithVendorInfo = res.data.checkout.lines.map(item => ({
          ...item,
          checkoutId: res.data.checkout.id,
          vendorId: vendorId,
          isSelected: true
        }));
        allItems.push(...itemsWithVendorInfo);
      });
      setShoppingItems(allItems);
    }).catch(error => {
      console.error('Error fetching checkout data: ', error);
    });
  };

  useEffect(() => {
    console.log('globalObserver update');
    fetchShopping();
  }, [globalObserver,me])

  // Mounted[START] ------------------------------------ 
  useEffect(() => {
    document.addEventListener("mousedown", setShowTabs(false));
    return () => {
      document.removeEventListener("mousedown", setShowTabs(false));
    };
  }, []);
  // Mounted[END] --------------------------------------

  // 언어 변경 시 선택 버튼 창을 닫도록 수정하고 로딩 상태 적용
  const handleLanguageChange = async (lang) => {
    setIsLoading(true);  // 로딩 상태 시작
    await toggleLanguage(lang); // toggleLanguage 완료 후에 다음 작업 수행
    window.location.reload(); // 페이지 새로고침
    setShowTabs(false); // 언어 선택 후 창 닫기
  };

  return (
    <div className="top-bar">
      <div className="top-bar-inner">
        <Link to="/" title="zaezae 메인으로 이동">
          <h1 id="logo">
            <img src={LogoImage} alt="zaezae" />
          </h1>
        </Link>
        <div className="actions">
          <button className="icon-search" type="button" onClick={() => navigate('/search/process')}></button>
          <div className="language-wrap">
            <button className="icon-language" type="button" onClick={toggleTabs}></button>
            {showTabs && (
              <>
                <ul className="tabs">
                  <li className="tab">
                    <button type="button" onClick={() => handleLanguageChange('KO')}>한국상점</button>
                  </li>
                  <li className="tab">
                    <button type="button" onClick={() => handleLanguageChange('EN')}>Global</button>
                  </li>
                </ul>
                <div className="dim white" onClick={() => setShowTabs(false)}></div>
              </>
            )}
          </div>
          <button className="icon-notification" type="button" onClick={() => navigate('/alert')}></button>
          {
            me?.data?.me?.isStaff &&
            <button className="icon-b2b" type="button" onClick={() => navigate('/biz')}></button>
          }
          {
            localStorage.getItem('user') && user && user.checkout ? (
              <button className="icon-shoppingbag" type="button" onClick={() => navigate('/pay/shopping')}>
                {shoppingItems.length > 0 && <span className="num">{shoppingItems.length}</span>}
              </button>
            ) : (
              <button
                className="icon-shoppingbag"
                type="button"
                onClick={() => navigate('/login/main2')}
              >
              </button>
            )
          }
        </div>
      </div>
      {isLoading && (
        <div className="loading-wrap">
          <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
          </svg>
        </div>
      )}
    </div>
  );
};

export default TopNav;
