import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';
import { getCheckoutSimple } from '../../services/GQ_apis/checkout';

const TitleActionBar = ({ title = '타이틀을 입력해주세요.' }) => {
  const navigate = useNavigate();
  const { user, globalObserver } = useAuthStore();
  const [shoppingItems, setShoppingItems] = useState([]);
  const [canGoBack, setCanGoBack] = useState(true);  // 상태 추가: 뒤로가기 여부를 제어
  const [isLoading, setIsLoading] = useState(true); // 로딩 여부

  const goBack = () => {
    if (canGoBack && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  // const fetchDelivery = () => {
  //   if (!localStorage.getItem('cartToken')) return;
  //   getCheckoutSimple(localStorage.getItem('cartToken')).then((res) => {
  //     if (res.data.checkout) setShoppingItems(res.data.checkout.lines);
  //   });
  // };

  // 배송지 리스트 불러오는 메소드
  const fetchShopping = () => {
    const vendorIds = Object.keys(localStorage).filter(key => key.startsWith('checkoutToken_'));
    const checkoutPromises = vendorIds.map(vendorKey => {
      const checkoutToken = localStorage.getItem(vendorKey);
      return getCheckoutSimple(checkoutToken);
    });
    Promise.all(checkoutPromises).then(results => {
      const allItems = [];
      results.forEach((res, index) => {
        const vendorId = vendorIds[index].split('checkoutToken_')[1];
        const itemsWithVendorInfo = res.data.checkout.lines.map(item => ({
          ...item,
          checkoutId: res.data.checkout.id,
          vendorId: vendorId,
          isSelected: true
        }));
        allItems.push(...itemsWithVendorInfo);
      });
      setShoppingItems(allItems);
    }).catch(error => {
      console.error('Error fetching checkout data: ', error);
    });
  };

  useEffect(() => {
    fetchShopping();
    console.log('globalObserver update');
  }, [globalObserver]);

  return (
    <div className="top-bar">
      <div className="top-bar-inner">
        <span onClick={goBack} title="클릭시 이전 페이지로" className="title" style={{ cursor: 'pointer' }}>
          {title}
        </span>
        <div className="actions">
          <button className="icon-search" type="button" onClick={() => navigate('/search/process')}></button>
          <button className="icon-notification" type="button" onClick={() => navigate('/alert')}></button>
          {
            localStorage.getItem('user') && user && user.checkout ?
              <button className="icon-shoppingbag" type="button" onClick={() => navigate('/pay/shopping')}>
                {shoppingItems.length > 0 && <span className="num">{shoppingItems.length}</span>}
              </button>
              :
              <button className="icon-shoppingbag" type="button" onClick={() => navigate('/login/main2')}></button>
          }
        </div>
      </div>
    </div>
  );
};


export default TitleActionBar;
