import React, { useEffect, useState } from "react";
import TopBar from '../../layouts/top_bar/TitleBar';
import IconSearch from '../../assets/images/common/icons/icon_alert.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPages } from "../../services/GQ_apis/page";
import { meData } from "../../services/GQ_apis/me";
import { getAlertByEmail, readAlert } from "../../services/REST_apis/alert";
import { useLanguage } from '../../services/languageContext';

function AlertPage() {
  const navigate = useNavigate();
  const [me, setMe] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const { getTranslatedNameById, language } = useLanguage();
  const location = useLocation();

  // 번역
  const ORDER_SHIPPING = language === 'KO' ? '주문/배송' : 'Order/Shipping';
  const INQUIRY = language === 'KO' ? '1:1 문의' : '1:1 Inquiry';
  const PAGE_NOT_FOUND = language === 'KO' ? '존재하지 않는 페이지' : 'Page not found';

  // 사용자 정보 가져오기
  useEffect(() => {
    meData().then((res) => {
      setMe(res.data.me);
    });
  }, [me]);

  // 사용자 이메일로 알림 데이터 가져오기
  useEffect(() => {
    if (me.email) {
      getAlertByEmail(me.email).then((res) => {
        setData(res);
      }).finally(() => {
        setIsLoaded(true);
      });
    }
  }, [me]);

  const tabs = [
    { name: ORDER_SHIPPING, class: 'ship' }, // 주문/배송 필터
    { name: INQUIRY, class: 'inquiry' }, // 1:1 문의 필터
  ];

  // 초기 화면 설정
  useEffect(() => {
    if (data.length > 0) {
      const category = data.at(0).category === 'inquiry' ? INQUIRY : ORDER_SHIPPING;
      const nowData = data.filter((e) => e.category === data.at(0).category);
      setActiveTab(category);
      setPageData(nowData);
    }
  }, [data]);

  // 탭 클릭 핸들러
  const handleTabClick = (tab) => {
    setActiveTab(tab.name);

    // '주문/배송' 또는 'Order/Shipping'을 language에 맞춰 비교
    const isShippingTab = tab.name === ORDER_SHIPPING;
    const nowData = data.filter((e) => e.category === (isShippingTab ? 'ship' : 'inquiry'));
    setPageData(nowData);
  };

  // URL 파라미터를 통해 탭 초기화
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl = queryParams.get('tab') || 'ship';

    // URL에서 받은 탭 이름으로 활성화된 탭 설정
    setActiveTab(tabFromUrl === 'inquiry' ? INQUIRY : ORDER_SHIPPING);
    const nowData = data.filter((data) => data.category === tabFromUrl);
    setPageData(nowData);
  }, [location.search]);

  // 알림 클릭 핸들러
  const alertOnClick = (alert) => {
    if (alert.title || alert.product_id) {
      if (alert.is_active) {
        readAlert(alert.id).then((res) => res === 200 ? "" : console.log("오류"));
      }
      if (alert.category === "inquiry") {
        navigate(`/product/${alert.product_id}?focus=${window.btoa(`Page:${alert.target_id}`)}`, {
          state: { activeTab: 'inquiry' },
        });
      } else if (alert.category === "ship") {
        navigate(`/order/order-detail/${window.btoa(`Order:${alert.target_id}`)}`);
      }
    }
    console.log("alert", alert );
    console.log('alert.title', alert.title);

  };

  // 알림 메시지 언어별로 설정하는 함수
  const getTranslatedContent = (alert) => {
    if (alert.category === "inquiry") {
      return language === 'KO' ? '문의에 답변이 등록되었습니다.' : 'A reply has been posted.';
    } else if (alert.category === "ship") {
      if (alert.content === "배송이 완료되었습니다.") {
        return language === 'KO' ? '배송이 완료되었습니다.' : 'Delivery has been completed.';
      } else {
        return language === 'KO' ? '배송 상태가 업데이트되었습니다.' : 'Shipping status has been updated.';
      }
    }
    return alert.content;
  };

  return (
    <>
      <TopBar title={getTranslatedNameById("TWVudUl0ZW06NDcy")} />
      <section className="section-alert">
        {
          isLoaded ? (
            data.length > 0 ? (
              <>
                <div className="slide-tabs purple pl0 pr0 relative">
                  <div className="tabs">
                    {tabs.map((tab, index) => (
                      <button
                        key={index}
                        onClick={() => handleTabClick(tab)}
                        className={`w100p tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}>
                        {tab.name}
                      </button>
                    ))}
                  </div>
                </div>
                <ul className="alert-list mt54">
                  {
                    pageData.length > 0 ? pageData.map((alert, index) => {
                      return (
                        <li key={index}>
                          <button className="q" type="button" onClick={() => alertOnClick(alert)}>
                            <div className="title-wrap">
                              <span className="badge">
                                {alert.category === "ship" ? ORDER_SHIPPING : alert.category === "inquiry" ? INQUIRY : ""}
                              </span>
                              <div className="ml10">
                                {
                                  alert.is_active ? (
                                    <strong className="title is_active f18 mt5 flex left">
                                      {alert.title ? alert.title : PAGE_NOT_FOUND}
                                    </strong>
                                  ) : (
                                    <p className="title is-active-false f18 mt5 flex left">
                                      {alert.title ? alert.title : PAGE_NOT_FOUND}
                                    </p>
                                  )
                                }
                                <p className="f14 mt5 flex left tl">
                                  {getTranslatedContent(alert)}
                                  {/* {alert.content} */}
                                </p>
                              </div>
                            </div>
                            <span className="date">
                              {alert.created_at
                                ? new Date(alert.created_at).toLocaleDateString('ko-KR', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                                }).replace(/\./g, '. ').replace(/\.\s?$/, '') 
                                : "Unknown date"}
                            </span>
                          </button>
                        </li>
                      )
                    })
                      : (
                        <div className="inner pt78">
                          <p className="f14 mt10 flex center">{getTranslatedNameById("TWVudUl0ZW06NDcz")}</p> {/* 도착한 알림이 없습니다 */}
                        </div>
                      )
                  }
                </ul>
              </>
            ) : (<div className="inner pt78">
              <div className="img-wrap flex center">
                <img className="w120" src={IconSearch} alt={getTranslatedNameById("TWVudUl0ZW06NDcz")}></img> {/* 도착한 알림이 없습니다*/}
              </div>
              <strong className="f22 mt20 flex center">{getTranslatedNameById("TWVudUl0ZW06NDcz")}</strong> {/* 도착한 알림이 없습니다 */}
              <p className="f14 mt10 flex center">{getTranslatedNameById("TWVudUl0ZW06NDc0")}</p> {/* 알림을 받기 위해서는 설정에 들어가 주세요. */}
            </div>)
          ) : (
            <div className="loading-wrap flex center">
              <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
              </svg>
            </div>
          )
        }
      </section>
    </>
  );
}

export default AlertPage;
