import React from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용
import LogoImage from "../../assets/images/common/kistore_logo.svg";

const LoginMain01Section = () => {
  const navigate = useNavigate();
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  return (
    <section className="section-login-main01">
      {/*  노란 배경 [START] */}
      <div className="bg-yellow">
        <div className="images-wrap">
          {/* <span className="icon-logo-white">

          </span> */}
          <h1 id="logo" className="icon-logo-white">
            <img src={LogoImage} alt="zaezae" />
          </h1>
          
          <span className="icon-duck">
          </span>
        </div>
        <div className="btn-wrap">
          <div className="btns">
            <button onClick={() => navigate('/login/main2')} type="button" className="btn white">
              {getTranslatedNameById("TWVudUl0ZW06Mjc1")} {/* 로그인 */}
            </button>
            <button onClick={() => navigate('/register/1')} type="button" className="btn white">
              {getTranslatedNameById("TWVudUl0ZW06NDc2")} {/* 회원가입 */}
            </button>
          </div>
        </div>
      </div>
      {/*  노란 배경 [END] */}
      {/*  하얀 배경 [START] */}
      <div className="bg-white">
        {/* <div className="btn-wrap">
          <div className="title">
            {getTranslatedNameById("TWVudUl0ZW06NDc3")} 
          </div>
          <div className="btns">
            <button type="button" className="btn gray">
              {getTranslatedNameById("TWVudUl0ZW06NDc4")}
            </button>
            <button type="button" className="btn gray">
              {getTranslatedNameById("TWVudUl0ZW06NDc5")} 
            </button>
          </div>
        </div> */}
        <div className="mt30 btn-wrap">
          <div className="title">
            {getTranslatedNameById("TWVudUl0ZW06NDgw")} {/* 고객센터 */}
          </div>
          <div className="btns">
            <button onClick={() => navigate('/customer-center?tab=notice')} type="button" className="btn gray">
              {getTranslatedNameById("TWVudUl0ZW06NDgx")} {/* 공지사항  */}
            </button>
            <button onClick={() => navigate('/customer-center?tab=faq')} type="button" className="btn gray">
              FAQ 
            </button>
          </div>
        </div>
        <Link className="under-line" to="/" title="">
          {getTranslatedNameById("TWVudUl0ZW06NDgz")} {/* 혜택 받지 않고 둘러보기  */}
        </Link>
      </div>
      {/*  하얀 배경 [END] */}
    </section>
  );
};

export default LoginMain01Section;
