import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import sampleImage from "../../assets/images/sample/sample_nike.svg";
import { useNavigate } from 'react-router-dom';

const BrandTab = ({ title ="타이틀", tabs=[] }) => {
  // tabs가 배열인지 확인하고, 그렇지 않으면 빈 배열로 초기화
  const safeTabs = Array.isArray(tabs) ? tabs : [];

  const navigate = useNavigate();
  const channel = window.WSgetChannel();

  return (
    <div className="inner">
      <div className="h5-title-wrap">
        <h5 className="title">
          {title}
        </h5>
      </div>
      
      <Swiper
        modules={[Autoplay, FreeMode]}
        spaceBetween={16}
        slidesPerView={5.5}
        loop={false}
        freeMode={true}
        className="brand-tabs"
        nested={true}
        touchMoveStopPropagation={true}
      >
        {safeTabs.map((tab, index) => {
          const storeName = (channel === 'KO' || !tab.store_name_en) ? tab.store_name_ko : tab.store_name_en;
          

          return (
            <SwiperSlide key={tab.id || index} onClick={() => navigate(`/brand/detail/${tab.id}`)}>
              <button type="button" className="tab">
                <div className="img-wrap">
                  <img src={tab.vendor_thumbnail || sampleImage} alt={storeName} />
                </div>
                <div className="title mb0">
                  {storeName}
                </div>
              </button>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default BrandTab;
