import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import H3Title from '../../../layouts/title/H3Title';
import GridSection from '../../../sections/grid/GridSection';
import SwipeGridSection from '../../../sections/grid/SwipeGridSection';
import BannerASection from '../../../sections/banner/BannerASection';
import BannerCSection from '../../../sections/banner/BannerCSection';
import BannerESection from '../../../sections/banner/BannerESection';

import { getCollection } from '../../../services/GQ_apis/collection';
import { useLanguage } from '../../../services/languageContext';

const slugs = [
  'hotdeal1',
  'hotdeal2',
  'hotdeal3',
  'hotdeal4'
];

function HotdealPage() {
  const navigate = useNavigate();
  const [collection, setCollection] = useState([]);
  const { getTranslatedNameById } = useLanguage();
  
  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = async () => {
    try {
      const res = await getCollection(slugs);
      const data = res.data.collections.edges;

      const formattedData = data.map(el => {
        let title = getTranslatedNameById('TWVudUl0ZW06Mjkx');
        if (el.node.description) {
          const parsedDescription = JSON.parse(el.node.description);

          title = parsedDescription.blocks
            .map(block => block.data.text)
            .join('<br>');
        }
        
        return {
          title: title,
          id: el.node.id
        };
      });

      setCollection(formattedData);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  return (
    <article id="hotdealPage" className="pt74">
      {/* HOTDEAL 1 영역 [START] */}
      <div className="white-wrap">
        {collection[0] && (
          <>
            <div className="banner-wrap vw100 mb20">
              <BannerESection id='hotdeal_b1'/>
            </div>
            <H3Title title={collection[0].title}/>
            <GridSection 
              type="big" 
              option={false}
              collection={collection[0].id}
            />
            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={()=>navigate(`/product-list?id=${collection[0].id}&title=${collection[0].title}`)}
            >
              {getTranslatedNameById('TWVudUl0ZW06Mjcz')}
            </button>
          </>
        )}
      </div>
      {/* HOTDEAL 1 영역 [END] */}

      {/* HOTDEAL 2 영역 [START] */}
      <div className="white-wrap">
        {collection[1] && (
          <>
            <H3Title title={collection[1].title}/>
            <div className="banner-wrap vw100 mb20">
              <BannerESection id='hotdeal_b2'/>
            </div>
            <GridSection 
              type="big" 
              option={false}
              collection={collection[1].id}
            />
            <button 
              type="button" 
              className="btn big round more mt40"
              onClick={()=>navigate(`/product-list?id=${collection[1].id}&title=${collection[1].title}`)}
            >
              {getTranslatedNameById('TWVudUl0ZW06Mjcz')}
            </button>
          </>
        )}
      </div>
      {/* HOTDEAL 2 영역 [END] */}

      {/* HOTDEAL 3 영역 [START] */}
      <div className="white-wrap">
        {collection[2] && (
          <>
            <H3Title title={collection[2].title}/>
            <div className="banner-wrap vw100 mb20">
              <BannerESection id='hotdeal_b3'/>
            </div>
            <div className="banner-wrap vw100 mb20">
              <BannerCSection id='hotdeal_b4'/>
            </div>
            <SwipeGridSection 
              collection={collection[2].id}
            />
            <div className="banner-wrap mt20 mb20">
              <BannerESection id='hotdeal_b5'/>
            </div>
          </>
        )}
      </div>
      {/* HOTDEAL 3 영역 [END] */}

      {/* HOTDEAL 4 영역 [START] */}
      <div className="white-wrap">
        {collection[3] && (
          <>
            <H3Title title={collection[3].title}/>
            <div className="banner-wrap vw100 mb20">
              <BannerASection id='hotdeal_b6'/>
            </div>
            <SwipeGridSection 
              collection={collection[3].id}
            />
            <div className="banner-wrap vw100 mb20">
              <BannerASection id='hotdeal_b7'/>
            </div>
            <div className="banner-wrap vw100 mb20">
              <BannerASection id='hotdeal_b8'/>
            </div>
            <div className="banner-wrap vw100 mb20">
              <BannerASection id='hotdeal_b9'/>
            </div>
            <div className="banner-wrap vw100 mb20">
              <BannerASection id='hotdeal_b10'/>
            </div>
          </>
        )}
      </div>
      {/* HOTDEAL 4 영역 [END] */}
    </article>
  );
}

export default HotdealPage;
