import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import useMainStore from '../../store/Pages/useMainStore';
import MainLoginBeforeSection from '../../sections/main/MainLoginBeforeSection';
import useAuthStore from '../../store/common/useAuthStore';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import TopBar from '../../layouts/top_bar/TopNav';
import BottomNav from '../../layouts/bottom_bar/BottomNav';
import FAB from '../../components/Buttons/FAB';

import HomePage from '../../pages/main/home/HomePage';
// import HotPage from '../../pages/main/hot/HotPage';
import HotdealPage from '../../pages/main/hotdeal/HotdealPage';
import RankingPage from '../../pages/main/ranking/RankingPage';
import BrandPage from '../../pages/main/brand/BrandPage';
import OverseasPage from '../../pages/main/overseas/OverseasPage';
import CouponBenefitPage from '../../pages/main/couponBenefit/CouponBenefitPage';
import { useLanguage } from '../../services/languageContext';

// ID 상수 선언
const TAB_IDS = {
  HOME: 'TWVudUl0ZW06MjYw',
  HOTDEAL: 'TWVudUl0ZW06MjYx',
  RANKING: 'TWVudUl0ZW06MjYy',
  COUPON_BENEFIT: 'TWVudUl0ZW06MjY0',
  BRAND: 'TWVudUl0ZW06MjY1',
};

const MainPage = () => {
  const { getTranslatedNameById } = useLanguage();

  // 탭 정보 배열
  const tabs = useMemo(() => [
    { id: TAB_IDS.HOME, class: 'home', route: '/' },
    { id: TAB_IDS.HOTDEAL, class: 'hotdeal', route: '/hotdeal' },
    { id: TAB_IDS.RANKING, class: 'ranking', route: '/ranking' },
    { id: TAB_IDS.COUPON_BENEFIT, class: 'couponBenefit', route: '/couponBenefit' },
    { id: TAB_IDS.BRAND, class: 'brand', route: '/brand' },
  ], []);

  // 기본 탭 이름을 하드코딩한 함수 추가
  const getDefaultTabName = (id) => {
    switch (id) {
      case TAB_IDS.HOME:
        return 'HOME';
      case TAB_IDS.HOTDEAL:
        return '핫딜';
      case TAB_IDS.RANKING:
        return '랭킹';
      case TAB_IDS.COUPON_BENEFIT:
        return '쿠폰 혜택';
      case TAB_IDS.BRAND:
        return '브랜드';
      default:
        return '탭';
    }
  };

  const translatedTabs = useMemo(() => tabs.map(tab => ({
    ...tab,
    name: getTranslatedNameById(tab.id) || getDefaultTabName(tab.id), // 번역된 이름이 없으면 기본값 사용
  })), [tabs, getTranslatedNameById]);

  const { userToken } = useAuthStore();
  const { currentPage, activeTab, setCurrentPage, setActiveTab } = useMainStore();
  const [swiper, setSwiper] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const pages = [
    <HomePage />,
    <HotdealPage />,
    <RankingPage />,
    // <OverseasPage />,
    <CouponBenefitPage />,
    <BrandPage />,
  ];

  useEffect(() => {
    const currentTab = translatedTabs.find(tab => location.pathname === tab.route);
    const pageIndex = currentTab ? translatedTabs.indexOf(currentTab) : 0;
    setCurrentPage(pageIndex);
    setActiveTab(currentTab ? currentTab.name : 'HOME');
    if (swiper) swiper.slideTo(pageIndex, 0);
  }, [location, swiper, setCurrentPage, setActiveTab, translatedTabs]);

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
    setActiveTab(translatedTabs[pageIndex].name);
    navigate(translatedTabs[pageIndex].route);
  };

  const handleTabClick = (tab) => {
    const pageIndex = translatedTabs.findIndex((t) => t.name === tab.name);
    setCurrentPage(pageIndex);
    setActiveTab(tab.name);
    swiper?.slideTo(pageIndex);
  };

  return (
    <>
      <TopBar />
      <div className="home-slide-tabs">
        <div className="tabs">
          {translatedTabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(tab)}
              className={`tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
      <div id="mainSlidePage">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoHeight={true}
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={(swiper) => handlePageChange(swiper.activeIndex)}
          className="layout-slide-wrap"
          loop={false}
          initialSlide={currentPage}
        >
          {pages.map((page, index) => (
            <SwiperSlide key={index}>
              {page}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <FAB />
      <BottomNav />
    </>
  );
};

export default MainPage;
