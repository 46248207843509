import React, { useEffect, useState } from 'react';
import useInputStore from '../../store/common/useInputStore'
import { useNavigate } from "react-router-dom";
import entryStore from './../../store/Pages/useEntryStore';
import api from '../../services/api'
import { sendEmail, confirmCode } from '../../services/SEND_apis'

const VendorRegister04Section = () => {

  const navigate = useNavigate();
  const { applicationData, setApplicationData } = entryStore();

  const {
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  const [email, setEmail] = useState(applicationData.email);
  const [userCode, setUserCode] = useState('');
  const [authCode, setAuthCode] = useState(null);
  const [isIDSend, setIsSend] = useState(false);

  const inputEmail = (e) => {
    const email = e.target.value.replace(/\s/g, '');
    setEmail(email);
  };

  const inputUserCode = (e) => {
    const code = e.target.value.replace(/\s/g, '');
    setUserCode(code);
  };

  const sendIDCode = () => {
    checkID().then(success => {
      sendEmail(email).then(res => {
        setAuthCode(res.data.access_id);
        setIsSend(true);
      }).catch(e => {
        alert('인증번호 전송이 실패했습니다.\n다시 시도해주세요.')
      })

    }).catch(e => {
      console.log('인증 코드 발송 중 오류 발생:', e);
    });
  }

  const checkID = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      alert('유효하지 않은 이메일 형식입니다.');
      return Promise.reject('Invalid email format');  // 유효하지 않은 경우 Promise를 거부합니다.
    }

    return api.post('/api-tg/vendor/check-id', { email }).then(res => {
      alert('입점신청이 가능한 계정입니다.\n인증번호를 전송했습니다.');
      const user = res.data.data
      console.log('user:: ', user)
      setApplicationData({
        ...applicationData,
        uuid: user.id,
        email: user.email,
        b_no: user.b_no
      });
      return true;  // 성공 시 true 반환
    }).catch(e => {
      const res = e.response;
      if (res && res.status === 404) {
        if (res.data && res.data.entry_id) {
          alert('이미 입점신청 완료된 계정입니다.\n다시 확인해주세요.');
        } else if(res.data.message.includes('No staff')) {
          alert('일반 회원으로 가입된 계정은 입점신청이 불가합니다.\n판매자 회원가입 후 다시 시도해주세요.');
        } else {
          alert('가입되지 않은 계정입니다.\n다시 확인해주세요.');
        }
      } else {
        alert('기타 오류');
      }
      throw e;
    });
  }

  const checkCode = () => {
    if (!isIDSend) {
      alert('입점신청을 진행할 계정을 인증해주세요.')
      return
    }
    if (userCode.length == 0) {
      alert('인증번호를 입력해주세요.')
      return
    }
    // 24-10-14 임시 인증 비밀번호 추가
    if (userCode == '12345678') {
      alert('인증되었습니다.')
      navigate('/vendor/5')
    } else {
      confirmCode(authCode, userCode).then(res => {
        alert('인증되었습니다.')
        navigate('/vendor/5')
      }).catch(e => {
        alert('인증코드가 일치하지 않습니다.')
        return
      })
    }
  }



  return (
    <>
      <section className="section-register">
        <div className="inner">
          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">회원 인증</h3>
            <p className="text">
              입점신청을 진행할 계정을 인증해주세요.
            </p>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 아이디(이메일) [START] */}
          <div className="input-box">
            <label htmlFor="email" className="title starred">
              아이디(이메일)
            </label>
            <div className="input">
              <input
                id="email"
                type="email"
                placeholder="아이디(이메일)을 입력해 주세요."
                onInput={inputEmail}
                disabled={isIDSend}
                value={email}
              />
              <button
                type="button"
                className="btn purple"
                onClick={sendIDCode}
                disabled={isIDSend}
              >
                인증번호받기
              </button>
            </div>
            <div className="input">
              <input
                id="code"
                type="text"
                placeholder="인증번호를 입력해주세요."
                onInput={inputUserCode}
                maxLength={8}
              />
            </div>
          </div>
          {/* 아이디(이메일) [END] */}

          {/* 디버그 확인용 [START]*/}
          {/* <div className="debug-box mt20">
            <p><b>이메일(applicationData.email) :</b> {applicationData.email}</p>
            <p><b>인증번호(userCode) :</b> {userCode}</p>
          </div> */}
          {/* 디버그 확인용 [END]*/}

        </div>
      </section>
      {/* 다음 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button
              type="button"
              className="btn purple big"
              onClick={() => checkCode()}
            >
              다음
            </button>
            {/* <a 
              href="#"
              className="skip-link"
              onClick={() => navigate('/')}
            >
              아이 등록 다음에 하기
            </a> */}
          </div>
        </div>
      </div>
      {/* 다음 버튼 [END]*/}
    </>
  );
};

export default VendorRegister04Section;

