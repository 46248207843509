import React, { useState } from "react";
import useInputStore from '../../store/common/useInputStore';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

const KidRegister02Section = () => {

  const navigate = useNavigate();
  const { getTranslatedNameById, language } = useLanguage();

  const {
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  const brandList = [
    getTranslatedNameById("TWVudUl0ZW06MzY4"), // 한국브랜드
    getTranslatedNameById("TWVudUl0ZW06MzY5"), // 수입브랜드
    getTranslatedNameById("TWVudUl0ZW06Mzcw"), // 명품
    getTranslatedNameById("TWVudUl0ZW06Mzcx"), // SNS브랜드
    getTranslatedNameById("TWVudUl0ZW06Mzcy"), // 남/동대문
    getTranslatedNameById("TWVudUl0ZW06Mzcz")  // 쇼핑몰
  ];

  const [checkedStates, setCheckedStates] = useState(new Array(brandList.length).fill(false));
  const [selectedBrands, setSelectedBrands] = useState([]);

  const handleCheckboxChange = (position) => {
    if (selectedBrands.length >= 3 && !checkedStates[position]) {
      return;
    }

    const updatedCheckedStates = checkedStates.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedStates(updatedCheckedStates);

    if (!checkedStates[position]) {
      setSelectedBrands([...selectedBrands, brandList[position]]);
    } else {
      setSelectedBrands(selectedBrands.filter((brand) => brand !== brandList[position]));
    }
  };

  const nextPage = () => {
    actions.setKidBrandList(JSON.stringify(selectedBrands));
    navigate('/register/kid-3');
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">
          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">
              {getTranslatedNameById("TWVudUl0ZW06MzY1")} {/* "선호 브랜드" */}
            </h3>
            <p className="text">
              {/* 언어에 따른 조건부 텍스트 */}
              {language === 'KO' ? (
                <>
                  평소 선호하는 브랜드 유형을<br/>
                  1순위부터 3순위까지 선택해주세요
                </>
              ) : (
                <>
                  {getTranslatedNameById("TWVudUl0ZW06Mzc1")} {/* "평소 선호하는 브랜드 유형을 1순위부터 3순위까지 선택해주세요" */}
                </>
              )}
            </p>
            <div className="pagination">
              <span className="item">1</span>
              <span className="item active">2</span>
              <span className="item">3</span>
              <span className="item">4</span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 아이템 체크 영역[START]*/}
          <div className="check-wrap mt30">
            <ul className="check-list brand">
              {brandList.map((item, index) => (
                <li key={index}>
                  {/* selectedBrands의 길이가 3 이상이고, 
                      현재 항목이 선택되지 않았다면 disabled 클래스를 추가 */}
                  <label
                    htmlFor={`check${index}`}
                    className={`${checkedStates[index] ? 'active' : ''} 
                    ${selectedBrands.length >= 3 && !checkedStates[index] ? 'disabled' : ''}`}
                  >
                    {item}
                    <input
                      className="bg"
                      id={`check${index}`}
                      type="checkbox"
                      checked={checkedStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={selectedBrands.length >= 3 && !checkedStates[index]} // 여기도 동일한 조건으로 disabled 속성 적용
                    />
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 아이템 체크 영역[END]*/}
          
          {/* 디버그 박스 [START] */}
          {/* <div className="debug-box mt20">
            <p><b>선택한 브랜드 순위:</b> {selectedBrands.join(', ')}</p>
          </div> */}
          {/* 디버그 박스 [END] */}
        </div>
      </section>

      {/* 다음 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              disabled={!(selectedBrands.length >= 3)}
              className="btn purple big"
              onClick={nextPage}
            >
              {getTranslatedNameById("TWVudUl0ZW06MzYz")} {/* "다음" */}
            </button>
            <a 
              href="#"
              className="skip-link"
              onClick={() => navigate('/')}
            >
              {getTranslatedNameById("TWVudUl0ZW06MzYy")} {/* "아이 등록 다음에 하기" */}
            </a>
          </div>
        </div>
      </div>
      {/* 다음 버튼 [END]*/}
    </>
  );
};

export default KidRegister02Section;
