import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import TopBar from '../../layouts/top_bar/TitleBar';
import { fetchPages } from "../../services/GQ_apis/page";
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

function CustomerCenterPage() {
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const [activeTab, setActiveTab] = useState(getTranslatedNameById("TWVudUl0ZW06NDgx")); // 공지사항
  const [activeQuestion, setActiveQuestion] = useState(null); // 활성화된 질문을 관리하기 위한 state 추가
  const [pageData, setPageData] = useState([]);
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { name: getTranslatedNameById("TWVudUl0ZW06NDgx"), class: 'notice', filter: "UGFnZVR5cGU6Nw==" }, // 공지사항
    { name: 'FAQ', class: 'faq', filter: "UGFnZVR5cGU6OA==" }, // FAQ
  ];

  // 탭 클릭 핸들러
  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    navigate(`/customer-center?tab=${tab.name === getTranslatedNameById("TWVudUl0ZW06NDgx") ? 'notice' : 'faq'}`); // URL 업데이트
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl = queryParams.get('tab') || 'notice';

    // URL에서 받은 탭 이름으로 활성화된 탭 설정
    setActiveTab(tabFromUrl === 'faq' ? 'FAQ' : getTranslatedNameById("TWVudUl0ZW06NDgx"));

    const selectedTab = tabs.find(tab => tab.name === (tabFromUrl === 'faq' ? 'FAQ' : getTranslatedNameById("TWVudUl0ZW06NDgx")));
    const filter = selectedTab ? { pageTypes: selectedTab.filter } : {};

    setIsLoading(true); // 로딩 시작
    fetchPages({ filter, first: 10 }).then((res) => {
      const { edges, pageInfo } = res.data.pages;

      // created 필드를 기준으로 최신순 정렬
      const sortedPages = [...edges].sort((a, b) => {
        return new Date(b.node.created) - new Date(a.node.created);
      });

      setPageData(sortedPages);
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);
    }).catch(error => {
      console.error('Error fetching pages:', error);
    }).finally(() => {
      setIsLoading(false); // 로딩 완료
    });
  }, [location.search]);

  // 질문 항목 클릭 이벤트를 처리하는 함수
  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  // JSON 데이터에서 텍스트를 추출하는 함수
  const parseContentJson = (contentJson) => {
    try {
      if (!contentJson) {
        return getTranslatedNameById("TWVudUl0ZW06NTE4"); // 내용이 없습니다.
      }

      const parsedContent = JSON.parse(contentJson);
      if (parsedContent.blocks && Array.isArray(parsedContent.blocks)) {
        return parsedContent.blocks.map(block => block.data.text).join('\n');
      } else {
        return getTranslatedNameById("TWVudUl0ZW06NTE4"); // 내용이 없습니다.
      }
    } catch (error) {
      console.error('Error parsing contentJson:', error);
      return getTranslatedNameById("TWVudUl0ZW06NTE4"); // 파싱 오류 시 기본 메시지
    }
  };

  // 더 많은 데이터를 불러오는 함수
  const loadMorePages = () => {
    if (!hasNextPage || isMoreLoading) return; // 중복 요청 방지

    setIsMoreLoading(true);
    const selectedTab = tabs.find(tab => tab.name === activeTab);
    const filter = selectedTab ? { pageTypes: selectedTab.filter } : {};

    fetchPages({ filter, first: 10, after: endCursor }).then((res) => {
      const { edges, pageInfo } = res.data.pages;
      setPageData(prevData => [...prevData, ...edges]);
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);
    }).catch(error => {
      console.error('Error fetching more pages:', error);
    }).finally(() => {
      setIsMoreLoading(false); // 더 많은 데이터 로딩 완료
    });
  };

  return (
    <>
      <TopBar title={getTranslatedNameById("TWVudUl0ZW06NDgw")} url="/login/main1" /> {/* 고객센터 */}
      <section className="section-customer-center">
        <div className="slide-tabs purple pl0 pr0 relative">
          <div className="tabs">
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => handleTabClick(tab)}
                className={`w100p tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
              >
                {tab.name}
              </button>
            ))}
          </div>
        </div>

        {isLoading ? (
          <div className="loading-wrap">
            <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        ) : (
          <>
            <ul className="toggle-list mt54">
              {pageData.length > 0 ? (
                pageData.map((page, index) => (
                  <li key={index}>
                    <button className="q" type="button" onClick={() => toggleQuestion(index)}>
                      <div className="title-wrap">
                        <span className="badge">
                          {page.node.pageType?.name || '카테고리 없음'}
                        </span>
                        <div className="title">
                          {page.node.title}
                        </div>
                      </div>
                      <span className="date">
                        {page?.node?.created 
                          ? new Date(page.node.created).toLocaleDateString('ko-KR', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            }).replace(/\./g, '. ').replace(/\.\s?$/, '') // 마지막 .을 제거
                          : "Unknown date"}
                      </span>
                    </button>
                    {activeQuestion === index && (
                      <button className="a" onClick={() => toggleQuestion(index)}>
                        {parseContentJson(page.node.contentJson)}
                      </button>
                    )}
                  </li>
                ))
              ) : (
                <li>{getTranslatedNameById("TWVudUl0ZW06NTE4")}</li>
              )}
            </ul>

            {/* 더 많은 데이터를 불러오는 버튼 */}
            {hasNextPage && (
              <div className="white-wrap mt20">
                <button
                  type="button"
                  className={`btn big round more f14 ${isMoreLoading ? 'active' : ''}`}
                  onClick={loadMorePages}
                >
                  {getTranslatedNameById("TWVudUl0ZW06NTE5")} {/* 더 많은 내용 보기 */}
                </button>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}

export default CustomerCenterPage;
