import React, { useState, useEffect } from "react";
import useProductStore from '../../store/common/useProductStore';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

const Product02Section = () => {

  const [isOpen, setIsOpen] = useState(false);
  const { getTranslatedNameById, language } = useLanguage(); 
  const { nowProductData, setNowProductData } = useProductStore((state) => ({
    nowProductData: state.nowProductData,
    setNowProductData: state.setNowProductData,
  }));

  if (! nowProductData) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  useEffect(()=>{

    console.log('nowProductData', nowProductData);

  },[nowProductData])

  return (
    <>
      <section className="section-product-info">
        <div 
          className={isOpen ? "white-wrap toggle-wrap active h60" : "white-wrap toggle-wrap"}
        >
          <div className="h4-title-wrap">
            <h4 className="title mb0">
              {getTranslatedNameById("TWVudUl0ZW06NDM3")}
            </h4>
            <button 
              type="button" 
              title="토글버튼" 
              className="toggle big active"
              onClick={()=>setIsOpen(!isOpen)}
            >
              <span className="icon-under"></span>
            </button>
          </div>
        {
          nowProductData.attributes.map((attr, index)=>(
            <>
              <div key={index} className="text-wrap mt16">
                <div className="flex between">
                  {
                    attr.attribute && 
                    attr.attribute.name && 
                    attr.attribute.name !== 'MD 추천' &&
                    attr.attribute.name !== '검색 키워드' &&
                    attr.attribute.name !== '상품 그룹' &&
                    attr.attribute.name !== 'B2C/B2B' &&
                    <>
                      <strong className="f14 f-black80">
                        {attr.attribute.name}
                      </strong>
                      {
                        attr.values.length > 0 && 
                        <p className="f14 f-black80">
                          {attr.values[0].name}
                        </p>
                      }
                    </>
                  }
                </div>
              </div>
            </>
          ))
        }
        </div>

      </section>
    </>
  );
};

export default Product02Section;

