import React from 'react';
import IconSearch from '../../assets/images/common/icons/icon_search_big.svg';
import { useLanguage } from '../../services/languageContext';

const SearchEmptySection = () => {
  const { getTranslatedNameById, language } = useLanguage();

  return (
    <>
      <section className="section-search">
        <div className="inner pt30">
          <div className="img-wrap flex center">
            <img className="w120" src={IconSearch} alt="검색결과가 없습니다."></img>
          </div>
          <strong className="f22 mt20 flex center">{getTranslatedNameById("TWVudUl0ZW06NDcw")}</strong>
          <p className="f14 mt10 flex center">{getTranslatedNameById("TWVudUl0ZW06NDcx")}</p>
        </div>
      </section>
    </>
  );
};

export default SearchEmptySection;

