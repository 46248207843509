import React, {useState, useEffect} from "react";
import usePopupStore from '../../store/common/usePopupStore'
import { fetchVouchers, TGVoucherDownload } from '../../services/GQ_apis/discounts';
import useAuthStore from '../../store/common/useAuthStore';
import { useLanguage } from '../../services/languageContext';

const CouponsAllSection = () => {

  const { user } = useAuthStore();
  const { getTranslatedNameById, language } = useLanguage(); 

  // SnackBar 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const [couponList, setCouponList] = useState([]);
  const [isCouponLoading, setIsCouponLoading] = useState(true);

  useEffect(() => {
    fetchAndSetVouchers();
  }, [user]);

  const fetchAndSetVouchers = () => {
    if(!user) return;
    setIsCouponLoading(true);
    fetchVouchers().then(res => {
        console.log('All Vouchers');
        setCouponList(res.data.TGvouchers.edges);
        console.log(res.data.TGvouchers.edges);
        console.log('All Vouchers');
      })
      .catch(error => {
        console.error('Error fetching vouchers:', error);
      })
      .finally(() => {
        setIsCouponLoading(false);
      });
  };

  const voucherDownload = (code) => {

    TGVoucherDownload(code).then((res) => {

      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTY2"), '', '');
      console.log('voucherDownload res', res);
      fetchAndSetVouchers();

    }).catch((error) => {
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTY3"), '', '');
      console.error('Error downloading voucher:', error);
      fetchAndSetVouchers();
    });

  };

  if (isCouponLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-coupons">
        <div className="inner">
          <strong className="title">
          {getTranslatedNameById("TWVudUl0ZW06NTY0")} {/* 받을 수 있는 쿠폰 */}
          {language === 'KO' ? (
              <>
                <b className="f-purple"> 
                  {couponList.filter((item)=>item.node.isDownloaded == false).length}개
                </b>
              </>
            ) : (
              <>
                <b className="f-purple"> 
                  {couponList.filter((item)=>item.node.isDownloaded == false).length}
                </b>
              </>
            )}
          </strong>
          <ul className="coupons-list">
            {
              couponList.map((coupon,index)=>(
                ! coupon.node.isDownloaded && 
                <li className="card coupon" key={coupon.node.id}>
                  <div className="top-wrap">
                    <strong className="f-purple">
                      {
                        coupon.node.discountValueType === 'FIXED' ? 
                        <>
                          -{window.WSformatPrice(coupon.node.discountValue)}{window.WSCurrency(coupon.node.currency)}
                        </> 
                        : 
                        <>
                          {coupon.node.discountValue}%
                        </>
                      }
                    </strong>
                    <button 
                      type="button" 
                      className="btn white round"
                      onClick={()=>{voucherDownload(coupon.node.code)}}
                      >
                      <span className="icon-download">
                      </span>
                      {getTranslatedNameById("TWVudUl0ZW06NTY1")} {/* 다운로드 */}
                    </button>
                  </div>
                  <div className="bottom-wrap">
                    <strong className="title">
                      {coupon.node.name}
                    </strong>
                    <strong className="d-day">
                      { coupon.node.startDate && window.WSdate(coupon.node.startDate) } 
                      {(coupon.node.startDate || coupon.node.endDate) && '~'}  
                      { coupon.node.endDate && window.WSdate(coupon.node.endDate) }
                      {
                        coupon.node.minSpent &&
                        <span className="caption">
                          {language === 'KO' ? (
                              <>
                                {window.WSformatPrice(coupon.node.minSpent.amount)}
                                {window.WSCurrency(coupon.node.minSpent.currency)} 이상 구매시 사용가능
                              </>
                            ) : (
                              <>
                                Valid on purchases over {window.WSformatPrice(coupon.node.minSpent.amount)}
                                {window.WSCurrency(coupon.node.minSpent.currency)}
                              </>
                            )}
                        </span>
                      }
                    </strong>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </section>
      {/* 쿠폰 전체 다운받기 버튼 [START]*/}
      {/* <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              onClick={()=>openSnackBarPopup('정상적으로 쿠폰을 다운로드하였습니다.','','')}
              className="btn purple big"
              disabled={downloadableCoupons.length === 0}
            >
              쿠폰 전체 다운받기
            </button>
          </div>
        </div>
      </div> */}
      {/* 쿠폰 전체 다운받기 버튼 [END]*/}
    </>
  );
};

export default CouponsAllSection;

