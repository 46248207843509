import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TopBar from '../../layouts/top_bar/TitleBar';
import CouponsAllSection from '../../sections/coupon/CouponsAllSection';
import CouponsUserSection from '../../sections/coupon/CouponsUserSection';
import { useLanguage } from '../../services/languageContext'; 

function CouponPage() {
  const { Page2depth } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [pageIndex, setPageIndex] = useState(1);
  const { getTranslatedNameById } = useLanguage();
  const [topBarUrl, setTopBarUrl] = useState(-1);  // 기본적으로 뒤로 가기 버튼 설정
  const [initialFrom, setInitialFrom] = useState(null); // 처음 온 경로를 저장

  console.log(location.state);

  useEffect(() => {
    // URL에 따라 페이지 인덱스를 설정
    if (location.pathname.includes('/coupons/1')) {
      setPageIndex(1);
    } else if (location.pathname.includes('/coupons/2')) {
      setPageIndex(2);
    }

    // location.state?.from이 존재할 때만 상태에 저장
    if (!initialFrom && location.state?.from) {
      setInitialFrom(location.state?.from); // 처음 쿠폰 페이지로 온 경로 저장
    }

    // 쿠폰 페이지로 오는 경로에 따라 뒤로가기 URL 설정
    if (initialFrom === 'mypage') {
      setTopBarUrl('/mymenu/profile');  // 마이페이지에서 왔을 경우
    } else if (initialFrom === 'special') {
      setTopBarUrl('/');  // 전체화면에서 special 탭을 통해 왔을 경우
    } else {
      setTopBarUrl(-1);  // 기본적으로 뒤로 가기 설정
    }
  }, [location, initialFrom]);

  return (
    <>
      <TopBar  
        title={getTranslatedNameById("TWVudUl0ZW06Mjk5")} // "쿠폰"
        url={topBarUrl}  // 동적으로 설정된 URL 사용
      />
      <article id="couponPage">
        {/* 탭 버튼을 이용하여 페이지 전환 */}
        <div className="switch-tab">
          <button 
            type="button" 
            className={`tab ${pageIndex === 1 ? 'active' : ''}`}
            onClick={() => { 
              navigate('/coupons/1', { state: location.state });  // state 값을 유지
              setPageIndex(1);
            }}
          >
            {getTranslatedNameById("TWVudUl0ZW06NDE4")} {/* "보유쿠폰" */}
          </button>

          <button 
            type="button" 
            className={`tab ${pageIndex === 2 ? 'active' : ''}`}
            onClick={() => { 
              navigate('/coupons/2', { state: location.state });  // state 값을 유지
              setPageIndex(2);
            }}
          >
            {getTranslatedNameById("TWVudUl0ZW06NDE5")} {/* "쿠폰받기" */}
          </button>
        </div>

        {/* 보유 쿠폰 섹션 */}
        {Page2depth === '1' && <CouponsUserSection/>}

        {/* 쿠폰 받기 섹션 */}
        {Page2depth === '2' && <CouponsAllSection/>}
      </article>
    </>
  );
}

export default CouponPage;
