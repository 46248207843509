import React, { useState, useEffect, useCallback } from "react";
import parse from 'html-react-parser';
import TopBar from '../../layouts/top_bar/TopNav';
import BottomNav from '../../layouts/bottom_bar/BottomNav';
import GridSection from '../../sections/grid/GridSection';
import { getCategory } from '../../services/GQ_apis/category';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Autoplay, FreeMode, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/grid';

function CategoryPage() {

  const [isLoading, setIsLoading] = useState(true);

  // 카테고리 탭 리스트
  const [depth1_tabs, setDepth1] = useState([]);
  const [depth2_tabs, setDepth2] = useState([]);
  const [depth3_tabs, setDepth3] = useState([]);
  const [depth4_tabs, setDepth4] = useState([]);

  // 선택된 카테고리 탭
  const [active_depth1_Tab, setActiveDepth1Tab] = useState(null);
  const [active_depth2_Tab, setActiveDepth2Tab] = useState(null);
  const [active_depth3_Tab, setActiveDepth3Tab] = useState(null);
  const [active_depth4_Tab, setActiveDepth4Tab] = useState(null);

  const [categoriesList, setCategoriesList] = useState(null);

  useEffect(() => {
    console.log(categoriesList)
  }, [categoriesList])

  const loadDepth1 = useCallback(() => {
    getCategory(0).then((depth1) => {
      const updatedDepth1 = [...depth1.data.categories.edges];
      setDepth1(updatedDepth1);
      if (!active_depth1_Tab) {
        setActiveDepth1Tab(updatedDepth1[0].node)
        setCategoriesList(updatedDepth1[0].node.id)
      }
    });
  }, [active_depth1_Tab]);

  const loadDepth2 = useCallback(() => {
    if (!active_depth1_Tab) return;
    getCategory(1, active_depth1_Tab.decryptId).then((depth2) => {
      const updatedDepth2 = [...depth2.data.categories.edges];
      setDepth2(updatedDepth2);
      // if (!active_depth2_Tab) {
      //   setActiveDepth2Tab(updatedDepth2[0].node);
      //   setActiveDepth3Tab(updatedDepth2[0].node);
      // }
    });
  }, [active_depth1_Tab, active_depth2_Tab, active_depth3_Tab, active_depth4_Tab]);

  const loadDepth3 = useCallback(() => {
    if (!active_depth2_Tab) {
      setIsLoading(false);
      return;
    }
    getCategory(2, active_depth2_Tab.decryptId).then((depth3) => {
      const updatedDepth3 = [...depth3.data.categories.edges];
      setDepth3(updatedDepth3);
      // if (!active_depth3_Tab) setActiveDepth3Tab(updatedDepth3[0].node);
      setIsLoading(false);
    });
  }, [active_depth2_Tab, active_depth3_Tab, active_depth4_Tab]);

  const loadDepth4 = useCallback(() => {
    if (!active_depth3_Tab) return;
    getCategory(3, active_depth3_Tab.decryptId).then((depth4) => {
      const updatedDepth4 = [...depth4.data.categories.edges];
      setDepth4(updatedDepth4);
      // if (!active_depth4_Tab) setActiveDepth4Tab(updatedDepth4[0].node);
    });
  }, [active_depth3_Tab, active_depth4_Tab]);

  useEffect(() => {
    loadDepth1();
  }, [loadDepth1]);

  useEffect(() => {
    loadDepth2();
  }, [loadDepth2]);

  useEffect(() => {
    loadDepth3();
  }, [loadDepth3]);

  useEffect(() => {
    loadDepth4();
  }, [loadDepth4]);

  const depth1Click = (tab) => {
    setActiveDepth1Tab(tab);
    setActiveDepth2Tab(null);
    setDepth2([]);
    setDepth3([]);
    setDepth4([]);
    setCategoriesList(tab.id);
  };

  const depth2Click = (tab) => {
    if (active_depth2_Tab !== tab) {
      setActiveDepth2Tab(tab);
      setActiveDepth3Tab(tab);
      setDepth3([]);
      setDepth4([]);
      setCategoriesList(tab.id);
    } else {
      setActiveDepth2Tab(null);
      setDepth3([]);
      setDepth4([]);
      setCategoriesList(active_depth1_Tab.id);
    }
  };

  const depth3Click = (tab) => {
    setActiveDepth3Tab(tab);
    setActiveDepth4Tab(tab);
    setDepth4([]);
    setCategoriesList(tab.id);
  };

  const depth4Click = (tab) => {
    setActiveDepth4Tab(tab);
    setCategoriesList(tab.id);
  };

  if (isLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <TopBar />
      <article id="categoryPage">
        {/* 패션/라이프 탭 [START] */}
        <div className="slide-tabs purple2">
          <div className="tabs">
            {depth1_tabs.map((tab) => (
              <button
                key={tab.node.id}
                onClick={() => depth1Click(tab.node)}
                className={`tab ${active_depth1_Tab?.id === tab.node.id ? 'active' : ''}`}
              >
                {
                  window.WSgetChannel() == 'EN' ? tab?.node?.translation?.name : tab?.node?.name
                }
              </button>
            ))}
          </div>
        </div>
        {/* 패션/라이프 탭 [END] */}

        {/* 카테고리(BIG) 탭 [START] */}
        <div className="category-tabs-wrap big">
          <Swiper
            modules={[Grid, Autoplay, FreeMode, Pagination]}
            slidesPerView={5}
            grid={{ rows: 2, fill: 'row' }}
            loop={false}
            freeMode={false}
            pagination={{
              clickable: true,
              renderBullet: (index, className) => {
                return `<span className="${className} custom-bullet"></span>`;
              }
            }}
            className="category-swiper big tabs ml10"
          >
            {depth2_tabs.map((tab) => (
              <SwiperSlide key={tab.node.id}>
                <li
                  className={`tab mb12 ${active_depth2_Tab?.id === tab.node.id ? "active" : ""}`}
                  onClick={() => depth2Click(tab.node)}
                  style={{
                    pointerEvents: 'auto'
                  }}
                >
                  <a href="#none" title="">
                    {
                      window.WSgetChannel() == 'EN' ? parse(tab?.node?.translation?.name) : parse(tab?.node?.name)
                    }
                  </a>
                </li>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* 카테고리(BIG) 탭 [END] */}

        {/* 카테고리 탭 [START] */}
        {
          depth3_tabs.length > 0 && (
            <div className="category-tabs-wrap">
              <Swiper
                modules={[Autoplay, FreeMode, Pagination]}
                spaceBetween={12}
                slidesPerView="auto"
                loop={false}
                freeMode={true}
                className="category-swiper mb20 ml10"
              >
                {
                  depth3_tabs.length > 0 &&
                  <SwiperSlide key="all1">
                    <li>
                      <button
                        type="button"
                        className={`tab ${(active_depth2_Tab == active_depth3_Tab) ? 'active' : ''}`}
                        onClick={() => depth3Click(active_depth2_Tab)}
                      >
                        All
                      </button>
                    </li>
                  </SwiperSlide>
                }
                {depth3_tabs.length > 0 && depth3_tabs.map((tab) => (
                  <SwiperSlide key={tab.node.id}>
                    <li>
                      <button
                        type="button"
                        className={`tab pl10 pr10 ${active_depth3_Tab?.id === tab.node.id ? 'active' : ''}`}
                        onClick={() => depth3Click(tab.node)}
                      >
                        {
                          window.WSgetChannel() == 'EN' ? tab?.node?.translation?.name : tab?.node?.name
                        }
                      </button>
                    </li>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        {/* 카테고리 탭 [END] */}

        {/* 추가 카테고리 탭 [START] */}
        {
          depth4_tabs.length > 0 &&
          <div className="category-tabs-wrap additional-category-tabs mb10">
            <Swiper
              modules={[Autoplay, FreeMode, Pagination]}
              spaceBetween={12}
              slidesPerView="auto"
              loop={false}
              freeMode={true}
              className="category-swiper additional-swiper mb20 ml10"
            >
              {
                depth4_tabs.length > 0 &&
                <SwiperSlide key="all2">
                  <li>
                    <button
                      type="button"
                      className={`tab ${(active_depth3_Tab == active_depth4_Tab) ? 'active' : ''}`}
                      onClick={() => depth4Click(active_depth3_Tab)}
                    >
                      All
                    </button>
                  </li>
                </SwiperSlide>
              }
              {depth4_tabs.map((tab) => (
                <SwiperSlide key={tab.node.id}>
                  <li>
                    <button
                      type="button"
                      className={`tab ${active_depth4_Tab?.id === tab.node.id ? 'active' : ''}`}
                      onClick={() => depth4Click(tab.node)}
                    >
                      {
                        window.WSgetChannel() == 'EN' ? tab?.node?.translation?.name : tab?.node?.name
                      }
                    </button>
                  </li>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        }
        {/* 추가 카테고리 탭 [END] */}

        {/* 상품 리스트 [START] */}
        {
          categoriesList &&
          <GridSection
            type="middle"
            option={true}
            category={categoriesList}
            infinity={true}
          />
        }
        {/* 상품 리스트 [END] */}

      </article>
      <BottomNav />
    </>
  );
}

export default CategoryPage;
