import { create } from 'zustand'
import { meData } from '../../services/GQ_apis/me';

const useAuthStore = create((set, get) => ({
  globalObserver: false,
  user: null,
  observerUpdate: () => set((state) => ({ globalObserver: !state.globalObserver })),
  fetchUser: async () => {
    try {
      const localUser = localStorage.getItem("user");
      const response = await meData();
      if (response && localUser && localUser !== "null") {
        set({ user: response.data.me });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  },
  login: (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
    get().fetchUser();
    console.log(get().user)
  },
  logout: () => {

    localStorage.removeItem("user");
    localStorage.removeItem("me");
    localStorage.removeItem("paymentToken");
    // localStorage.removeItem("cartToken");

    set({ user: null });

    console.log('logout')
    console.log(get().user)
    console.log('logout')
  },
  userToken: () => {
    let user = localStorage.getItem("user");

    if (!user) return false;
  
    let result = JSON.parse(user);
    if (!result) return false;
  
    return result;
  }
}));

export default useAuthStore;