import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useInputStore from '../../store/common/useInputStore'
import { accountRegister, accountLogin } from '../../services/GQ_apis/auth';
import useAuthStore from '../../store/common/useAuthStore';
import usePopupStore from '../../store/common/usePopupStore'
import { useLanguage } from '../../services/languageContext';

const Register02Section = () => {

  const navigate = useNavigate();
  const { login } = useAuthStore();
  const { getTranslatedNameById } = useLanguage();

  const {
    input,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  // SnackBar 스낵바 팝업 사용 함수 openSnackBarPopup
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup, // 스낵바 오픈 함수
  }));

  const [checkboxes, setCheckboxes] = useState([
    { title: getTranslatedNameById('TWVudUl0ZW06NjQ5'), checked: false }, // (필수) 만 14세 이상입니다.
    { title: getTranslatedNameById('TWVudUl0ZW06NjQ4'), checked: false }, // (필수) 서비스 이용약관에 동의
    { title: getTranslatedNameById('TWVudUl0ZW06NjUw'), checked: false }, // (필수) 개인정보 수집이용에 동의
    { title: getTranslatedNameById('TWVudUl0ZW06NjUx'), checked: false }, // (선택) 야간 수신혜택에 동의
    { title: getTranslatedNameById('TWVudUl0ZW06NjUy'), checked: false }, // (선택) 홍보 및 마케팅 이용에 동의
    { title: getTranslatedNameById('TWVudUl0ZW06NjUz'), checked: false }, // (선택) 마케팅 개인정보 제 3자 제공 동의
  ]);

  const [allChecked, setAllChecked] = useState(false);

  const handleAllCheckChange = () => {
    const newAllChecked = !allChecked;
    setAllChecked(newAllChecked);
    setCheckboxes(checkboxes.map(checkbox => ({ ...checkbox, checked: newAllChecked })));
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = { ...updatedCheckboxes[index], checked: !updatedCheckboxes[index].checked };
    setCheckboxes(updatedCheckboxes);
    setAllChecked(updatedCheckboxes.every(checkbox => checkbox.checked));
  };

  const handleRegister = () => {
    const metadata = [
      {
        key: 'phone',
        value: input.phoneNumber,
      },
      {
        key: 'nickName',
        value: input.nickName,
      }
    ]
    accountRegister(
      process.env.REACT_APP_BASEURL,
      process.env.REACT_APP_CHANNEL_NAME,
      input.email,
      input.password,
      input.name,
      metadata
    ).then((res) => {
      accountLogin(input.email, input.password)
        .then((response) => {
          if (response.data && response.data.tokenCreate && response.data.tokenCreate.token) {
            login(response.data);
            openSnackBarPopup(getTranslatedNameById('TWVudUl0ZW06NjQ2'), '', ''); // 정상적으로 회원가입되었습니다.
            navigate("/register/kid-1");
          } else {
            openSnackBarPopup(getTranslatedNameById('TWVudUl0ZW06NjQ3'), '', ''); // 아이디와 비밀번호를 확인해주세요.
          }
        })
        .catch((error) => {
          console.log('로그인 오류:', error);
          openSnackBarPopup('로그인 도중 오류가 발생했습니다.', '', '');
          navigate("/login/main2");
        });
    })
  };

  const { openToastPopup } = usePopupStore((state) => ({
    openToastPopup: state.openToastPopup,
  }));

  // 필수 입력 항목 검사
  const validateInput = () => {
    return checkboxes[0].checked && checkboxes[1].checked && checkboxes[2].checked;
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">
          <div className="h3-title-wrap mt0">
            {/* 이용약관 */}
            <h3 className="title">{getTranslatedNameById("TWVudUl0ZW06NjM0")}</h3>
          </div>
          <div className="input-box">
            <div className="input check bg check-all mb14">
              <label htmlFor="checkAll">
                <input
                  id="checkAll"
                  type="checkbox"
                  checked={allChecked}
                  onChange={handleAllCheckChange}
                />  {getTranslatedNameById("TWVudUl0ZW06NjM1")} {/* 약관 전체 동의하기 */}
              </label>
            </div>
            <ul className="input-list">
              {checkboxes.map((checkbox, index) => (
                <li key={index} className="input check">
                  <label htmlFor={'check__' + index}>
                    <input
                      id={'check__' + index}
                      type="checkbox"
                      checked={checkbox.checked}
                      onChange={() => handleCheckboxChange(index)}
                    /> {checkbox.title}
                  </label>
                  <button
                    type="button"
                    className="under-line"
                    onClick={() => openToastPopup(checkbox.title)}
                  >
                    {getTranslatedNameById("TWVudUl0ZW06NjM2")} {/* 자세히 */}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/* 개발 디버그용 [START] */}
          {/* <div className="debug-box mt20">
            <p><b>채널명 :</b>{process.env.REACT_APP_CHANNEL_NAME}</p>
            <p><b>전체 체크(allChecked) :</b>{allChecked ? 'True' : 'False'}</p>
            <p><b>1번 체크(checkboxes[0]) :</b>{checkboxes[0].checked ? 'True' : 'False'}</p>
            <p><b>필수항목체크상태 :</b>{validateInput() ? 'True' : 'False'}</p>
            <p><b>아이디(이메일) email :</b> {input.email}</p>
            <p><b>비밀번호 password :</b> {input.password} </p>
            <p><b>비밀번호 재입력 password_re :</b> {input.password_re} </p>
            <p><b>이름 name :</b> {input.name} </p>
            <p><b>닉네임 nickName :</b> {input.nickName} </p>
            <p><b>휴대폰 번호 phoneNumber :</b> {input.phoneNumber} </p>
            <p><b>휴대폰 인증번호 phoneCode :</b> {input.phoneCode} </p>
          </div> */}
          {/* 개발 디버그용 [END] */}
        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button
              type="button"
              className="btn purple big"
              disabled={!validateInput()}
              onClick={() => handleRegister()}
            >
              {getTranslatedNameById("TWVudUl0ZW06NjM3")} {/* 완료 */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register02Section;
