import React, { useEffect } from 'react';
import usePopupStore from '../../store/common/usePopupStore';
import useInputStore from '../../store/common/useInputStore';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

import smapleImg from "../../assets/images/common/icons/icon_profile.svg";

const KidRegister01Section = () => {

  const navigate = useNavigate();

  const {
    input,
    actions,
  } = useInputStore(state => ({
    input : state.input,
    actions: state.actions,
  }));

  const { openToastPopup } = usePopupStore((state) => ({
    openToastPopup: state.openToastPopup,
  }));

  const { getTranslatedNameById, language } = useLanguage();

  // 마운트 시 resetInput 초기화
  useEffect(() => {
    actions.resetInput();
  }, [actions.resetInput]);

  // 필수 입력 항목 검사
  const validateInput = () => {
    return input.kidNickName && input.kidGender && input.kidHeight && input.kidWeight && input.kidFootSize;
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">
          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">
              {getTranslatedNameById("TWVudUl0ZW06MzUz")} {/* "아이 프로필 입력" */}
            </h3>
            <p className="text">
              {/* 언어에 따른 조건부 텍스트 */}
              {language === 'KO' ? (
                <>
                  <p className="text">
                    아이에게 맞는 <span className="f-purple">옷을 추천받기 위한</span><br/> 기본 정보를 입력해주세요.
                  </p>
                </>
              ) : (
                <>
                  {getTranslatedNameById("TWVudUl0ZW06MzU0")} 
                </>
              )}
            </p>
            <div className="pagination">
              <span className="item active">1</span>
              <span className="item">2</span>
              <span className="item">3</span>
              <span className="item">4</span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 프로필 영역 [START] */}
          <div className="profile-img-wrap">
            <label htmlFor="profileImgInput" className="upload-button">
              <div className="img-wrap">
                <img 
                  src={input.kidProfileImg || smapleImg } 
                  alt={getTranslatedNameById("TWVudUl0ZW06MzY0")} 
                  title="클릭시 프로필 사진 첨부 이동"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={actions.handleImageChange}
                style={{ display: 'none' }}
                id="profileImgInput"
              />
              <p className="title">{getTranslatedNameById("TWVudUl0ZW06MzY0")}</p> {/* "프로필 사진" */}
            </label>
          </div>
          {/* 프로필 영역 [END] */}

          {/* 별명 [START] */}
          <div className="input-box">
            <label htmlFor="nickname" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzE2")} {/* "닉네임" */}
            </label>
            <div className="input">
              <input 
                id="nickname" 
                type="text" 
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzU1")} 
                onInput={(e) => actions.setKidNickName(e.target.value)}
              />
            </div>
          </div>
          {/* 별명 [END] */}

          {/* 성별 선택 & 생년월일 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <div className="title starred">{getTranslatedNameById("TWVudUl0ZW06MzU2")} {/* "성별선택" */}</div>
              <div className="input">
                <select 
                  className={input.kidGender !== '0' ? 'selected' : ''}
                  onInput={(e) => actions.setKidGender(e.target.value)}
                >
                  <option value="0">{getTranslatedNameById("TWVudUl0ZW06MzU2")}</option> {/* "성별선택" */}
                  <option value="boy">{getTranslatedNameById("TWVudUl0ZW06NDA3")}</option>
                  <option value="girl">{getTranslatedNameById("TWVudUl0ZW06NDA4")}</option>
                </select>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="date" className="title">
                {getTranslatedNameById("TWVudUl0ZW06MzU3")} {/* "생년월일" */}
              </label>
              <div className="input">
                <input 
                  id="date" 
                  type="date"
                  placeholder={getTranslatedNameById("TWVudUl0ZW06MzU3")} 
                  onInput={(e) => actions.setKidBirthDay(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* 성별 선택 & 생년월일 [END] */}

          {/* 키 & 몸무게 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <label htmlFor="height" className="title starred">
                {getTranslatedNameById("TWVudUl0ZW06MzU4")} {/* "키(cm)" */}
              </label>
              <div className="input">
                <input 
                  id="height" 
                  type="number" 
                  placeholder={getTranslatedNameById("TWVudUl0ZW06MjY5")}
                  onInput={(e) => actions.setKidHeight(e.target.value)}
                />
                <span className="inner-text">cm</span>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="weight" className="title starred">
                {getTranslatedNameById("TWVudUl0ZW06MzU5")} {/* "몸무게(kg)" */}
              </label>
              <div className="input-box">
                <div className="input">
                  <input 
                    id="weight" 
                    type="number" 
                    placeholder={getTranslatedNameById("TWVudUl0ZW06Mjcw")} 
                    onInput={(e) => actions.setKidWeight(e.target.value)}
                  />
                  <span className="inner-text">kg</span>
                </div>
              </div>
            </div>
          </div>
          {/* 키 & 몸무게 [END] */}

          {/* 발 & 머리 둘레 [START] */}
          <div className="input-box-wrap">
            <div className="input-box">
              <label htmlFor="foot" className="title starred">
                {getTranslatedNameById("TWVudUl0ZW06MzYw")} {/* "발(cm)" */}
              </label>
              <div className="input">
                <input 
                  id="foot" 
                  type="number" 
                  placeholder={getTranslatedNameById("TWVudUl0ZW06Mjcy")}
                  onInput={(e) => actions.setKidFootSize(e.target.value)}
                />
                <span className="inner-text">cm</span>
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="weight" className="title">
                {getTranslatedNameById("TWVudUl0ZW06MzYx")} {/* "머리 둘레(cm)" */}
              </label>
              <div className="input-box">
                <div className="input">
                  <input 
                    id="weight" 
                    type="number" 
                    placeholder={getTranslatedNameById("TWVudUl0ZW06Mjcw")}
                    onInput={(e) => actions.setKidHeadSize(e.target.value)}
                  />
                  <span className="inner-text">cm</span>
                </div>
              </div>
            </div>
          </div>
          {/* 발 & 머리 둘레 [END] */}
        </div>
      </section>

      {/* 다음 버튼 [START] */}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              disabled={!validateInput()}
              className="btn purple big"
              onClick={() => navigate('/register/kid-2')}
            >
              {getTranslatedNameById("TWVudUl0ZW06MzYz")} {/* "다음" */}
            </button>
            <a 
              href="#"
              className="skip-link"
              onClick={() => openToastPopup('다음에하기')} 
            >
              {getTranslatedNameById("TWVudUl0ZW06MzYy")} {/* "아이 등록 다음에 하기" */}
            </a>
          </div>
        </div>
      </div>
      {/* 다음 버튼 [END] */}
    </>
  );
};

export default KidRegister01Section;
