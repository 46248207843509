import React from "react";
import GridSection from '../../sections/grid/GridSection';

const MyMenuWishListSection = () => {
  return (
    <>
      <section className="section-mymenu-wish-list">
        <div className="inner">
          {/* Middle 그리드(리스트) [START] */}
          <GridSection type="middle" option={true} isMyWishProduct={true} infinity={true}/>
          {/* Middle 그리드(리스트) [END] */}
        </div>
      </section>
    </>
  );
};

export default MyMenuWishListSection;

