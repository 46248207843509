import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import 'swiper/css';
import "../assets/styles/scss/structure/articles/_front-guide.scss";

import H3Title from '../layouts/title/H3Title';
import GridSection from '../sections/grid/GridSection';
import SwipeGridSection from '../sections/grid/SwipeGridSection';
import ImageSwipeGridSection from '../sections/grid/ImageSwipeGridSection';
import BasicImageSwiperGridSection from '../sections/grid/BasicImageSwiperGridSection'; 
import BannerASection from '../sections/banner/BannerASection';
import BannerBSection from '../sections/banner/BannerBSection';
import BannerCSection from '../sections/banner/BannerCSection';
import BannerDSection from '../sections/banner/BannerDSection';
import BannerESection from '../sections/banner/BannerESection';
import BannerFSection from '../sections/banner/BannerFSection';
import BannerGSection from '../sections/banner/BannerGSection';

import BigTab from '../components/Tabs/BigTab'; 
import SmallTab from '../components/Tabs/SmallTab'; 

// 빅탭 리스트
const bigTabs = [
  {title: '임부복'},
  {title: '뷰티/헬스'},
  {title: '베이비의류 0~24M'},
  {title: '키즈의류24M~8Y'},
  {title: '주니어의류24M~8Y'},
  {title: '신발악세서리'},
  {title: '기저귀물티슈'},
  {title: '목욕위생세재'},
  {title: '수유기구'},
  {title: '장난감교육교제'},
  {title: '역류방지쿠션매트'},
  {title: '힙시트캐리어유모차'},
  {title: '침구류가구홈데코'},
  {title: '푸드'},
  {title: '3PL물류'},
];

// 스몰탭 리스트
const smallTabs = [
  { key: 'all', label: 'All' },
  { key: 'bottle', label: '젖병' },
  { key: 'nipple', label: '젖꼭지' },
  { key: 'cleaner', label: '젖병세정용품' },
  { key: 'nursing', label: '수유용품' },
];

const tabs = [
  { name: '패션' },
  { name: '라이프' }
];

function FrontGuidePage() {
  // 스몰탭 상태관리 [START] 
  const [activeSmallTab, setActiveSmallTab] = useState("all");
  // 스몰탭 상태관리 [END]

  // 이미지 스와이프 컴포넌트 상태관리 [START] 
  const [photos, setPhotos] = useState([
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150"
  ]); // 첨부된 사진들의 이름을 저장할 상태
  // 이미지 스와이프 컴포넌트 상태관리 [END] 

  const navigate = useNavigate();

  // 스몰탭 클릭 이벤트 [START] 
  const handleSmallTabClick = (key) => {
    setActiveSmallTab(key);
    // 여기에 클릭 시 추가 로직이 필요하면 추가 가능
  };
  // 스몰탭 클릭 이벤트 [END]

  const removePhoto = (index) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
  };

  return (
    <article>
      {/* [BIG] 상품 리스트 [START] */}
      <div className="white-wrap">
        <H3Title title="요즈음 뜨는 국내 인기 핫템!!"/>
        <GridSection 
          type="big" 
          option={false}
          collection="" // Home2 ID
        />
        <button 
          type="button" 
          className="btn big round more mt40"
          onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
        >
          더 보기
        </button>
      </div>
      {/* [BIG] 상품 리스트 [END] */}

      {/* [MID] 상품 리스트 [START] */}
      <div className="white-wrap">
        <H3Title title="요즈음 뜨는 국내 인기 핫템!!"/>
        <GridSection 
          type="middle" 
          option={false}
          collection=""
        />
        <button 
          type="button" 
          className="btn big round shortcuts mt40"
          onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
        >
          브랜드관 바로가기
        </button>
      </div>
      {/* [MID] 상품 리스트 [END] */}

      {/* [SMALL] 상품 리스트 [START] */}
      <div className="white-wrap">
        <H3Title title="요즈음 뜨는 국내 인기 핫템!!"/>
        <SwipeGridSection 
          collection=""
        />
      {/* [SMALL] 상품 리스트 [END] */}
      </div>

      <div className="white-wrap">
        {/*[A] 배너[START] */}
        <div className="banner-wrap vw100">
          <BannerASection />
        </div>
        {/*[A] 배너[END] */}

        <br/>

        <div className="banner-wrap">
          {/*[B] 배너[START] */}
          <BannerBSection />
          {/*[B] 배너[END] */}
        </div>

        <br/>

        <div className="banner-wrap">
          {/*[C] 배너[START] */}
          <BannerCSection />
          {/*[C] 배너[END] */}
        </div>

        <br/>

        <div className="banner-wrap">
          {/*[D] 배너[START] */}
          <BannerDSection />
          {/*[D] 배너[END] */}
        </div>

        <br/>

        <div className="banner-wrap vw100">
          {/*[E] 배너[START] */}
          <BannerESection />
          {/*[E] 배너[END] */}
        </div>

        <br/>

        <div className="banner-wrap vw100">
          {/*[F] 배너[START] */}
          <BannerFSection />
          {/*[F] 배너[END] */}
        </div>

        <br/>

        <div className="banner-wrap vw100">
          {/*[G] 배너[START] */}
          <BannerGSection />
          {/*[G] 배너[END] */}
        </div>

        <br/>

        {/* 빅탭 [START] */}
        <BigTab tabs={bigTabs} />
        {/* 빅탭 [END] */}

        <br/>

        {/* 스몰탭 [START] */}
        <SmallTab
          tabs={smallTabs}
          activeTab={activeSmallTab}
          onTabClick={handleSmallTabClick}
        />
        {/* 스몰탭 [END] */}

        <br />

        {/* 타이틀+텍스트 [START] */}
        <h3 className="biz_title">
            HOT ISSUE PR3ODUCTS
        </h3>

        <p className="biz_contents">
            HOT ISSUE PRODUCTS <br />
            What is the hot product right now?
        </p>

        <br />

        {/* 이미지 추가시 스와이프가 추가 및 제거되는 컴포넌트 */}
        {/* <ImageSwipeGridSection photos={photos} onRemovePhoto={removePhoto}/> */}

        {/* 기존 이미지로 스와이프 하는 컴포넌트 */}
        <BasicImageSwiperGridSection photos={photos} />

      </div>
    </article>
  );
}

export default FrontGuidePage;
