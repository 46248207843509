import React from "react";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../services/languageContext';

const MainLoginBeforeSection = () => {
  const navigate = useNavigate();
  const { getTranslatedNameById } = useLanguage();

  return (
    <section className="section-login">
      <div className="inner pt16 pb16 flex between">
        <p className="f14 f-black80">
          {getTranslatedNameById('TWVudUl0ZW06Mjc0')}
        </p>
        <button
          type="button"
          className="btn round white w86 h42 f14 ml30"
          onClick={() => navigate('/login/main1')}
        >
          {getTranslatedNameById('TWVudUl0ZW06Mjc1')}
          <span className="icon-more purple"></span>
        </button>
      </div>
    </section>
  );
};

export default MainLoginBeforeSection;
