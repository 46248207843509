// components/common/TrackPageChanges.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TrackPageChanges = () => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const previousPath = localStorage.getItem('currentPath'); 

    if (previousPath) {
      localStorage.setItem('prevPath', previousPath);
    }

    localStorage.setItem('currentPath', currentPath);
  }, [location]);

  return null; 
};

export default TrackPageChanges;
