import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import useInputStore from '../../store/common/useInputStore';
import usePopupStore from '../../store/common/usePopupStore';
import api from "../../services/api";
import InputPhone from '../../components/Inputs/InputPhone';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

const LoginFindPwSection = () => {
  const navigate = useNavigate();
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const { 
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  // 비밀번호 찾기 관련 메소드 --------------------------------------------------------

  const validateInput = () => {
    return input.name &&
           input.email &&
           input.phoneNumber &&
           input.isPhoneVerification;
  };

  const checkForPw = async () => {
    try {
      const res = await api.post('/api-tg/fpw-check', {
        name: input.name,
        email: input.email,
        phone: input.phoneNumber
      });
      actions.setUUID(res.data.data.id);
      actions.setIsPhoneVerification(true);
      return true;
    } catch (e) {
      console.log(e);
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTEw"), '', 'error'); // 일치하는 정보가 없습니다.\n다른 가입 정보로 다시 시도해주세요.
      return false;
    }
  };

  const resetPw = () => {
    if (input.password !== input.password_re) {
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTEy"), '', 'error'); // 비밀번호가 일치하지 않습니다.
      return;
    }

    api.post('/api-tg/reset-pw', {
      id: input.uuid,
      email: input.email,
      new_password: input.password
    }).then(res => {
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTEz"), '', 'success'); // 비밀번호 변경이 완료되었습니다.
      navigate('/login/main2');
    }).catch(e => {
      console.log(e);
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTE0"), '', 'error'); // 오류가 발생했습니다. 다시 시도해주세요.
      navigate(0);
    });
  };

  return (
    <>
      <section className="section-login-findpw">
        <div className="inner">

          <div className="h3-title-wrap mt0">
            <h3 className="title">{getTranslatedNameById("TWVudUl0ZW06NTEx")} {/* 비밀번호를 잊으셨나요? */}</h3>
          </div>

          {/* 아이디(이메일) [START] */}
          <div className="input-box">
            <label htmlFor="email" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06NDg0")} {/* 아이디(이메일)*/}
            </label>
            <div className="input">
              <input 
                id="email" 
                type="email" 
                placeholder={getTranslatedNameById("TWVudUl0ZW06NDg2")} 
                onInput={(e) => actions.setEmail(e.target.value)}
                value={input.email}
              />
            </div>
          </div>
          {/* 아이디(이메일) [END] */}

          {/* 이름 [START] */}
          <div className="input-box">
            <label htmlFor="name" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzEz")} 
            </label>
            <div className="input">
              <input 
                id="name" 
                type="text" 
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzI5")} 
                onInput={(e) => actions.setName(e.target.value)}
                value={input.name}
              />
            </div>
          </div>
          {/* 이름 [END] */}

          {/* 휴대전화 [START] */}
          <InputPhone disabled={!input.name || !input.email} checkFunc={checkForPw} />
          {/* 휴대전화 [END] */}

          {
            validateInput() &&
            <>
              {/* 새로운 비밀번호 [START] */}
              <div className="input-box">
                <label htmlFor="pw1" className="title starred">
                  {getTranslatedNameById("TWVudUl0ZW06NTE1")} {/* 새로운 비밀번호 */}
                </label>
                <div className="input">
                  <input 
                    id="pw1" 
                    type="password" 
                    className="private"
                    placeholder={getTranslatedNameById("TWVudUl0ZW06NDkx")} 
                    onInput={(e) => actions.setPassword(e.target.value)}
                    value={input.password}
                  />
                  <button type="button" className="btn icon-private" onClick={(e)=>window.WStoggleInputPrivate(e)}></button>
                </div>
                <span className="caption f-purple">{getTranslatedNameById("TWVudUl0ZW06NDg5")} {/* 숫자, 영문, 특수문자 조합 최소 6자 이상 */}</span>
                <div className="input mt20">
                  <label htmlFor="pw2" className="hide">버튼타입</label>
                  <input 
                    id="pw2" 
                    type="password" 
                    className="private"
                    placeholder={getTranslatedNameById("TWVudUl0ZW06NTE2")}
                    onInput={(e) => actions.setPasswordRe(e.target.value)}
                    value={input.password_re}
                  />
                  {
                    (input.password !== input.password_re) &&
                    <span className="caption f-red">{getTranslatedNameById("TWVudUl0ZW06NTEy")} {/* 비밀번호가 일치하지 않습니다. */}</span>
                  }
                  <button 
                    type="button" 
                    className="btn icon-private" 
                    onClick={(e)=>window.WStoggleInputPrivate(e)}
                  >
                  </button>
                </div>
              </div>
              {/* 비밀번호 [END] */}
            </>
          }

        </div>
      </section>
      <div className="bottom-bar">
        <div className="inner">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              disabled={!input.password || (input.password !== input.password_re)}
              onClick={resetPw}
            >
              {getTranslatedNameById("TWVudUl0ZW06NTE3")} {/* 비밀번호 재설정하기 */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFindPwSection;
