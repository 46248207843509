import React, { useEffect } from 'react';
import useInputStore from '../../store/common/useInputStore';
import { useNavigate } from "react-router-dom";
import InputPhone from '../../components/Inputs/InputPhone';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

const Register01Section = () => {

  const navigate = useNavigate();
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const { 
    input, 
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  // 마운트시 resetInput 초기화
  useEffect(() => {
    actions.resetInput();
  }, [actions]);

  // 필수 입력 항목 검사
  const validateInput = () => {
    return  input.nickName &&
            input.phoneNumber && 
            input.name && 
            input.isPhoneVerification && 
            input.passwordAlertType === 'success' &&
            input.emailAlertType === 'success';
  };

  // 전화번호 외의 모든 필수 항목이 입력되었는지 확인하는 함수
  const isPhoneDisabled = () => {
    return !(input.nickName &&
    input.name && 
    input.passwordAlertType === 'success' &&
    input.emailAlertType === 'success');
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">

          {/* 아이디(이메일) [START] */}
          <div className="input-box">
            <label htmlFor="email" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06NDg0")} {/* 아이디(이메일)*/}
            </label>
            <div className="input">
              <input 
                id="email" 
                type="email" 
                placeholder={getTranslatedNameById("TWVudUl0ZW06NDg2")} 
                onInput={(e) => actions.setEmail(e.target.value)}
              />
              <button 
                type="button" 
                className="btn purple" 
                onClick={actions.handleCheckEmail}
              >
                {getTranslatedNameById("TWVudUl0ZW06NDg1")} {/* 중복 확인 */}
              </button>
            </div>
            {input.emailAlert && 
              <span className={`caption ${input.emailAlertType === 'error' ? 'f-red' : 'f-blue'}`}>
                {input.emailAlert}
              </span>
            } 
          </div>
          {/* 아이디(이메일) [END] */}

          {/* 비밀번호 [START] */}
          <div className="input-box">
            <label htmlFor="pw1" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06NDg3")} {/* 비밀번호 */}
            </label>
            <div className="input">
              <input 
                id="pw1" 
                type="password" 
                className="private"
                placeholder={getTranslatedNameById("TWVudUl0ZW06NDg4")} 
                onInput={(e) => {
                  actions.setPassword(e.target.value);
                  actions.validatePasswords();
                }}
              >
              </input>
              <button type="button" className="btn icon-private" onClick={(e)=>window.WStoggleInputPrivate(e)}></button>
            </div>
            <span className="caption f-purple">
              {getTranslatedNameById("TWVudUl0ZW06NDg5")} {/* 숫자, 영문, 특수문자 조합 최소 6자 이상 */}
            </span>
            <div className="input mt20">
              <label htmlFor="pw2" className="hide">
                {getTranslatedNameById("TWVudUl0ZW06NDkw")} {/* 버튼타입 */}
              </label>
              <input 
                id="pw2" 
                type="password" 
                className="private"
                placeholder={getTranslatedNameById("TWVudUl0ZW06NDkx")} 
                onInput={(e) => {
                  actions.setPasswordRe(e.target.value);
                  actions.validatePasswords();
                }}
              >
              </input>
              <button type="button" className="btn icon-private" onClick={(e)=>window.WStoggleInputPrivate(e)}></button>
            </div>
            {input.passwordAlert && 
              <span className={`caption ${input.passwordAlertType === 'error' ? 'f-red' : 'f-blue'}`}>
                {input.passwordAlert}
              </span>
            }
          </div>
          {/* 비밀번호 [END] */}

          {/* 이름 [START] */}
          <div className="input-box">
            <label htmlFor="name" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzEz")} 
            </label>
            <div className="input">
              <input 
                id="name" 
                type="text" 
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzI5")} 
                onInput={(e) => actions.setName(e.target.value)}
              />
            </div>
          </div>
          {/* 이름 [END] */}

          {/* 닉네임 [START] */}
          <div className="input-box">
            <label htmlFor="nickname" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzE2")} 
            </label>
            <div className="input">
              <input 
                id="nickname" 
                type="text" 
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzMw")} 
                onInput={(e) => actions.setNickName(e.target.value)}
              />
            </div>
          </div>
          {/* 닉네임 [END] */}

          {/* 휴대전화 [START] */}
          <InputPhone disabled={isPhoneDisabled()} />
          {/* 휴대전화 [END] */}

        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big"
              onClick={()=>navigate("/register/2")}
              disabled={!validateInput()}
            >
              {getTranslatedNameById("TWVudUl0ZW06MzYz")} 
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register01Section;
