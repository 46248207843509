import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SwipeProductSection from '../../sections/grid/SwipeProductSection';
import sampleImage from "../../assets/images/sample/sample_nike.svg"; 
import { getVendorRanking } from '../../services/REST_apis/vendor';
import { useLanguage } from '../../services/languageContext';

const RankingBrandSection = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);  // 전체 vendor 데이터
  const [visibleBrands, setVisibleBrands] = useState([]);  // 현재 보여줄 vendor 데이터
  const [visibleDetails, setVisibleDetails] = useState({});
  const [showAll, setShowAll] = useState(false);  // 전체보기 버튼 상태
  const { getTranslatedNameById } = useLanguage();

  const ITEMS_PER_PAGE = 10;  // 한 번에 보여줄 개수

  // useEffect로 컴포넌트가 마운트될 때 API 호출
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        // API 호출하여 vendor 랭킹 데이터 가져오기
        const channel = window.WSgetChannel()
        const vendors = await getVendorRanking(channel);

        // API 응답에서 데이터 설정
        const updatedBrands = vendors.map((vendor, index) => ({
          id: vendor.id,
          name: channel === 'KO' || !vendor.store_name_en ? vendor.store_name_ko : vendor.store_name_en,
          rank: index + 1,
          thumbnail: vendor.detail_info__vendor_thumbnail,
          products: vendor.products
        }));

        setBrands(updatedBrands);  // 전체 브랜드 데이터 설정
        setVisibleBrands(updatedBrands.slice(0, ITEMS_PER_PAGE));  // 처음에는 10개만 보여줌
        setShowAll(updatedBrands.length > ITEMS_PER_PAGE);  // 데이터가 10개 이상일 경우에만 전체보기 버튼 표시
      } catch (error) {
        console.error('Error fetching brand data:', error);
      }
    };

    fetchBrands();  // API 호출
  }, []);

  const handleDetailToggle = (id) => {
    setVisibleDetails(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleShowAll = () => {
    setVisibleBrands(brands);  // 모든 브랜드 데이터를 보여줌
    setShowAll(false);  // 전체보기 버튼 숨김
  };

  if (visibleBrands.length === 0) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-ranking-brand">
        <ul className="ranking-brand-list">
          {visibleBrands.map(brand => (
            <li key={brand.id}>
              <div className="ranking-brand-wrap">
              <a
                href="#"
                title={brand.name}
                onClick={(e) => e.preventDefault()}  // 기본 동작 방지
              >
                <b className="brand-index">{brand.rank}</b>
                <div className="brand-thumbnail">
                  <img src={brand.thumbnail || sampleImage} alt={brand.name} /> 
                </div>
                <span className="brand-name">
                  {brand.name}
                </span>
              </a>
                <button 
                  type="button" 
                  className={`more-btn ${visibleDetails[brand.id] ? 'active' : ''}`} 
                  onClick={() => handleDetailToggle(brand.id)}
                >
                </button>
              </div>
              {visibleDetails[brand.id] && (
                <div className="ranking-brand-detail">
                  <SwipeProductSection 
                      products={brand && brand.products && brand.products.length > 0 ? brand.products : 'No Product'} 
                  />
                  <button 
                    type="button" 
                    className="btn big round more mt16"
                    onClick={() => navigate(`/brand/detail/${brand.id}`)}
                  >
                    {brand.name} {getTranslatedNameById('TWVudUl0ZW06Mjg4')}
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="btn-wrap pl16 pr16">
          {showAll && (
            <button 
              type="button" 
              className="btn big round more mt16"
              onClick={handleShowAll} 
            >
              {getTranslatedNameById('TWVudUl0ZW06Mjg5')}
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export default RankingBrandSection;
