import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useLanguage } from '../../services/languageContext';
import TopBar from '../../layouts/top_bar/TitleActionBar';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation(); // 현재 경로를 가져옴
  const { getTranslatedNameById, language } = useLanguage();

  // 쿼리 파라미터에서 검색어 읽어오기
  useEffect(() => {
    const query = searchParams.get('query');
    if (query) {
      setSearchTerm(query); // 검색어를 searchTerm에 설정
    }
  }, [searchParams]);

  const saveRecentSearch = (term) => {
    let recentSearches = JSON.parse(localStorage.getItem('recentSearches')) || [];

    if (!recentSearches.includes(term)) {
      recentSearches.push(term);
      if (recentSearches.length > 5) {
        recentSearches.shift();
      }
      localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
    }
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      saveRecentSearch(searchTerm);
      navigate(`/search/result?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  console.log('location', location);

  const handleBackClick = () => {
    if (location.pathname === '/search/empty') {
      // 검색 결과가 없는 경우, 카테고리 검색이면 홈으로 이동
      if (location.state?.fromCategory) {
        navigate('/'); // 카테고리 검색일 때는 홈으로
      } else {
        // 검색 프로세스로 돌아갈 때도 상태를 전달
        navigate('/search/process', { state: { fromEmpty: true } });
      }
    } else if (location.pathname === '/search/process') {
      // 검색 프로세스에서 빈 결과 페이지로 왔다가 다시 돌아온 경우
      if (location.state?.fromEmpty) {
        navigate('/'); 
      } else {
        navigate(-1); // 그 외는 기본 뒤로가기
      }
    } else {
      navigate(-1); // 그 외는 기본 뒤로가기
    }
  };
  
  

  return (
    <div className="top-bar border pt8 pb0">
      <div className="top-bar-inner gap10">
        <span
          onClick={handleBackClick} 
          title="클릭시 홈으로" 
          className="title" 
          style={{ cursor: 'pointer' }}
        >
        </span>
        <div className="input-box w100p">
          <div className="input">
            <input 
              type="search"
              placeholder={searchTerm || getTranslatedNameById("TWVudUl0ZW06NDY5")} // 검색어를 입력하세요.
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button 
              type="button" 
              className="icon-search"
              onClick={handleSearch}
            >
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
