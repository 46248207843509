import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';
import usePopupStore from '../../store/common/usePopupStore';
import { useLanguage } from '../../services/languageContext'; 
import api from "../../services/api"

const GoogleCallback = () => {
  const navigate = useNavigate();
  const { login } = useAuthStore();
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup, // 스낵바 오픈 함수
  }));
  const { language } = useLanguage(); 

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');

    if (authorizationCode) {
      api
        .post('/api-tg/google/callback', { code: authorizationCode })  // 구글 콜백 엔드포인트로 변경
        .then((response) => {
          console.log(response)
          if (response.data && response.data.tokenCreate && response.data.tokenCreate.token) {
            console.log('google login():: ', response.data)
            login(response.data);  // 받은 데이터를 그대로 처리
            openSnackBarPopup(
              language === 'KO' ? '정상적으로 로그인되었습니다.' : 'Successfully logged in.', 
              '', 
              ''
            );
            navigate('/');
          } else {
            openSnackBarPopup(
              language === 'KO' ? '구글 로그인 오류가 발생했습니다.' : 'An error occurred during Google login.', 
              '', 
              ''
            );
          }
        })
        .catch((error) => {
          console.error('구글 로그인 오류:', error);
          openSnackBarPopup(
            language === 'KO' ? '구글 로그인 오류가 발생했습니다.' : 'An error occurred during Google login.', 
            '', 
            ''
          );
          navigate('/login/main2');
        });
    } else {
      console.error(language === 'KO' ? "Authorization code가 없습니다." : "No authorization code found.");
      navigate('/login/main2');  // 실패 시 로그인 페이지로 이동
    }
  }, [navigate, language]);

  return <></>;
};

export default GoogleCallback;
