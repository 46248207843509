import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SampleImage from "../../assets/images/common/icons/icon_profile.svg";
import { getMyChild } from '../../services/GQ_apis/kid';
import api from "../../services/api"
import { useLanguage } from '../../services/languageContext';

const MyMenuKidDetailSection = () => {
  const { Page3depth } = useParams();
  const init = {
    id: '',
    name: '',
    birthDate: '',
    height: '',
    weight: '',
    footSize: '',
    headSize: '',
    thumbnail: '',
    etc: '',
    main: false,
    created_at: '',
    gender: '',
    brands: [],
    colors: [],
    items: []
  };

  const [kidData, setKidData] = useState(init);
  const [reportData, setReportData] = useState({
    height: { my_00:0, my_01:0, my_02:0, avg_00:0, avg_01:0, avg_02:0 },
    weight: { my_00:0, my_01:0, my_02:0, avg_00:0, avg_01:0, avg_02:0 },    
  });


  const { getTranslatedNameById, language } = useLanguage();
  
  const formatDateForInput = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toISOString().split('T')[0];  // 'T'를 기준으로 분리하고 날짜 부분만 반환
  };

  const parseDateForServer = (inputDate) => {
    if (!inputDate) return '';
    const date = new Date(inputDate);
    return date.toISOString();  // 서버로 보낼 때는 전체 ISO 형식으로 변환
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);

    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    return age;
  };

  const getChildReport = (cid) => {
    api.post('/api-tg/getChildReport', { cid: cid })
      .then(res => {
        const data = res.data;
        setReportData({
          height: { my_00: data.height.my_00, my_01: data.height.my_01, my_02: data.height.my_02, avg_00:data.height.avg_00, avg_01:data.height.avg_01, avg_02:data.height.avg_02 },
          weight: { my_00: data.weight.my_00, my_01: data.weight.my_01, my_02: data.weight.my_02, avg_00:data.weight.avg_00, avg_01:data.weight.avg_01, avg_02:data.weight.avg_02 },    
        });

        console.log(data);
      })
      .catch(e => {
        console.error('Failed to fetch average children data:', e);
      });
  };
  
  

  useEffect(() => {
    if (Page3depth) {
      getMyChild(Page3depth).then((res) => {
        const child = res.data.myChild;

        setKidData((prevData) => ({
          ...prevData,
          name: child.name,
          gender: child.gender,
          birthDate: formatDateForInput(child.birthDate),
          height: child.height,
          weight: child.weight,
          footSize: child.footSize,
          headSize: child.headSize,
          thumbnail: child.thumbnail,
          main: child.main,
          created_at: child.createdAt,
          brands: JSON.parse(child.brands),
          colors: JSON.parse(child.colors),
          items: JSON.parse(child.items)
        }));

        getChildReport(child.id); // Call after kid data is set
      });
    }
  }, [Page3depth]);

  return (
    <>
      <section className="section-mykid-detail">
        <div className="white-wrap">
          <div className="card kid">
            <div className="img-wrap">
              <img className="circle-img" src={kidData.thumbnail || SampleImage} alt="하늘이 이미지" />
              <span className="badge purple">Kids</span>
            </div>
            <div className="text-wrap">
              <div className="title-wrap">
                <strong className="title">{kidData.name}</strong>
                <div className="span-wrap">
                  <span className="age">
                    {language === 'KO' ? (
                      <>
                        (만) {calculateAge(kidData.birthDate)}세
                      </>
                    ) : (
                      <>
                        {calculateAge(kidData.birthDate)} years old
                      </>
                    )}
                  </span>
                  <span className="date">{kidData.birthDate}</span>
                </div>
              </div>
              <ul className="body-list">
                <li><span>{getTranslatedNameById("TWVudUl0ZW06MjY5")}{/* 키 */}</span><b>{kidData.height}cm</b></li>
                <li><span>{getTranslatedNameById("TWVudUl0ZW06Mjcw")}{/* 몸무게 */}</span><b>{kidData.weight}kg</b></li>
                <li><span>{getTranslatedNameById("TWVudUl0ZW06Mjcy")}{/* 발길이 */}</span><b>{kidData.footSize}cm</b></li>
                <li><span>{getTranslatedNameById("TWVudUl0ZW06Mjcx")}{/* 머리둘레 */}</span><b>{kidData.headSize}cm</b></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="white-wrap">
          <div className="h4-title-wrap">
            <h4 className="title">{getTranslatedNameById("TWVudUl0ZW06NTU1")}{/* 그로잉 리포트 */}</h4>
          </div>
          <div className="graph-wrap">
            <div className="graph-box height">
              <div className="info-wrap">
                <div className="title">
                  <span className="yellow">{getTranslatedNameById("TWVudUl0ZW06MjY5")}{/* 키 */}</span> {getTranslatedNameById("TWVudUl0ZW06NTU2")}{/* 단위 */} cm
                </div>
                <div className="infos">
                  <span className="info purple">
                    {getTranslatedNameById("TWVudUl0ZW06NTU3")}{/* 내 아이 */}
                  </span>
                  <span className="info yellow">
                    {getTranslatedNameById("TWVudUl0ZW06NTU4")}{/* 평균 */}
                  </span>
                </div>
              </div>
              <ul className="graphs">
                <li className="graph">
                  <div className="bars" style={{ height: '100rem', overflow:'hidden' }}>
                    <span className="bar purple" style={{ height: `${reportData.height.my_00}%` }}>
                    </span>
                    <span className="bar yellow" style={{ height: `${reportData.height.avg_00}%` }}>
                    </span>
                  </div>
                  <p>{getTranslatedNameById("TWVudUl0ZW06NTU5")}{/* 현재 */}</p>
                </li>
                <li className="graph">
                  <div className="bars" style={{ height: '100rem', overflow:'hidden' }}>
                    <span className="bar purple" style={{ height: `${reportData.height.my_01}%` }}>
                    </span>
                    <span className="bar yellow" style={{ height: `${reportData.height.avg_01}%` }}>
                    </span>
                  </div>
                  <p>{getTranslatedNameById("TWVudUl0ZW06NTYw")}{/* 1년후 */}</p>
                </li>
                <li className="graph">
                  <div className="bars" style={{ height: '100rem', overflow:'hidden' }}>
                    <span className="bar purple" style={{ height: `${reportData.height.my_02}%` }}>
                    </span>
                    <span className="bar yellow" style={{ height: `${reportData.height.avg_02}%` }}>
                    </span>
                  </div>
                  <p>{getTranslatedNameById("TWVudUl0ZW06NTYx")}{/* 2년후 */}</p>
                </li>
              </ul>
            </div>
            <div className="graph-box weight">
              <div className="info-wrap">
                <div className="title">
                  <span className="yellow">{getTranslatedNameById("TWVudUl0ZW06Mjcw")}{/* 몸무게 */}</span> {getTranslatedNameById("TWVudUl0ZW06NTU2")}{/* 단위 */} kg
                </div>
                <div className="infos">
                  <span className="info purple">
                    {getTranslatedNameById("TWVudUl0ZW06NTU3")}{/* 내 아이 */}
                  </span>
                  <span className="info yellow">
                    {getTranslatedNameById("TWVudUl0ZW06NTU4")}{/* 평균 */}
                  </span>
                </div>
              </div>
              <ul className="graphs">
                <li className="graph">
                  <div className="bars" style={{ height: '100rem', overflow:'hidden' }}>
                    <span className="bar purple" style={{ height: `${reportData.weight.my_00}%` }}>
                    </span>
                    <span className="bar yellow" style={{ height: `${reportData.weight.avg_00}%` }}>
                    </span>
                  </div>
                  <p>{getTranslatedNameById("TWVudUl0ZW06NTU5")}{/* 현재 */}</p>
                </li>
                <li className="graph">
                  <div className="bars" style={{ height: '100rem', overflow:'hidden' }}>
                    <span className="bar purple" style={{ height: `${reportData.weight.my_01}%` }}>
                    </span>
                    <span className="bar yellow" style={{ height: `${reportData.weight.avg_01}%` }}>
                    </span>
                  </div>
                  <p>{getTranslatedNameById("TWVudUl0ZW06NTYw")}{/* 1년후 */}</p>
                </li>
                <li className="graph">
                  <div className="bars" style={{ height: '100rem', overflow:'hidden' }}>
                    <span className="bar purple" style={{ height: `${reportData.weight.my_02}%` }}>
                    </span>
                    <span className="bar yellow" style={{ height: `${reportData.weight.avg_02}%` }}>
                    </span>
                  </div>
                  <p>{getTranslatedNameById("TWVudUl0ZW06NTYx")}{/* 2년후 */}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="white-wrap">
          {/* 브랜드 체크 영역[START]*/}
          <div className="check-wrap mt30">
            <div className="h4-title-wrap">
              <h4 className="title">{getTranslatedNameById("TWVudUl0ZW06MjY1")}{/* 브랜드 */}</h4>
            </div>
            <ul className="check-list no-input">
              {kidData.brands.map((item, index) => (
                <li key={index} className='black'>
                  <label htmlFor={`check${index}`}>{item}
                    <input id={`check${index}`} type="checkbox" />
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 브랜드 체크 영역[END]*/}
          {/* 색상 체크 영역[START]*/}
          <div className="check-wrap mt30">
            <div className="h4-title-wrap">
              <h4 className="title">{getTranslatedNameById("TWVudUl0ZW06NTYy")}{/* 색상 */}</h4>
            </div>
            <ul className="check-list color">
              {kidData.colors.map((item, index) => (
                <li key={index} className={item.class}>
                  <label htmlFor={`check${index}`}>{item.name}
                    <input id={`check${index}`} type="checkbox" />
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 색상 체크 영역[END]*/}
          {/* 아이템 체크 영역[START]*/}
          <div className="check-wrap mt30">
            <div className="h4-title-wrap">
              <h4 className="title">{getTranslatedNameById("TWVudUl0ZW06NTYz")}{/* 아이템 */}</h4>
            </div>
            <ul className="check-list no-input">
              {kidData.items.map((item, index) => (
                <li key={index} className='black'>
                  <label htmlFor={`check${index}`}>{item}
                    <input id={`check${index}`} type="checkbox"/>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 아이템 체크 영역[END]*/}
        </div>
      </section>
    </>
  );
};

export default MyMenuKidDetailSection;
