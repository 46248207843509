import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';

const Init = () => {

  const { pathname } = useLocation();
  const { fetchUser } = useAuthStore();

  // 페이지 변경 감지
  useEffect(() => {
    
    const articles = document.querySelectorAll('article');
    
    articles.forEach(article => {
      article.scrollTop = 0;
    });
    fetchUser();
    
  }, [pathname]);

  // 채널 변경 감지
  useEffect(()=>{
  
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('checkoutToken_')) {
        localStorage.removeItem(key);
      }
    });

  },[localStorage.getItem('zz_channel')])

  return null;
};

export default Init;
