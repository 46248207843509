import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import closeIcon from '../../assets/images/common/icons/icon_close_btn.svg';

const ImageSwipeGridSection = ({ photos, onRemovePhoto }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // 클릭된 이미지를 관리하는 상태
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달의 열림 상태

  useEffect(() => {
    setImages(photos);
  }, [photos]);

  // 이미지 클릭 시 모달을 열고 해당 이미지를 설정
  const handleImageClick = (photo) => {
    setSelectedImage(photo);
    setIsModalOpen(true);
  };

  // 모달 닫기 함수
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // 모달 배경 클릭 시 닫기 함수 (이미지를 클릭했을 때는 닫히지 않도록 설정)
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  const handleRemove = (index) => {
    console.log("Remove image at position:", index);
    const newImages = images.filter((_, i) => i !== index); // 선택한 인덱스의 이미지를 제거
    setImages(newImages);
  };

  return (
    <section className="section-grid">
      <Swiper
        modules={[Autoplay, FreeMode]}
        spaceBetween={8}
        slidesPerView='auto'
        loop={false}
        freeMode={true}
        className="card-tabs"
        touchMoveStopPropagation={true}
      >
        {photos.map((photo, index) => (
          <SwiperSlide key={index} style={{ width: '110rem' }}>
            <div className='tab card small'>
              <div className="img-wrap">
                <button 
                  className="close-button"
                  onClick={() => onRemovePhoto(index)}
                  style={{
                    position: 'absolute', 
                    top: '10rem', 
                    left: '10rem', 
                    background: 'none', 
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  <img src={closeIcon} alt="Remove attachment" style={{ width: '24rem', height: '24rem' }} />
                </button>
                <img 
                  src={photo} 
                  alt={`Attached image ${index + 1}`} 
                  onClick={() => handleImageClick(photo)} // 클릭 시 모달 열기
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* 모달 구현 */}
      {isModalOpen && (
        <div className="modal" style={modalStyles} onClick={handleBackgroundClick}>
          <div className="modal-content" style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
            <span
              className="close"
              onClick={handleCloseModal}
              style={closeButtonStyles}
            >
              &times;
            </span>
            <img
              src={selectedImage}
              alt="Selected large view"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </div>
      )}
    </section>
  );
};

// 모달 스타일
const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyles = {
  position: 'relative',
  maxWidth: '100%',
  maxHeight: '100%',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10rem',
  right: '10rem',
  fontSize: '20rem', // 폰트 크기 조정
  lineHeight: '24rem', // 폰트 크기와 동일한 line-height 설정
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // 반투명 검정 배경
  borderRadius: '50%', // 둥근 모서리
  width: '26rem', // 고정된 너비
  height: '26rem', // 고정된 높이
  display: 'flex', // 버튼 내부의 'X'를 중앙에 배치
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center', // 중앙 정렬
};

export default ImageSwipeGridSection;
