import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { meData } from '../../services/GQ_apis/me';
import { deleteAddress, setDefaultAddress } from '../../services/GQ_apis/address';
import { useLanguage } from '../../services/languageContext';

const DeliveryListSection = () => {

  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [fromOrder, setFromOrder] = useState(false); // fromOrder 상태 관리
  const { getTranslatedNameById, language } = useLanguage();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromOrderParam = urlParams.get("from-order");
    
    if (fromOrderParam == "") {
      setFromOrder(true);
    } else {
      setFromOrder(false);
    }

    fetchDelivery();
  }, [fromOrder]);

  // 배송지 리스트 불러오는 메소드
  const fetchDelivery = () => {
    meData().then((res)=>{
      console.log('me res', res);
      setAddressList(res.data.me.addresses);
    })
  }

  const deleteDelivery = (addressId) => {
    deleteAddress(addressId).then((res)=>{
      fetchDelivery();
      window.location.reload();
    });
  }

  const selectedDefaultAddress = (id) => {
    setDefaultAddress(id,'SHIPPING');
    setDefaultAddress(id,'BILLING');
    
    if (fromOrder) {
      // ?from-order가 있는 경우 /pay/process로 이동
      navigate('/pay/process');
    } else {
      navigate('/');
    }

    setTimeout(() => {
      window.location.reload();
    }, 100); // 100밀리초 후 페이지 새로고침
  }

  // mounted
  useEffect(()=>{
    fetchDelivery();
  },[])

  return (
    <>
      <section className="section-delivery-list">
        <div className="inner">

        {
          addressList.length > 0 ?
          addressList.map((address) => {
            return (
              <div className="card delivery" key={address.id}>
                <div className="name-wrap">
                  <strong className="name">
                    {address.metadata[0].value}
                  </strong>
                  <span className="badge red">
                    {address.country.code}
                  </span>
                  {
                    address.isDefaultShippingAddress &&
                    <span className="badge blue">
                      {/* 기본배송지 */}
                      {getTranslatedNameById("TWVudUl0ZW06MzM0")}
                    </span>
                  }
                  <button 
                    type="button" 
                    className="btn purple"
                    onClick={()=>selectedDefaultAddress(address.id)}
                  >
                    {/* 선택 */}
                    {getTranslatedNameById("TWVudUl0ZW06MzM1")}
                  </button>
                </div>
                <div className="info-wrap">
                  {address.firstName} {address.phone}
                </div>
                <div className="address-wrap">
                  {address.streetAddress1} {address.streetAddress2}
                </div>
                {
                  ! fromOrder && <>
                    <div className="btn-wrap">
                      {
                        address.country.code === 'KR' &&
                        <Link to={`/delivery/domestic/${address.id}`} title="">
                          {/* 수정 */}
                          {getTranslatedNameById("TWVudUl0ZW06MzM2")}
                        </Link>
                      }
                      {
                        address.country.code === 'US' &&
                        <Link to={`/delivery/overseas/${address.id}`} title="">
                          {/* 수정 */}
                          {getTranslatedNameById("TWVudUl0ZW06MzM2")}
                        </Link>
                      }
                      <button 
                        type="button"
                        onClick={()=>deleteDelivery(address.id)}
                      >
                        {/* 삭제 */}
                        {getTranslatedNameById("TWVudUl0ZW06MzA4")}
                      </button>
                    </div>
                  </>
                }

              </div>
            );
          }) :
          <>
            {/* 등록된 배송지가 없습니다. */}
            {getTranslatedNameById("TWVudUl0ZW06MzM3")}<br/> 
            {/* 배송지를 추가해주세요. */}
            {getTranslatedNameById("TWVudUl0ZW06MzM4")}
          </>
        }
        
        

        </div>
      </section>
      {/* 결제하기 영역 [START]*/}
      <div className="bottom-bar">
        <div className="inner">
          <button 
            type="button" 
            className="btn big purple"
            onClick={()=> fromOrder ? 
              navigate('/delivery/domestic?from-order') : 
              navigate('/delivery/domestic')
            }
          >
            {/* 배송지 추가 */}
            {getTranslatedNameById("TWVudUl0ZW06MzM5")} 
          </button>
        </div>
      </div>
      {/* 결제하기 영역 [END]*/}
    </>
  );
};

export default DeliveryListSection;
