import React, { useState } from 'react';
import usePopupStore from '../../store/common/usePopupStore';
import useInputStore from '../../store/common/useInputStore';
import { sendSms, confirmPhone, confirmCode } from '../../services/SEND_apis';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

const InputPhone = ({ disabled = false, checkFunc }) => { 
  const location = useLocation();
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const { input, actions } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  const [authCode, setAuthCode] = useState(null)

  // 전화번호 유효성 검사 및 인증번호 요청 함수
  const handleRequestVerificationCode = async () => {
    const regex = /^[0-9]{10,11}$/;
    if (!regex.test(input.phoneNumber)) {
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjI3"), '', 'error'); // 유효한 휴대폰 번호를 입력해주세요.
      return;
    }
  
    try {
      /// 1) 핸드폰 중복여부 확인
      if (location.pathname === '/register/1') {
        await confirmPhone(input.phoneNumber);
      }

      /// 2) 인증번호 전송
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjI4"), '', 'success'); // 인증번호가 전송되었습니다.
      actions.setIsPhoneSend(true);
      const response = await sendSms(input.phoneNumber);
      if (response.status == 200) {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjI4"), '', 'success');
        setAuthCode(response.data.access_id)
        actions.setIsPhoneSend(true); // 인증번호 전송 상태를 true로 설정
      } else {
        openSnackBarPopup('인증번호 전송 실패: ' + response, '', 'error');
      }
    } catch (error) {
      console.log(error)
      if(error.response.status == 409) {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjI5"), '', 'error'); // 이미 존재하는 휴대전화 번호입니다.
      } else {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjMw") + error, '', 'error'); //  네트워크 오류가 발생했습니다 :
      }
    }
  };
  
  // 인증번호 일치 여부 확인 및 결과 표시 함수
  const handleVerifyCode = async () => {
    // checkFunc가 있는 경우 먼저 실행
    if (checkFunc) {
      const isCheckFuncPassed = await checkFunc(); // checkFunc가 비동기 함수일 수 있으므로 await 사용
      if (!isCheckFuncPassed) {
        return;
      }
    }

    if (input.phoneCode) {
      // 09-26 kdy 임시처리
      if(input.phoneCode == '123456') {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjMx"), '', 'success'); // 인증되었습니다.
        actions.setIsPhoneVerification(true)
        return
      } 
      
      confirmCode(authCode, input.phoneCode).then(() => {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjMx"), '', 'success');
        actions.setIsPhoneVerification(true)
      }).catch(e => {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjMy"), '', 'error')
        return
      })
    } else {
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NjMz"), '', 'error'); // 인증번호를 입력해주세요.
    }
  };

  return (
    <>
      <div className="input-box">
        <label htmlFor="phone" className="title starred">
          {getTranslatedNameById("TWVudUl0ZW06NDky")} 
        </label>
        <div className="input">
          <input 
            id="phone" 
            type="number"
            placeholder={getTranslatedNameById("TWVudUl0ZW06NDkz")} 
            onInput={(e) => actions.setPhoneNumber(e.target.value)}
            disabled={disabled || input.isPhoneSend} 
          />
          <button 
            type="button" 
            className="btn purple"
            disabled={disabled || !input.phoneNumber || input.isPhoneSend} 
            onClick={handleRequestVerificationCode}
          >
            {getTranslatedNameById("TWVudUl0ZW06NDk0")} {/* 인증번호받기 */}
          </button>
        </div>
        <div className="input">
          <input 
            id="phoneCode" 
            type="number" 
            placeholder={getTranslatedNameById("TWVudUl0ZW06NDk2")} 
            onInput={(e) => actions.setPhoneCode(e.target.value)}
            disabled={disabled || input.isPhoneVerification} 
          />
          <button 
            type="button" 
            className="btn purple"
            onClick={handleVerifyCode}
            disabled={disabled || input.isPhoneVerification} 
          >
            {getTranslatedNameById("TWVudUl0ZW06NDk1")} {/* 확인 */}
          </button>
        </div>
        {input.isPhoneSend && (
          <span className="caption mt10">
            {getTranslatedNameById("TWVudUl0ZW06NDk3")} {/* 인증번호가 전송되었습니다. 입력해주세요. */}
          </span>
        )}
      </div>
    </>
  );
};

export default InputPhone;
