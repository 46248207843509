import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const tabs = [
  { name: 'Home', class: 'home', route: '/' },
  { name: 'Category', class: 'category', route: '/category' },
  { name: 'Special', class: 'special', route: '/coupons/2' },
  { name: 'Wish', class: 'promotion', route: '/mymenu/wish-list' },
  { name: 'MY menu', class: 'mymenu', route: '/mymenu/profile' },
];

const BottomNav = () => {
  const [activeTab, setActiveTab] = useState('Home');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentTab = tabs.find(tab => tab.route === location.pathname);
    setActiveTab(currentTab ? currentTab.name : 'Home');
  }, [location]);

  const handleTabClick = (tab) => {
    if (tab.name === 'Special') {
      // Special 탭을 클릭하면 state에 'from' 값을 설정
      navigate(tab.route, { state: { from: 'special' } });
    } else {
      // 다른 탭은 그냥 route로 이동
      navigate(tab.route);
    }
    setActiveTab(tab.name);
  };

  return (
    <div className="bottom-bar">
      <div className="bottom-bar-inner">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
              onClick={() => handleTabClick(tab)}
              type="button"
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
