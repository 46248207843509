import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GridSection from '../../sections/grid/GridSection';

// URL에서 쿼리 파라미터를 가져오는 함수
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResultSection = () => {
  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const [searchTerm, setSearchTerm] = useState('');
  const query = useQuery();
  const navigate = useNavigate(); // 페이지 이동을 위한 navigate
  const location = useLocation(); // state 확인을 위한 useLocation

  // URL의 쿼리 파라미터에서 검색어를 가져와 상태에 설정
  useEffect(() => {
    const searchQuery = query.get('query');
    setSearchTerm(searchQuery || ''); // 검색어가 없으면 빈 문자열로 설정
  }, [query]);

  // totalCount를 업데이트하는 함수
  const handleTotalCountChange = (count) => {
    setTotalCount(count);
    setLoading(false); // 로딩이 끝난 후 로딩 상태를 false로 변경
  };

  // totalCount가 0이면 빈 페이지로 이동, 로딩 중이 아닐 때만 검사
  useEffect(() => {
    if (!loading && totalCount === 0) {
      // 검색 결과가 없을 때 /search/empty로 이동하면서 fromEmpty 상태 전달
      navigate('/search/empty', { state: { fromCategory: location.state?.fromCategory, fromEmpty: true } });
    }
  }, [totalCount, loading, navigate, location.state]);
  
  return (
    <>
      <section className="section-search">
        <div className="inner">
          <h4 className="title">
          </h4>
          {searchTerm && ( // 검색어가 있을 때만 GridSection을 렌더링
            <GridSection 
              key={searchTerm} // 검색어가 바뀔 때마다 GridSection이 새로 렌더링됨
              type="big"
              option={true}
              infinity={true}
              search={searchTerm} // searchTerm을 GridSection에 전달
              onTotalCountChange={handleTotalCountChange} // totalCount를 업데이트하는 함수 전달
            />
          )}
        </div>
      </section>
    </>
  );
};

export default SearchResultSection;
