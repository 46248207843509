import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';
import BottomNav from '../../layouts/bottom_bar/BottomNav';

import { meData } from '../../services/GQ_apis/me';
import OrderTab from "../../components/Tabs/OrderTab";

import OrderOrderListSection from '../../sections/order/OrderOrderListSection'
import OrderOrderDetailSection from '../../sections/order/OrderOrderDetailSection'
import OrderRequestCancelSection from '../../sections/order/OrderRequestCancelSection'
import OrderRequestCancel1Section from '../../sections/order/OrderRequestCancel1Section'
import OrderRequestCancel2Section from '../../sections/order/OrderRequestCancel2Section'
import OrderExchangeListSection from '../../sections/order/OrderExchangeListSection'
import OrderRequestExchangeSection from '../../sections/order/OrderRequestExchangeSection'
import OrderExchangeDetailSection from '../../sections/order/OrderExchangeDetailSection'
import OrderReturnListSection from '../../sections/order/OrderReturnListSection'
import OrderReturnDetailSection from '../../sections/order/OrderReturnDetailSection'
import OrderRequestReturnSection from '../../sections/order/OrderRequestReturnSection'
import OrderCancelListSection from '../../sections/order/OrderCancelListSection'
import OrderCancelDetailSection from '../../sections/order/OrderCancelDetailSection'

import { useLanguage } from '../../services/languageContext';
import usePopupStore from '../../store/common/usePopupStore';

function OrderPage() {

  const { Page2depth } = useParams();
  const [orderList, setOrderList] = useState([]);
  const { getTranslatedNameById, language } = useLanguage();

  const { ToastPopup } = usePopupStore((state) => ({
    // openSnackBarPopup: state.openSnackBarPopup,
    // openToastPopup: state.openToastPopup,
    ToastPopup: state.ToastPopup
  }));

  useEffect(()=>{

    meData().then((res)=>{
      setOrderList(res.data.me.orders.edges);
    })  

  },[ToastPopup])

  // console.log('meData', orderList);

  let topBarUrl;

  if (
    Page2depth === 'order-list' || 
    Page2depth === 'exchange-list' || 
    Page2depth === 'return-list' || 
    Page2depth === 'cancel-list'
  ) {
    topBarUrl = '/mymenu/profile';
  } else {
    topBarUrl = -1;
  }

  return (
    <>
      <TopBar title={getTranslatedNameById("TWVudUl0ZW06NDQ3")} url={topBarUrl} />
      <article id="orderPage">
        {
          (
            Page2depth === 'order-list' ||
            Page2depth === 'exchange-list' ||
            Page2depth === 'return-list' ||
            Page2depth === 'cancel-list'
          ) && <OrderTab orderList={orderList}/>
        }
        {Page2depth === 'order-list' && <OrderOrderListSection orderList={orderList}/>}
        {Page2depth === 'exchange-list' && <OrderExchangeListSection orderList={orderList}/>}
        {Page2depth === 'return-list' && <OrderReturnListSection orderList={orderList}/>}
        {Page2depth === 'cancel-list' && <OrderCancelListSection orderList={orderList}/>}

        {Page2depth === 'order-detail' && <OrderOrderDetailSection/>}
        {Page2depth === 'request-cancel1' && <OrderRequestCancel1Section/>}
        {Page2depth === 'request-cancel2' && <OrderRequestCancel2Section/>}

        {Page2depth === 'request-exchange' && <OrderRequestExchangeSection/>}
        {Page2depth === 'exchange-detail' && <OrderExchangeDetailSection/>}
        {Page2depth === 'return-detail' && <OrderReturnDetailSection/>}
        {Page2depth === 'request-return' && <OrderRequestReturnSection/>}
        {Page2depth === 'cancel-detail' && <OrderCancelDetailSection/>}
        {Page2depth === 'request-cancel' && <OrderRequestCancelSection/>}
      </article>
      {
        (
          Page2depth !== 'mykid-profile' &&
          Page2depth !== 'mykid-modify' &&
          Page2depth !== 'profile-modify' &&
          Page2depth !== 'coupons1' &&
          Page2depth !== 'coupons2' &&
          Page2depth !== 'request-cancel1' &&
          Page2depth !== 'request-cancel2' &&
          Page2depth !== 'request-exchange' &&
          Page2depth !== 'request-return' &&
          Page2depth !== 'request-cancel' &&
          Page2depth !== 'myreview-write'
        ) && <BottomNav />
      }
    </>
  )
}

export default OrderPage;
