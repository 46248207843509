// eslint-disable-next-line
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getProductByVendor, toggleProductWishList } from '../../services/GQ_apis/product';
import useAuthStore from '../../store/common/useAuthStore';
import ThumbnailImage from '../../components/common/ThumbnailImage';
import blackImg from '../../assets/images/sample/black_img.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';

const SwipeGridSection = ({
  category='',
  collection='',
  meta='',
  infinity=false
}) => {

  const { globalObserver, observerUpdate } = useAuthStore();

  // 상품 데이터 호출 메소드 [START]
  const [gridLi, setGridLi] = useState([]);
  const [loading, setLoading] = useState(false); // 페이지 변수 삭제
  const [hasMore, setHasMore] = useState(true); // 'hasMore' 상태 관리를 변경 가능하도록 수정
  const [afterCursor, setAfterCursor] = useState(null); // 커서 기반 페이지네이션을 위한 상태 추가
  const observer = useRef();  // Intersection Observer를 위한 ref 생성
  const lastGridElementRef = useRef(null); // 마지막 그리드 요소를 참조하기 위한 ref
  const [wishList, setWishList] = useState([]);
  const [selectedThumbnails, setSelectedThumbnails] = useState({});

  const channel = useMemo(()=>{
    if(window.WSgetChannel() == 'EN') return 'en' 
    if(window.WSgetChannel() == 'KO') return 'ko' 
  },[window.WSgetChannel()])

  const fetchProducts = useCallback(() => {

    if ((loading || !hasMore)) return; 
  
    setLoading(true);
    getProductByVendor(
      afterCursor, // Next 페이지 ID
      category, // 카테고리
      collection, // 콜렉션
      channel,
      meta,
    ).then((res) => { // getProductList 호출 시 현재 커서 전달
      const data = res.data.products.edges;
      if (gridLi.length === 0) setGridLi(data);
      else setGridLi(prev => [...prev, ...data]); // 기존 목록에 새로운 데이터 추가
      if(data[data.length-1]) setAfterCursor(data[data.length - 1].cursor); // 다음 페이지 로드를 위한 커서 업데이트
      setHasMore(res.data.products.pageInfo.hasNextPage); // 더 불러올 데이터가 있는지 업데이트
      setLoading(false);
    });
  }, [afterCursor, hasMore, loading]);

  useEffect(() => {
    const currentObserver = observer.current;
    if (currentObserver) currentObserver.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          fetchProducts();
        }
      });

      if (lastGridElementRef.current) {
        observer.current.observe(lastGridElementRef.current);
      }

    return () => {
      if (currentObserver) currentObserver.disconnect();
    };

  }, [fetchProducts, loading, hasMore, infinity]);

  // 상품 데이터 호출 메소드 [END]

  const updateWishList = (productId) => {
    toggleProductWishList(productId).then(()=>{
      setLoading(false);
      observerUpdate(); 
      setLoading(false);
      console.log('globalObserver', globalObserver) 
    })
  }

  const colorClick = (color, variants, productId) => {
    const selectedVariant = variants.find(variant =>
      variant.attributes.some(attr => 
        attr.values.some(value => value.name.toLowerCase().trim() === color.toLowerCase().trim())
      )
    );

    if (selectedVariant && selectedVariant.media.length > 0) {
      const newThumbnailUrl = selectedVariant.media[0].url;
      setSelectedThumbnails(prev => ({
        ...prev,
        [productId]: newThumbnailUrl  
      }));
    }
  };

  useEffect(()=>{
    setGridLi([]);
    setHasMore(true);
    setAfterCursor(null);
    if(infinity) fetchProducts();
  },[category, meta])

  if (gridLi.length === 0 && loading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }
  
  return (
    <>
      {/* 그리드(리스트) [START] */}
      <section className="section-grid">

        <Swiper
          // 스와이퍼 옵션
          modules={[Autoplay, FreeMode]}
          spaceBetween={8}
          slidesPerView='auto'
          loop={gridLi.length > 4 ? true : false}
          freeMode={true}
          className="card-tabs"
          nested={true} // 중첩 스와이퍼로 설정
          touchMoveStopPropagation={true} // 부모 스와이퍼에 이벤트 전파 중지
        >
          {gridLi.map((item, index) => (
            <SwiperSlide key={index} style={{ width: '110rem' }}>
              <div className='tab card small'>
                <div className="info-wrap">
                <div className="img-wrap">
                  <Link 
                    title="클릭시 해당 상품 페이지로 이동"
                    to={"/product/"+item.node.id}
                  > 
                    {
                      item.node.thumbnail ?
                      <ThumbnailImage 
                        thumbnailUrl={item.node.thumbnail.url} 
                        thumbnailAlt={item.node.thumbnail.alt} 
                      /> :
                      <img src={blackImg} alt='이미지 없음'/>
                    }
                  </Link>
                  <button 
                    type="button" 
                    className={`icon-heart white ${ item.node.hasMyWish ? 'active' : ''}`}
                    onClick={()=>{updateWishList(item.node.id)}}
                  >
                    {item.node.hasMyWish}
                  </button>
                </div>
                  {item.node.vendor !== null && 
                    <Link 
                      title="클릭시 해당 상품 페이지로 이동"
                      to={"/brand/detail/"+item.node.vendor.id}
                      className="brand"
                    >
                      {
                        item.node.channel === 'ko' ? (
                          item.node.vendor.storeNameKo ? item.node.vendor.storeNameKo : 'no-store-name'
                        ) : item.node.channel === 'en' ? (
                          item.node.vendor.storeNameEn ? item.node.vendor.storeNameEn : 'no-store-name'
                        ) : (
                          'no-store-name'
                        )
                      }
                    </Link>
                  }
                  <Link 
                    title="클릭시 해당 상품 페이지로 이동"
                    to={"/product/"+item.node.id}
                  >
                    <div className="title">
                      {
                        item?.node?.channel === 'ko' && item?.node?.name ? (
                          item?.node?.name
                        ) : item?.node?.channel === 'en' && item?.node?.translation?.name ? (
                          item?.node?.translation?.name
                        ) : (
                          'no-title-item'
                        )
                      }
                    </div>
                    <div className="price-wrap">
                      {
                        item?.node?.pricing?.onSale &&
                        (
                          <div className="before-price">
                            <span className="f-purple">Sale</span>
                            <span className="price">
                              {window.WSformatPrice(item?.node?.pricing?.priceRangeUndiscounted?.start?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.stop?.gross?.currency)}
                              {
                                item?.node?.pricing?.priceRangeUndiscounted?.start?.gross?.amount != item?.node?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount &&
                                <>
                                  <>~</>
                                  {window.WSformatPrice(item?.node?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.stop?.gross?.currency)}
                                </>
                              }
                            </span>
                          </div>
                        )
                      }
                      <div className="now-price">
                        {/* <span className="f-purple">{item.discount.discounted.raw}%</span> */}
                        <span className="price f12">
                          {window.WSformatPrice(item?.node?.pricing?.priceRange?.start?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.start?.gross?.currency)}
                          {item?.node?.pricing?.priceRange?.stop?.gross?.amount !== item?.node?.pricing?.priceRange?.start?.gross?.amount && <>
                            ~
                            {window.WSformatPrice(item?.node?.pricing?.priceRange?.stop?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.stop?.gross?.currency)}
                          </>}
                        </span>
                      </div>
                    </div>
                  </Link>
                  <div className="colors-wrap mt0 pb10">
                    <ul className="colors">
                      {item.node.color.values.length > 0 &&
                        item.node.color.values.map((colorItem, colorIndex) => (
                          <li className="color" key={colorItem + colorIndex}>
                            <button
                              type="button"
                              className={colorItem.name}
                              onClick={() => colorClick(colorItem.name, item.node.variants, item.node.id)}  // productId 추가
                            >
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

      </section>
      {/* 그리드(리스트) [END] */}

      {/* 스크롤 타켓 [START] */}
      <div className="scroll-target" ref={lastGridElementRef}>
        {
          hasMore && loading &&
          <div className="loading-wrap">
            <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        }
      </div>
      {/* 스크롤 타켓 [END] */}

    </>
  );
};

export default SwipeGridSection;
