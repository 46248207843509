import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from '../../services/languageContext';

const PayResultSection = () => {

  const navigate = useNavigate();
  const { getTranslatedNameById, language } = useLanguage();
  
  return (
    <>
      <section className="section-pay-result">
        <div className="white-wrap">
          <div className="title-wrap">
            <strong className="title">
              주문이 완료되었어요
            </strong>
            <p className="text">
              {getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* "주문번호" */} YADJIDJIA
            </p>
          </div>
          <div className="gray-box">
            <div className="h4-title-wrap">
              <h4 className="title">
                배송지
              </h4>
              <Link 
                to ="/delivery/list" 
                className="a-more-btn" 
                title=""
              >
                변경하기
              </Link>
            </div>
            <p className="text">
              이온유 010-1234-5678
            </p>
            <span className="f-gray">
              서울 관악구 신림동 123-556 A동 1202호
            </span>
          </div>
        </div>
        <div className="white-wrap pb60">
          <div className="h4-title-wrap border">
            <h4 className="title">
              결제 정보
            </h4>
          </div>
          <ul className="order-list">
            <li><span>총 주문 금액</span><b>890,600원</b></li>
            <li><span>총 배송비</span><b>3,000원</b></li>
            <li><span>총 즉시할인금액</span><b>3,000원</b></li>
            <li><span>총 쿠폰할인금액</span><b>3000원</b></li>
            <li><span>총 사용적립금</span><b>3000원</b></li>
            <li><span>총 사용적립금</span><b>3000원</b></li>
            <li><span>총 결제 금액</span><b className="f-purple f16">880,706원</b></li>
          </ul>
          <div className="gray-box flex between pt10 pb10">
            <b className="f14">가상계좌</b>
            <span className="f14">42,000원</span>
          </div>
        </div>
      </section>
      {/* 계속 쇼핑하기, 구매내역 확인 [START]*/}
      <div className="bottom-bar">
        <div className="inner">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn big gray"
              onClick={()=>navigate('/')}
            >
              계속 쇼핑하기
            </button>
            <button 
              type="button" 
              className="btn big purple"
              onClick={()=>navigate('/mymenu/order-detail')}
            >
              구매내역 확인  
            </button>
          </div>
        </div>
      </div>
      {/* 계속 쇼핑하기, 구매내역 확인 [END]*/}
    </>
  );
};

export default PayResultSection;

