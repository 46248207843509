import React from 'react';
import { useLanguage } from '../../../../services/languageContext';

const PopupRegisterDetail1 = ({closePopup}) => {
  const { getTranslatedNameById } = useLanguage(); 

  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          {getTranslatedNameById('TWVudUl0ZW06NjQw')} {/* 만 14세 이상입니다. */}
        </h3>
        <p className="mt16 f16">
          {getTranslatedNameById('TWVudUl0ZW06NjQw')} {/* 만 14세 이상입니다. */}
        </p>
      </div>
    </div>
    <div className="popup-footer">
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>
          {getTranslatedNameById('TWVudUl0ZW06NjM3')} {/* 완료 */}
        </button>
      </div>
    </div>
    </>
  );
};

export default PopupRegisterDetail1;
