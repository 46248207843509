import React, {useState, useEffect, useMemo} from "react";
import { useLocation } from "react-router-dom";
import usePopupStore from '../../store/common/usePopupStore'
import { fetchVouchers, fetchUserVouchers } from '../../services/GQ_apis/discounts';
import useAuthStore from '../../store/common/useAuthStore';
import { useLanguage } from '../../services/languageContext';

const CouponsUserSection = () => {

  const { user } = useAuthStore();
  const [pageIndex, setPageIndex] = useState(1);
  const [isCouponLoading, setIsCouponLoading] = useState(true);
  const { getTranslatedNameById, language } = useLanguage(); 

  const [couponChannel, setCouponChannel] = useState('ko');

  // 현재 위치에 따른 페이지 인덱스 설정
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/coupons/1')) {
      setPageIndex(1);
    } else if (location.pathname.includes('/coupons/2')) {
      setPageIndex(2);
    }
  }, [location]);

  const [couponList, setCouponList] = useState([]);

  useEffect(()=>{

    if(! user) return;

    fetchUserVouchers(user.email).then((res)=>{
      console.log('TGUserVouchers');
      console.log(res.data.TGUserVouchers);
      setCouponList(res.data.TGUserVouchers);
      setIsCouponLoading(false);
      console.log('TGUserVouchers');
    })

  },[user])

  const channelListing = (channelListings, channel) => {
    if (channel === 'ko') {
      return channelListings.find((item) => item.currency === 'KRW');
    } else if (channel === 'en') {
      return channelListings.find((item) => item.currency === 'USD');
    }
    return null; // 조건에 맞는 항목이 없을 경우 null 반환
  };

  if (isCouponLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-coupons">
        <div className="inner">
          <strong className="title">
            {getTranslatedNameById("TWVudUl0ZW06NjIx")} {/* 사용할 수 있는 쿠폰 */} 
            {language === 'KO' ? (<><b className="f-purple">{couponList.length}개</b></>
            ) : (
              <><b className="f-purple">{couponList.length}</b></>
            )}
          </strong>
            
          <ul className="coupons-list">
            {
              couponList.map((coupon, index)=>(
                <li className="card coupon" key={index}>
                  <div className="top-wrap">
                    <strong className="f-purple">
                      {
                        coupon.voucher.discountValueType === 'FIXED' ? 
                        <>
                          -{window.WSformatPrice(channelListing(coupon.channelListings, couponChannel).discountValue)}{window.WSCurrency(channelListing(coupon.channelListings, couponChannel).currency)}
                        </> 
                        : 
                        <>
                          {channelListing(coupon.channelListings, couponChannel).discountValue}%
                        </>
                      }
                    </strong>
                  </div>
                  <div className="bottom-wrap">
                    <strong className="title">
                      {coupon.voucher.name}
                    </strong>
                    <strong className="d-day">
                      { coupon.voucher.startDate && window.WSdate(coupon.voucher.startDate) } 
                      {(coupon.voucher.startDate || coupon.voucher.endDate) && '~'}  
                      { coupon.voucher.endDate && window.WSdate(coupon.voucher.endDate) }
                      {
                        coupon.voucher.minSpent &&
                        <span className="caption">
                          {language === 'KO' ? (
                              <>
                                {window.WSformatPrice(coupon.voucher.minSpent.amount)}
                                {window.WSCurrency(coupon.voucher.minSpent.currency)} 이상 구매시 사용가능
                              </>
                            ) : (
                              <>
                                Valid on purchases over {window.WSformatPrice(coupon.voucher.minSpent.amount)}
                                {window.WSCurrency(coupon.voucher.minSpent.currency)}
                              </>
                            )}
                        </span>
                      }
                    </strong>
                  </div>
                </li>
              ))
            }

          </ul>
        </div>
      </section>
    </>
  );
};

export default CouponsUserSection;

