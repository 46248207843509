import React, { useState } from "react";
import useInputStore from '../../store/common/useInputStore';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 사용

const KidRegister03Section = () => {

  const navigate = useNavigate();
  const { getTranslatedNameById, language } = useLanguage(); // 번역 함수와 언어 가져오기
  const {
    input,
    actions,
  } = useInputStore(state => ({
    input: state.input,
    actions: state.actions,
  }));

  // 번역된 색상 리스트
  const colorList = [
    { name: getTranslatedNameById("TWVudUl0ZW06Mzc4"), class: 'black' }, // 블랙
    { name: getTranslatedNameById("TWVudUl0ZW06Mzc5"), class: 'grey' },  // 그레이
    { name: getTranslatedNameById("TWVudUl0ZW06Mzgw"), class: 'white' }, // 화이트
    { name: getTranslatedNameById("TWVudUl0ZW06Mzgx"), class: 'navy' },  // 네이비
    { name: getTranslatedNameById("TWVudUl0ZW06Mzgy"), class: 'blue' },  // 블루
    { name: getTranslatedNameById("TWVudUl0ZW06Mzgz"), class: 'sky' },   // 스카이
    { name: getTranslatedNameById("TWVudUl0ZW06Mzg0"), class: 'pink' },  // 핑크
    { name: getTranslatedNameById("TWVudUl0ZW06Mzg1"), class: 'yellow' }, // 노랑
    { name: getTranslatedNameById("TWVudUl0ZW06Mzg2"), class: 'lime' },  // 연두
    { name: getTranslatedNameById("TWVudUl0ZW06Mzg3"), class: 'green' }, // 그린
    { name: getTranslatedNameById("TWVudUl0ZW06Mzg4"), class: 'brown' }, // 브라운
    { name: getTranslatedNameById("TWVudUl0ZW06Mzg5"), class: 'beige' }, // 베이지
    { name: getTranslatedNameById("TWVudUl0ZW06Mzkw"), class: 'purple' }, // 퍼플
    { name: getTranslatedNameById("TWVudUl0ZW06Mzkx"), class: 'orange' }, // 오렌지
    { name: getTranslatedNameById("TWVudUl0ZW06Mzky"), class: 'red' },   // 레드
  ];

  const [checkedStates, setCheckedStates] = useState(new Array(colorList.length).fill(false));
  const [selectedColors, setSelectedColors] = useState([]);

  const handleCheckboxChange = (position) => {
    if (selectedColors.length >= 3 && !checkedStates[position]) {
      return;
    }

    const updatedCheckedStates = checkedStates.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedStates(updatedCheckedStates);

    if (!checkedStates[position]) {
      setSelectedColors([...selectedColors, colorList[position]]);
    } else {
      setSelectedColors(selectedColors.filter((color) => color !== colorList[position]));
    }
  };

  const nextPage = () => {
    actions.setKidColorList(JSON.stringify(selectedColors));
    navigate('/register/kid-4');
  };

  return (
    <>
      <section className="section-register">
        <div className="inner">

          {/* 타이틀 영역 [START] */}
          <div className="h3-title-wrap mt0">
            <h3 className="title">
              {getTranslatedNameById("TWVudUl0ZW06Mzc2")} {/* "선호 색상" */}
            </h3>
            <p className="text">
              {language === 'KO' ? (
                <>
                  평소 선호하는 색상을<br/>
                  1순위부터 3순위까지 선택해주세요.
                </>
              ) : (
                <>
                  {getTranslatedNameById("TWVudUl0ZW06Mzc3")} {/* "평소 선호하는 색상을 1순위부터 3순위까지 선택해주세요." */}
                </>
              )}
            </p>
            <div className="pagination">
              <span className="item">1</span>
              <span className="item">2</span>
              <span className="item active">3</span>
              <span className="item">4</span>
            </div>
          </div>
          {/* 타이틀 영역 [END] */}

          {/* 아이템 체크 영역[START] */}
          <div className="check-wrap mt30">
            <ul className="check-list color">
              {colorList.map((item, index) => (
                <li key={index} className={item.class}>
                  {/* selectedColors의 길이가 3 이상이고, 
                      현재 항목이 선택되지 않았다면 disabled 클래스를 추가 */}
                  <label 
                    htmlFor={`check${index}`} 
                    className={`${checkedStates[index] ? 'active' : ''} 
                    ${selectedColors.length >= 3 && !checkedStates[index] ? 'disabled' : ''}`}
                    style={{ fontSize: language === 'EN' ? '15rem' : 'initial' }} // 영어일 때만 폰트 크기를 14rem으로
                  >
                    {item.name}
                    <input 
                      id={`check${index}`} 
                      type="checkbox"
                      checked={checkedStates[index]}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={selectedColors.length >= 3 && !checkedStates[index]}
                    />
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* 아이템 체크 영역[END] */}
          
          {/* 디버그 박스 [START] */}
          {/* <div className="debug-box mt20">
            <p><b>선호 색상 순위:</b> {selectedColors.map(color => color.name).join(', ')}</p>
          </div> */}
          {/* 디버그 박스 [END] */}
        </div>
      </section>

      {/* 다음 버튼 [START] */}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              disabled={!(selectedColors.length >= 3)}
              className="btn purple big"
              onClick={nextPage}
            >
              {getTranslatedNameById("TWVudUl0ZW06MzYz")} {/* "다음" */}
            </button>
            <a 
              href="#"
              className="skip-link"
              onClick={() => navigate('/')}
            >
              {getTranslatedNameById("TWVudUl0ZW06MzYy")} {/* "아이 등록 다음에 하기" */}
            </a>
          </div>
        </div>
      </div>
      {/* 다음 버튼 [END] */}
    </>
  );
};

export default KidRegister03Section;
