import React from 'react';
import { useLanguage } from '../../../../services/languageContext';

const PopupRegisterDetail2 = ({closePopup}) => {
  const { getTranslatedNameById, language } = useLanguage(); 
  
  return (
    <>
    <div className="popup-body">
      <div className="h3-title-wrap">
        <h3 className="title">
          {language === 'KO' ? (
            <>
              서비스 이용약관에 동의
            </>
          ) : (
            <>
              Agree to the Terms of Service
            </>
          )}
        </h3>
        <p className="mt16 f16">
          {language === 'KO' ? (
              <>
                서비스 이용약관에 동의
              </>
          ) : (
            <>
              Agree to the Terms of Service
            </>
          )}
        </p>
      </div>
    </div>
    <div className="popup-footer">
      <div className="btn-wrap">
        <button type="button" className="btn purple" onClick={closePopup}>
          {getTranslatedNameById('TWVudUl0ZW06NjM3')} {/* 완료 */}
        </button>
      </div>
    </div>
    </>
  );
};

export default PopupRegisterDetail2;
