import React, { useState, useEffect, useMemo } from "react";
import usePopupStore from '../../store/common/usePopupStore';
import useProductStore from '../../store/common/useProductStore';
import useAuthStore from '../../store/common/useAuthStore';
import { useNavigate, useParams, useLocation  } from 'react-router-dom'; 

import TopBar from '../../layouts/top_bar/TitleActionBar';
import Product01Section from "../../sections/product/Product01Section";
import Product02Section from "../../sections/product/Product02Section";
import Product03Section from "../../sections/product/Product03Section";
import Product04Section from "../../sections/product/Product04Section";
import { useLanguage } from '../../services/languageContext';

import { getProductDetail, addRecentlyViewedProduct } from '../../services/GQ_apis/product'; 
import { createProductLog } from '../../services/REST_apis/product'; 
import { toggleProductWishList, getWishlistItems } from '../../services/GQ_apis/product';

function ProductPage() {
  const navigate = useNavigate();  // useNavigate 훅 사용
  const location = useLocation();  // useLocation 훅 추가
  const { Page2depth } = useParams();
  const { getTranslatedNameById, language } = useLanguage();
  const [activeTab, setActiveTab] = useState(getTranslatedNameById('TWVudUl0ZW06NjE2'));
  const { user, globalObserver, observerUpdate } = useAuthStore();
  const userUUID = user?.id ? window.WSdecodeBase64Id(user.id) : null;  

  const tabs = [
    { name: getTranslatedNameById('TWVudUl0ZW06NjE2'), class: 'explain' },
    { name: getTranslatedNameById('TWVudUl0ZW06NjE3'), class: 'detail' },
    { name: getTranslatedNameById('TWVudUl0ZW06NDIw'), class: 'review' },
    { name: getTranslatedNameById('TWVudUl0ZW06NDI1'), class: 'inquiry'},
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    // navigate(`/product/${Page2depth}/${tab.route}`);
  };

  const { openToastPopup } = usePopupStore((state) => ({
    openToastPopup: state.openToastPopup,
  }));

  const { nowProductData, setNowProductData, fetchWishList  } = useProductStore((state) => ({
    nowProductData: state.nowProductData,
    setNowProductData: state.setNowProductData,
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [reviewList, setReviewList] = useState([]);
  
  const channel = useMemo(()=>{
    if(window.WSgetChannel() == 'EN') return 'en' 
    if(window.WSgetChannel() == 'KO') return 'ko' 
  },[window.WSgetChannel()])

  useEffect(() => {

    if (! activeTab && channel === 'ko') setActiveTab('상품설명');
    if (! activeTab && channel === 'en') setActiveTab('Description');

    getProductDetail(Page2depth, channel)
      .then((res) => {
        setIsLoading(false);
        setNowProductData(res.data.product);
        const product = res?.data?.product
        const productId = window?.WSdecodeBase64Id(product?.id);
        const vendorId = product?.vendor?.id;
        if(!product?.vendor) {
          alert('찾을 수 없는 상품 데이터입니다.');
          navigate('/');
          return
        }
        const userId = window?.WSdecodeBase64Id(user?.id);
        const referrer = window?.WSgetRefPath();
        createProductLog(productId, vendorId, 'view', userId, referrer);
      });

    addRecentlyViewedProduct(Page2depth);

    // URL 경로에 따른 activeTab 설정
    const currentTab = tabs.find(tab => `/product/${Page2depth}/${tab.route}` === location.pathname);
    if (currentTab) {
      setActiveTab(currentTab.name);
    }
  }, [Page2depth, location.pathname]);  

  useEffect(() => {
    // URL 경로 또는 상태에 따른 activeTab 설정
    const state = location.state;
    if (state?.activeTab === 'inquiry') {
      setActiveTab(getTranslatedNameById('TWVudUl0ZW06NDI1'));  // Inquiry 탭 활성화
    } else {
      setActiveTab(getTranslatedNameById('TWVudUl0ZW06NjE2'));  // 기본 탭 설정
    }
  }, [location.state, getTranslatedNameById]);



  if (isLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  const updateWishList = (productId) => {
    toggleProductWishList(productId).then((res)=>{
      setIsLoading(false);
      observerUpdate(); 
      setIsLoading(false);
      window.location.reload();
      console.log('globalObserver', globalObserver) 
    })
  }

  return (
    <div className="product-wrap">
      <TopBar title={getTranslatedNameById('TWVudUl0ZW06NjE4')}/> 
      <div className="slide-tabs purple center">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(tab)}
              className={`tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
            >
              {tab.name}
            </button>
          ))}
        </div>
      </div>

      <article id="productPage">
        {activeTab === getTranslatedNameById('TWVudUl0ZW06NjE2') && <Product01Section reviewList={reviewList} setActiveTab={setActiveTab} />}
        {activeTab === getTranslatedNameById('TWVudUl0ZW06NjE3') && <Product02Section />}
        {activeTab === getTranslatedNameById('TWVudUl0ZW06NDIw') && <Product03Section reviewList={reviewList} />}
        {activeTab === getTranslatedNameById('TWVudUl0ZW06NDI1') && <Product04Section />}
      </article>
      <div className="bottom-bar border">
        <div className="inner">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="heart-btn"
              onClick={()=>{updateWishList(nowProductData.id)}}
            >
              {getTranslatedNameById('TWVudUl0ZW06NjE5')} 
              <span className={`icon-heart ${ nowProductData.hasMyWish ? 'active' : ''}`}></span>
            </button>
             {/* 찜하기 */}
            {/* <button type="button" className="heart-btn">
              {getTranslatedNameById('TWVudUl0ZW06NjE5')}
              <span className="icon-heart white"></span>
            </button> */}
            <button 
              type="button" 
              className="btn purple big round"
              onClick={() => openToastPopup('구매하기')} // 구매하기 
            >
              {getTranslatedNameById('TWVudUl0ZW06NjIw')} {/* 구매하기 */}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
