import React, { useEffect, useState, useRef } from "react";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { getBannersById } from '../../services/REST_apis/banner';

const BannerESection = ({ id }) => {
  const [slides, setSlides] = useState([]);
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const bannerRef = useRef(null);

  const fetchBanners = async () => {
    if (id) {
      setLoading(true);
      try {
        const data = await getBannersById(id);
        const bannerData = window.WSremovePrefix(data)[id];
        
        const banners = bannerData.banner;
        setCategory(bannerData.category);

        const newSlides = banners.map(banner => ({
          img: banner.banner_img_url, 
          title: banner.description,
          src: banner.banner_ref_url
        }));
        setSlides(newSlides);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      if (entries[0].isIntersecting) {
        fetchBanners();
        observer.disconnect();
      }
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [id]);

  if (loading) {
    return <div className="loading-wrap">
      <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
    </div>;
  }

  return (
    <div ref={bannerRef} style={{ minHeight: '100rem' }}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        speed={900}
        loop={slides.length > 1 ? true : false}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{
          type: 'fraction',
          clickable: true
        }}
        className="banner banner-e"
        touchMoveStopPropagation={true} 
        nested={true}
      >
        {
          slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <a href={slide.src} title="" rel="noopener noreferrer">
                <img src={slide.img} alt={slide.title} />
              </a>
              {id}
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

export default BannerESection;
