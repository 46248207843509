import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toggleProductWishList, getWishlistItems } from '../../services/GQ_apis/product';
import useAuthStore from '../../store/common/useAuthStore';
import ThumbnailImage from '../../components/common/ThumbnailImage';
import blackImg from '../../assets/images/sample/black_img.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';

const SwipeProductSection = ({ products = [] }) => {
  const { globalObserver, observerUpdate } = useAuthStore();
  const [wishList, setWishList] = useState([]);

  // 위시리스트 불러오기
  useEffect(() => {
    if (localStorage.getItem('user') !== null) {
      getWishlistItems().then((res) => {
        setWishList(res.data.userWishlistItems);
      }).catch((e) => {
        console.log(e);
      });
    }
  }, [globalObserver]);

  const updateWishList = (productId) => {
    const encodedId = window.WSencodeBase64Id(productId)
    toggleProductWishList(encodedId).then(() => {
      observerUpdate();
    });
  };

  const isWishList = (productId) => {
    if (!wishList || wishList.length === 0) return false;
    console.log('pid:: ',productId)
    return wishList.some(item => item.product.id === productId);
  };

  // 상품없는 경우(임시처리)
  if (products === "No Product") {
    return (
      <div className="no-product-wrap">
        <style jsx>{`
          .no-product-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
  
          .no-product-message {
            font-size: 18px;
            color: #666;
          }
        `}</style>
        <p className="no-product-message">상품이 없습니다.</p>
      </div>
    );
  }
  

  if (products.length === 0) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      {/* 그리드(리스트) [START] */}
      <section className="section-grid">
        <Swiper
          // 스와이퍼 옵션
          modules={[Autoplay, FreeMode]}
          spaceBetween={8}
          slidesPerView="auto"
          loop={false }
          freeMode={true}
          className="card-tabs"
          nested={true} 
          touchMoveStopPropagation={true} 
        >
          {products.map((product, index) => (
            <SwiperSlide key={index} style={{ width: '110rem' }}>
              <div className="tab card small">
                <div className="info-wrap">
                  <div className="img-wrap">
                    <Link title="클릭시 해당 상품 페이지로 이동" to={`/product/${window.WSencodeBase64Id(product.id)}`}>
                      {product.thumbnail ? (
                        <ThumbnailImage thumbnailUrl={product.thumbnail} thumbnailAlt='aa' />
                      ) : (
                        <img src={blackImg} alt="이미지 없음" />
                      )}
                    </Link>
                    <button
                      type="button"
                      className={`icon-heart white ${isWishList(window.WSencodeBase64Id(product.id)) ? 'active' : ''}`}
                      onClick={() => updateWishList(product.id)}
                    />
                  </div>
                  {product.vendor && (
                    <Link title="클릭시 해당 브랜드 페이지로 이동" to={`/brand/detail/${product.vendor.id}`} className="brand">
                      {product.vendor.storeNameKo}
                    </Link>
                  )}
                  <Link title="클릭시 해당 상품 페이지로 이동" to={`/product/${window.WSencodeBase64Id(product.id)}`}>
                    <div className="title">{product.name}</div>
                    {product.pricing && (
                      <div className="price-wrap">
                        {product.pricing.priceRange.start.gross.amount !== product.pricing.priceRange.stop.gross.amount && (
                          <div className="before-price">
                            <span className="f-purple">쿠폰할인가</span>
                            <span className="price">{product.pricing.priceRange.stop.gross.amount}</span>
                          </div>
                        )}
                        <div className="now-price">
                          <span className="price">
                            {window.WSformatPrice(product.pricing.priceRange.start.gross.amount)}
                          </span>
                        </div>
                      </div>
                    )}
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      {/* 그리드(리스트) [END] */}
    </>
  );
};

export default SwipeProductSection;
