import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { myReviewData } from "../../services/GQ_apis/me";
import sampleImage from "../../assets/images/sample/banner.png";
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

const MyReviewsSection = () => {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [me, setMe] = useState({});
  const [loading, setLoading] = useState(true);

  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  useEffect(() => {
    myReviewData().then((res) => {
      setMe(res.data.me);
      const orderData = res.data.me.orders.edges;
      console.log("orderData", orderData);
      setOrderList(orderData);
      setLoading(false);
    });
  }, []);

  console.log( "meData", me);

  if (loading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  const handleReviewDetail = (reviewId, orderId) => {
    navigate(`/mymenu/myreview-detail/${reviewId}`, { state: { orderId } });
  };

  return (
    <>
      <section className="section-mymenu-orderlist">
        <div className="order-list-wrap">
          <ul className="order-list">
            {orderList.map((order) => {
              return order.node.lines
                .filter((line) => line.reviewId) 
                .map((line, index) => {
                  const orderId = order.node.id;
                  const reviewId = line.reviewId;

                  const thumbnailUrl = line.thumbnail?.url ? line.thumbnail.url : sampleImage;

                  return (
                    <li key={index} className="card order2 border-bottom-white pl16 pr16 pt20">
                      <div className="order-number-wrap flex between">
                        <span className="order-number">
                          {getTranslatedNameById("TWVudUl0ZW06NDEy")}  {/* "주문번호" */}
                          <span className="f-purple pl6">{order.node.id}</span>
                        </span>
                        <Link to={`/order/order-detail/${order.node.id}`} className="a-more-btn f14">
                          {getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* "주문 상세보기" -> "View Order Details" */}
                        </Link>
                      </div>
                      <div className="item-info-wrap flex">
                        <div className="img-wrap mr16">
                          <Link to={`/product/${line.variant.product.id}`}>
                            <img src={thumbnailUrl} alt={line.productName || "상품 이미지"} />
                          </Link>
                        </div>
                        <div className="text-wrap flex-grow">
                          <p className="name f14">
                            {line.productName} {line.productSku}
                          </p>
                          <div className="mt20">
                            <div className="btn-wrap flex justify-content-end">
                              <button
                                onClick={() => handleReviewDetail(reviewId, orderId)}
                                className="btn white round w100 h34 f14 mt10"
                              >
                                {getTranslatedNameById("TWVudUl0ZW06NDE0")} {/* "리뷰 확인하기" -> "View Review" */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                });
            })}
          </ul>
        </div>
      </section>
    </>
  );
};

export default MyReviewsSection;
