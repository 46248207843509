import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import SampleImage from "../../assets/images/common/icons/icon_profile.svg";
import { getMyChildren } from '../../services/GQ_apis/kid';
import { useLanguage } from '../../services/languageContext';

const MainLoginSection = () => {
  
  const navigate = useNavigate();
  const [kids, setKid] = useState([]);
  const [mainKid, setMainKid] = useState({})
  const [kidLoading, setKidLoading] = useState(true);
  const { getTranslatedNameById, language } = useLanguage();

  useEffect(()=>{

    setKidLoading(true);

    getMyChildren().then((res)=>{
      setKidLoading(false);
      const children = res.data.myChildren;
      setKid(children);
      
      const mainKid = children.find(kid => kid.main === true);
      if (mainKid) {
        setMainKid(mainKid);
      }
    })
  },[])

  const handleProfileClick = () => {
    if (mainKid && mainKid.id) {
      navigate(`mymenu/mykid-modify/${mainKid.id}`);
    }
  };

  if (kids && kids.length === 0) {
    return (
      <>
        {
          kidLoading &&
          <section className="section-login">
            <div className="inner">
              <div className="loading-wrap">
                <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
              </div>
            </div>
          </section>
        }
        {
          ! kidLoading &&
          <section className="section-login">
            <div className="inner">
              <div className="text-box mb10">
                {getTranslatedNameById("TWVudUl0ZW06Mjgy")}<br/>
                {getTranslatedNameById("TWVudUl0ZW06Mjgz")}
              </div>
              <button 
                type="button" 
                className="btn big white"
                onClick={()=>navigate('/register/kid-1')}
                >
                {getTranslatedNameById("TWVudUl0ZW06Mjg0")}
              </button>
            </div>
          </section>
        }
      </>

    );   
  }

  return (
    <section className="section-login">
      <div className="inner">
        <div className="profile-wrap">
          <div className="img-wrap">
            <img className="circle-img" src={mainKid.thumbnail || SampleImage} alt="하늘이 이미지" />
          </div>
          <div className="text-wrap">
            {/* 언어에 따른 조건부 텍스트 */}
            {language === 'KO' ? (
              <>
                <b>{mainKid.name}</b>를 위한<br /> 상품을 준비했어요.
              </>
            ) : (
              <>
                {getTranslatedNameById("TWVudUl0ZW06MjY2")} <b>{mainKid.name}</b>
              </>
            )}
          </div>
          <button 
            type="button" 
            className="icon-setting ml20" 
            onClick={handleProfileClick}
          >
          </button>
        </div>
        {/* <div className="banner-coupon">
          <div className="img-wrap">
          </div>
          <div className="text-wrap">
            <span className="name">하늘이</span> 초등입학을 축하!<br/>
            입학선물 <b className="f-purple">20% 할인 쿠폰</b> 도착
          </div>
          <button type="button" className="btn purple" onClick={()=>navigate('/coupons/2')}>
            쿠폰받기
          </button>
        </div> */}
        <div className="size-wrap">
          <div className="title-wrap">
            <div className="title">
              <span className="name">
                {mainKid.name}
              </span> {getTranslatedNameById("TWVudUl0ZW06MjY3")}
            </div>
            <button type="button" className="more" onClick={()=>navigate(`/mymenu/mykid-modify/${mainKid.id}`)}>
              {getTranslatedNameById("TWVudUl0ZW06MjY4")}
            </button>
          </div>
          <div className="white-box">
            <div className="item">
              <p className="key">
                {getTranslatedNameById("TWVudUl0ZW06MjY5")}
              </p>
              <p className="value">
                <span className="number">
                  {mainKid.height}
                </span>cm
              </p>
            </div>
            <div className="item">
              <p className="key">
                {getTranslatedNameById("TWVudUl0ZW06Mjcw")}
              </p>
              <p className="value">
              <span className="number">
                {mainKid.weight}
              </span>kg
              </p>
            </div>
            <div className="item">
              <p className="key">
                {getTranslatedNameById("TWVudUl0ZW06Mjcx")}
              </p>
              <p className="value">
                <span className="number">
                  {mainKid.headSize}
                </span>cm
              </p>
            </div>
            <div className="item">
              <p className="key">
                {getTranslatedNameById("TWVudUl0ZW06Mjcy")}
              </p>
              <p className="value">
                <span className="number">
                  {mainKid.footSize}
                </span>mm
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainLoginSection;
