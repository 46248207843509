import React, { createContext, useState, useContext, useEffect } from 'react';
import { getMenuTranslation } from '../services/GQ_apis/translation';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(window.WSgetChannel() || 'KO');
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const fetchMenuTranslation = async () => {
            try {
                const res = await getMenuTranslation('번역', language);
                setMenuItems(res.data.menu.items);
            } catch (error) {
                console.error('Error fetching menu translation:', error);
            }
        };

        fetchMenuTranslation();
    }, [language]);

    const toggleLanguage = async (lang) => {
        setLanguage(lang);
        window.WSsetChannel(lang);

        try {
            const res = await getMenuTranslation('번역', lang);
            setMenuItems(res.data.menu.items);
        } catch (error) {
            console.error('Error fetching menu translation:', error);
        }
    };

    const getTranslatedNameById = (id) => {
        const item = menuItems.find(menuItem => menuItem.id === id);
        return item?.translation?.name || item?.name || ''; 
    };

    // 전역(window)에 등록
    window.getTranslatedNameById = getTranslatedNameById;

    return (
        <LanguageContext.Provider
            value={{
                language,
                toggleLanguage,
                getTranslatedNameById
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};

// 커스텀 훅 생성
export const useLanguage = () => useContext(LanguageContext);
