import { gql } from '@apollo/client';
import { client, nonTokenClient } from '../apolloClient';

const GET_COLLECTION = gql`
  query GetCollections($slugs: [String!] = "", $channel : String!) {
    collections(
      filter: {slugs: $slugs}, 
      first: 50,
      channel: $channel
    ) {
      edges {
        node {
          id
          name
          description
          slug,
          metadata {
            key
            value
          }
          privateMetadata {
            key
            value
          }
        }
      }
    }
  }
`;

export function getCollection(slugs) {
  const channel = window.WSgetChannel()
  let channel_slugs = [];
  if(channel == 'EN') {
    slugs.forEach((slug) => {
      channel_slugs.push('global_'+slug);
    })
  } else {
    channel_slugs = slugs
  }

  return nonTokenClient.query({
    query: GET_COLLECTION,
    variables: {
      slugs: channel_slugs,
      channel: channel.toLowerCase()
    },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}


export function getCollectionByProducts({
  slugs,
  channel='ko',
  after = null,
  first = 50,
  categories = [],
  metaFilters = [],
  sortField = 'COLLECTION',
  sortDirection = 'ASC'
}) {

  const GET_COLLECTION_PRODUCTS = gql`
  query GetCollectionsByProducts(
    $slugs: [String!] = "",
    $channel: String!,
    $after: String,
    $first: Int!,
    $categories: [ID!],
    $metaFilters: [MetadataFilter!],
    $sortField: ProductOrderField!,
    $sortDirection: OrderDirection!
  ) {
    collections(
      filter: {slugs: $slugs},
      first: 50,
      channel: $channel
    ) {
      edges {
        node {
          id
          name
          description
          slug
          metadata {
            key
            value
          }
          privateMetadata {
            key
            value
          }
          products(
            after: $after,
            first: $first,
            sortBy: {
              field: $sortField,
              direction: $sortDirection
            },
            filter: {
              categories: $categories,
              metadata: $metaFilters,
              channel: $channel,
              isPublished:true,
              stocks: {quantity: {gte: 1}},
            }
          ) {
            pageInfo {
              hasNextPage
            }
            totalCount
            edges {
              cursor
              node {
                id
                name
                channel
                firstMediaUrlByColor
                created
                hasMyReview
                hasMyWish
                translation(languageCode: EN) {
                  id
                  name
                  translatableContent {
                    attributeValues {
                      name
                    }
                  }
                }

                category {
                  slug
                }
                collections {
                  slug
                }
                metadata {
                  key
                  value
                }
                vendor {
                  id
                  storeNameKo
                  storeNameEn
                }
                thumbnail {
                  alt
                  url
                }
                pricing {
                  onSale
                  priceRangeUndiscounted {
                    start {
                      gross {
                        amount
                        currency
                      }
                    }
                    stop {
                      gross {
                        amount
                        currency
                      }
                    }
                  }
                  discount {
                    currency
                    gross {
                      amount
                      currency
                    }
                  }
                  priceRange {
                    start {
                      gross {
                        amount
                        currency
                      }
                    }
                    stop {
                      gross {
                        amount
                        currency
                      }
                    }
                  }
                }
                attributes {
                  attribute {
                    name
                  }
                  values {
                    name
                  }
                }
                color: attribute(slug: "product-color") {
                  attribute {
                    id
                    name
                  }
                  values {
                    id
                    name
                  }
                }
                variants {
                  name
                  attributes {
                    attribute {
                      name
                      id
                    }
                    values {
                      id
                      name
                    }
                  }
                  media {
                    url
                    id
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

  const loggedInUser = localStorage.getItem('user');
  const tokenClient = loggedInUser ? client : nonTokenClient;

  return tokenClient.query({
    query: GET_COLLECTION_PRODUCTS,
    variables: {
      slugs,
      channel,
      after,
      first,
      categories,
      metaFilters,
      sortField,
      sortDirection
    },
    fetchPolicy: 'network-only'
  });
}


