import React from "react";
import { useParams } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';
import PayShoppingSection from '../../sections/pay/PayShoppingSection'; 
import PayProcessSection from '../../sections/pay/PayProcessSection'; 
import PayResultSection from '../../sections/pay/PayResultSection'; 
import { useLanguage, language } from '../../services/languageContext'; 

function PayPage() {

  const { Page2depth } = useParams();
  const { getTranslatedNameById, language } = useLanguage(); 
  
  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      case 'shopping' :
        return language === 'KO' ? '장바구니' : 'Shopping Cart'
      default:
        return language === 'KO' ? '결제' : 'Payment'
    }
  };

  return (
    <>
        <TopBar  
          title={getTitle(Page2depth)}
          url='/'
        />

      <article id="payPage">
        {Page2depth === 'shopping' && <PayShoppingSection/>}
        {Page2depth === 'process' && <PayProcessSection/>}
        {Page2depth === 'result' && <PayResultSection/>}
      </article>
    </>
  )
}

export default PayPage;
