import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage, language } from '../../services/languageContext';
import { getMyChildren } from '../../services/GQ_apis/kid';

const FAB = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false); // 활성화 상태를 저장할 state
  const { getTranslatedNameById, language } = useLanguage();
  const [kids, setKid] = useState([]);
  const [mainKid, setMainKid] = useState(null);

  const toggleActive = () => {
    setIsActive(!isActive); // 기존 상태를 반전
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      getMyChildren().then((res) => {
        const children = res.data.myChildren;
        setKid(children);

        const mainKid = children.find(kid => kid.main === true);
        if (mainKid) {
          setMainKid(mainKid);
        }
      })
    }
  }, [])

  const handleProfileClick = () => {
    if (mainKid && mainKid.id) {
      navigate(`mymenu/mykid-detail/${mainKid.id}`);
    } else {
      navigate(`/register/kid-1`);
    }
  };

  return (
    <div className={`fab ${isActive ? 'active' : ''}`}>
      <button
        type="button"
        className="action profile"
        onClick={handleProfileClick}
      // disabled={!mainKid || !mainKid.id} 
      >
        {getTranslatedNameById('TWVudUl0ZW06NTU3')} {/* 내 아이 */}
      </button>
      {/* <button 
        type="button" 
        className="action zaelook"
        onClick={() => navigate('/zaelook/:Page2depth')}
      >
        재룩
      </button> */}
      <button
        type="button"
        className="action plus"
        onClick={toggleActive} // 클릭 이벤트 핸들러로 toggleActive 함수 지정
      >
      </button>
    </div>
  );
};

export default FAB;
