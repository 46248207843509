import React, { useEffect } from "react";
import GridSection from '../../sections/grid/GridSection';

const MyMenuRecentProductSection = () => {

  return (
    <>
      <section className="section-mymenu-recent-product">
      <div className="inner">
        {/* MIDDLE 그리드(리스트) [START] */}
        <GridSection type="middle" option={true} isRecentlyViewed={true} infinity={true}/>
        {/* <RecentlyViewGridSection type="middle" option={false} isRecentlyViewed={true} infinity={true}/> */}
        {/* MIDDLE 그리드(리스트) [END] */}
      </div>
      </section>
    </>
  );
};

export default MyMenuRecentProductSection;

