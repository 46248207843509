import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { fetchOrderById } from '../../services/GQ_apis/order';
import { useLanguage } from '../../services/languageContext';

const MyMenuExchangeDetailSection = () => {

  const { Page3depth } = useParams();
  const [orderData, setOrderData] = useState({});
  const { getTranslatedNameById, language } = useLanguage();

  useEffect(() => {
    fetchOrderById(Page3depth).then((res) => {
      // 주문 데이터를 설정
      setOrderData(res.data.order);
      console.log('res.data.order', res.data.order);
    });
  }, [Page3depth]);

  if (!orderData || !orderData.lines) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-mymenu-exchange-detail">
        <div className="order-card selected">
          <div className="title-wrap">
            <strong className="title">{window.WSgetStatus(orderData.status)}</strong>
            <span className="caption">{getTranslatedNameById("TWVudUl0ZW06NDEy")} {/* "주문번호" */}  {orderData.id}</span> 
          </div>
          {
          orderData.lines.map((line, index) => (
            <div key={index} className="item-info-wrap">
              <div className="img-wrap">
                <Link to={`/product/${line.variant.product.id}`}>
                  <img src={line.thumbnail.url} alt={line.thumbnail.alt}></img>
                </Link>
              </div>
              <div className="text-wrap">
                <p className="brand">{line?.variant?.product?.vendor?.storeNameKo}</p>
                <p className="name">{line.productName}</p>
                  {language === 'KO' ? (
                    <>
                      <p className="price">{window.WSformatPrice(line.totalPrice.gross.amount)}{window.WSCurrency(line.totalPrice.gross.currency)}/ {line.quantity}{getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}</p>
                    </>
                  ) : (
                    <>
                      <p className="price">{window.WSformatPrice(line.totalPrice.gross.amount)} / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}</p>
                    </>
                  )}
                </div>
            </div>
          ))
          }
        </div>
        <div className="white-wrap exchange-wrap">
          <h4 className="title">
            {getTranslatedNameById("TWVudUl0ZW06NDQ4")} {/* "주문정보" */}
          </h4>
          <div className="text-wrap">
            <b>
              {orderData.shippingAddress.firstName}
            </b>
            <span>({orderData.shippingAddress.postalCode}) {orderData.shippingAddress.streetAddress1}</span>
            <span>{orderData.shippingAddress.streetAddress2}</span>
            <span className="f-black60">{window.WSformatPhoneNumber(orderData.shippingAddress.phone)}</span>
          </div>
          <div className="text-wrap mt10">
            <b>배송비</b> 
            <span>{ orderData.shippingPrice.gross.amount }{ window.WSCurrency(orderData.shippingPrice.gross.currency) }</span>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyMenuExchangeDetailSection;
