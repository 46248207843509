import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const BigTab = ({ tabs }) => {
  const navigate = useNavigate();

  const handleClick = (tabTitle) => {
    navigate(`/search/result?query=${encodeURIComponent(tabTitle)}`, { state: { fromCategory: true } });
  };

  return (
    <div className="big-tabs">
      {tabs.map((tab, index) => (
        <div
          className="big-tab"
          key={index}
          onClick={() => handleClick(tab.title)}
          style={{ cursor: 'pointer' }}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};

BigTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BigTab;
