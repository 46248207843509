import React, { useState, useRef, useEffect } from "react";
import useProductStore from '../../store/common/useProductStore';
import { meData } from "../../services/GQ_apis/me";
import { createPage, updateMetadata, createPageMedia, updateProduct, fetchPages, fetchProductRating } from "../../services/GQ_apis/page";
import { fetchOrderById } from '../../services/GQ_apis/order';
import { useNavigate, useLocation } from 'react-router-dom'; 
import ImageSwipeGridSection from '../../sections/grid/ImageSwipeGridSection';
import usePopupStore from '../../store/common/usePopupStore';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

const MyReviewWriteSection = () => {
  const [rating, setRating] = useState(0); // 별점 상태
  const [content, setContent] = useState(""); // 리뷰 내용 상태
  const [photoFiles, setPhotoFiles] = useState([]); // 파일 업로드를 위한 파일 객체 상태
  const [photoURLs, setPhotoURLs] = useState([]); // 이미지 미리보기 URL 상태
  const [productId, setProductId] = useState(""); // Product ID 상태
  const [vendorId, setVendorId] = useState(""); // Vendor ID 상태
  const [orderData, setOrderData] = useState(null); // Order Data 상태
  const fileInputRef = useRef(null); // 파일 입력 요소를 위한 ref
  const { nowProductData } = useProductStore((state) => ({ nowProductData: state.nowProductData }));
  const [me, setMe] = useState({});
  const location = useLocation();
  const { orderId, productName  } = location.state; 
  const [reviewStatus, setReviewStatus] = useState(""); // 리뷰 완료 상태
  const [lineIndex, setLineIndex] = useState(null); 
  const [totalRating, setTotalRating] = useState([]);
  const navigate = useNavigate();
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const { openToastPopup, openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
    openToastPopup: state.openToastPopup,
  }));

  // 별점을 처리하는 함수
  const handleRating = (newRating) => {
    setRating(newRating);
  };

  // 리뷰 내용을 처리하는 함수
  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    
    const readFiles = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ file, url: e.target.result }); // 파일 객체와 URL을 함께 반환
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });
  
    // 모든 파일 읽기가 완료되면 상태 업데이트
    Promise.all(readFiles).then((results) => {
      const newPhotoFiles = results.map(result => result.file);
      const newPhotoURLs = results.map(result => result.url);
  
      // 상태를 한 번에 업데이트
      setPhotoURLs(prev => [...prev, ...newPhotoURLs]);
      setPhotoFiles(prev => [...prev, ...newPhotoFiles]);
    }).catch((error) => {
      console.error("Error reading files:", error);
    });
  };

  // "사진 첨부하기" 버튼 클릭을 처리하는 함수
  const handleAttachPhotoClick = () => {
    fileInputRef.current.click(); // 숨겨진 파일 입력 요소를 프로그래밍 방식으로 클릭
  };

  // 사진 첨부를 제거하는 함수
  const removePhoto = (index) => {
    setPhotoFiles(photoFiles.filter((_, i) => i !== index));
    setPhotoURLs(photoURLs.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderById(orderId).then((res) => {
        const order = res.data.order;

        // 전달받은 productName과 일치하는 lineIndex 찾기
        const foundLineIndex = order.lines.findIndex(
          (line) => line.productName === productName
        );

        if (foundLineIndex !== -1) {
          setLineIndex(foundLineIndex); // 일치하는 인덱스를 저장
        }

        setOrderData(order); // 주문 데이터 저장
      }).catch((error) => {
        console.error('Error fetching order:', error);
      });
    }
  }, [orderId, productName]);

  useEffect(() => {
    if (orderId && lineIndex !== null && lineIndex !== undefined) {
      fetchOrderById(orderId)
        .then((res) => {
          console.log('Order data:', res.data.order);
  
          // lineIndex와 variant의 존재를 확인
          const orderLine = res.data.order.lines[lineIndex];
          if (orderLine && orderLine.variant) {
            const productId = orderLine.variant.product.id;
            const vendorId = orderLine.variant.product.vendor.id;
            setProductId(productId);
            setVendorId(vendorId);
            setOrderData(res.data.order); // 주문 데이터 저장
            console.log(productId, vendorId);
          } else {
            console.error("Variant or order line not found");
          }
        })
        .catch((error) => {
          console.error("Error fetching order:", error);
        });

        meData()
        .then((res) => {
          setMe(res.data.me);
          console.log('meData:', res.data.me);  // meData가 올바르게 가져오는지 확인
        })
        .catch((error) => {
          console.error("Error fetching meData:", error);
        });
    
        console.log('nowProductData', nowProductData);
        console.log('nowProductData name', nowProductData.name);
    }
  }, [orderId, lineIndex]);

  // product의 rating값을 가져오기
  useEffect(() => {
    console.log('productId:', productId);  // productId가 정상적인지 확인
    if (productId) {
      fetchProductRating(productId)
        .then((res) => {
          console.log('res', res);
          console.log('res.rating', res.data.product.rating);
          setTotalRating(res.data.product.rating);
        })
        .catch(error => {
          console.error("Error fetching product rating:", error);
        });
    } else {
      console.error("Error: productId is missing.");
    }
  }, [productId]);

  const pageTypeId = 'UGFnZVR5cGU6MTI='; // 리뷰
  const publicationDate = new Date().toISOString().split('T')[0];

  const saveReview = () => {
    if (!productId) {
      console.error('Product ID is not available.');
      return;
    }
  
    const orderLine = me.orders.edges
      ?.flatMap((edge) => edge.node.lines)
      ?.find((line) => line.variant?.product.id === productId);
  
    const size = orderLine?.variant?.name || '';
    const color = orderLine?.variant?.attributes[0]?.values[0]?.name || '';
  
    createPage(content, pageTypeId, publicationDate)
      .then((res) => {
        const pageId = res.data.pageCreate.page.id;
        const metadata = [
          { key: "product_id", value: productId },
          { key: "publication_date", value: publicationDate },
          { key: "rating", value: rating },
          { key: "ZZ_VID", value: vendorId },
          { key: "ZZ_UID", value: me.id },
          { key: "order_id", value: orderId },
          { key: "page_id", value: pageId },
          { key: "review_status", value: reviewStatus },
          { key: "color", value: color },
          { key: "size", value: size },
        ];
  
        if (photoFiles.length > 0) {
          const mediaUploadPromises = photoFiles.map((file) =>
            createPageMedia(productId, pageId, file)
          );
  
          return Promise.all(mediaUploadPromises).then(() => {
            return updateMetadata(pageId, metadata);
          });
        } else {
          return updateMetadata(pageId, metadata);
        }
      })
      .then(() => {
        // updateMetadata 완료 후 productUpdate 호출
        const newTotalRating = totalRating + rating; // 새로운 리뷰 평점 추가

        const input = {
          rating: newTotalRating, // 계산된 평균 평점을 업데이트
          id: productId,
        };
  
        return updateProduct(input);
      })
      .then((finalRes) => {
        openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTM2"), '', '');
        console.log('리뷰 및 제품 업데이트 완료:', finalRes);
        navigate(-1); 
      })
      .catch((error) => {
        console.error("Error creating page:", error);
        if (error.message.includes("PermissionDenied")) {
          alert("You do not have sufficient permissions to create a page.");
        } else {
          alert("An error occurred while creating the page.");
        }
      });
  };
  
  
  return (
    <>
      <section className="section-mymenu-review-write">
        <div className="white-wrap">
          <div className="card order">
            <div className="img-wrap w100 h100">
              {/* 주문 데이터에서 첫 번째 상품의 썸네일 이미지 사용 */}
              {orderData && orderData.lines && lineIndex !== null && orderData.lines[lineIndex]?.thumbnail?.url && (
                <img src={orderData.lines[lineIndex].thumbnail.url} alt={orderData.lines[lineIndex].thumbnail.alt} />
              )}
            </div>
            <div className="text-wrap">
              {/* 주문 데이터에 연동된 정보 표시 */}
              {orderData && orderData.lines && lineIndex !== null && (
                <>
                  <p className="date">
                    {new Date(orderData?.created)
                      .toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                      .replace(/\.$/, "")}
                  </p>
                  <p className="brand">
                    {orderData?.lines[lineIndex]?.variant?.product?.vendor?.storeNameKo} 
                    {/* {orderData?.lines[lineIndex]?.variant?.product?.attributes[0]?.values[0]?.name}  */}
                  </p>
                  <p className="name">
                    {orderData?.lines[lineIndex]?.productName}
                  </p>
                  <p className="price">
                    {window.WSformatPrice(orderData?.lines[lineIndex]?.variant?.product?.pricing?.priceRange?.start?.gross?.amount)} / {orderData?.lines[lineIndex]?.quantity}개
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="stars-wrap">
            <h4 className="title">{getTranslatedNameById("TWVudUl0ZW06NTM3")}</h4> {/* "상품 별점" */}
            <div className="stars big gap4">
              {[...Array(5)].map((star, index) => {
                const ratingValue = index + 1;
                return (
                  <button
                    key={ratingValue}
                    className={`icon-star-${ratingValue <= rating ? 'fill' : 'empty'} big`}
                    onClick={() => handleRating(ratingValue)}
                    style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                    title={`${ratingValue}점`}
                  ></button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="white-wrap">
          <div className="input-box textarea">
            <label htmlFor="textarea" className="title">
              {getTranslatedNameById("TWVudUl0ZW06NDMz")} {/* "Content" */}
            </label>
            <textarea 
              id="textarea" 
              placeholder={getTranslatedNameById("TWVudUl0ZW06NDM0")}  
              value={content}
              onChange={handleContentChange}
            ></textarea>
          </div>
          <input 
            type="file" 
            accept="image/*"
            multiple 
            onChange={handleFileChange} 
            ref={fileInputRef} 
            style={{ display: 'none' }} // 파일 입력 요소를 숨깁니다
          />
          <button 
            type="button" 
            className="btn white2 big mt16" 
            onClick={handleAttachPhotoClick} // 버튼 클릭 시 파일 입력을 활성화합니다
          >
            <span className="icon-camera black"></span>
            {getTranslatedNameById("TWVudUl0ZW06NDM1")} {/* "Attach a photo" */}
          </button>
          <div className="pt16"> 
            <ImageSwipeGridSection photos={photoURLs} onRemovePhoto={removePhoto}/>
          </div>
          {/* 개발 디버그용 */}
          {/* <div className="debug-box mt16">
            <p><b>별점 :</b> {rating}</p>
            <p><b>내용 :</b> {content}</p>
            <b>첨부 사진 :</b>
            <ul>
              {photoURLs.map((photo, index) => (
                <li key={index}>{photo}</li>
              ))}
            </ul>
          </div> */}
        </div>
      </section>
      {/* 저장하기 버튼 */}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big" 
              disabled={rating === 0 || content === ""}
              onClick={saveReview}
            >
            {getTranslatedNameById("TWVudUl0ZW06NDM2")} {/* "Submit" */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyReviewWriteSection;
