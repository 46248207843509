import React, { useState, useEffect } from "react";
import sampleImage from "../../assets/images/sample/sample_nike2.svg";
import GridSection from "../../sections/grid/GridSection";
import BannerGSection from '../../sections/banner/BannerGSection';

const BrandDetailSection = ({ store = {} }) => {
  const [isLoading, setIsLoading] = useState(true);

  // 제품 데이터가 로딩될 때까지 로딩 상태를 관리
  useEffect(() => {
    if (Object.keys(store).length > 0) {
      setIsLoading(false); // store 데이터가 있을 때 로딩 상태 해제
    }
  }, [store]);

  if (isLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }


  return (
    <>
      <section className="section-brand-detail">
        <div className="brand-hero-banner">
          <img src={store?.detail_info.vendor_thumbnail || sampleImage} alt=""></img>
        </div>

        {/* BIG 리스트 [START] */}
        <GridSection 
          type="big" 
          option={true} 
          vid={store?.id} 
          infinity={true} 
        />
        {/* BIG 리스트 [END] */}
      </section>
    </>
  );
};


export default BrandDetailSection;
