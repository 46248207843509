import { gql } from '@apollo/client';
import { client, nonTokenClient } from '../apolloClient';

export function getProductList(
  afterCursor, // 다음 페이지 ID
  categories, // 카테고리 ID 배열
  channel,
  collections, // 콜렉션 ID
  meta, // 메타 데이터 키 배열
  produt_first,
) {
  let metaFilters = [];
  if (meta && meta.length > 0) {
    metaFilters = meta.map(key => ({
      key: key
    }));
  }

  const GET_PRODUCTS = gql`
    query GetProducts(
      $channel: String!,
      $after: String,
      $first: Int!,
      ${collections ? '$collections: [ID!],' : ''} 
      ${categories && categories.length > 0 ? '$categories: [ID!],' : ''} 
      ${metaFilters.length > 0 ? '$metaFilters: [MetadataFilter!],' : ''}
    ) {
      products(
        channel: $channel, 
        after: $after, 
        first: $first,
        filter: {
          ${collections ? `collections: $collections,` : ''}
          ${categories && categories.length > 0 ? `categories: $categories,` : ''}
          ${metaFilters.length > 0 ? `metadata: $metaFilters,` : ''}
        },
      ) {
        pageInfo {
          hasNextPage
        }
        totalCount
        edges {
          cursor
          node {
            id
            name
            firstMediaUrlByColor
            hasMyReview
            hasMyWish
            created
            vendor {
              id
              storeNameKo
            }
            category {
              id
              slug
              name
            }
            collections {
              slug
            }
            metadata {
              key
            }
            thumbnail {
              alt
              url
            }
            pricing {
              onSale
              priceRangeUndiscounted {
                start {
                  gross {
                    amount
                    currency
                  }
                }
                stop {
                  gross {
                    amount
                    currency
                  }
                }
              }
              discount {
                currency
                gross {
                  amount
                  currency
                }
              }
              priceRange {
                start {
                  gross {
                    amount
                    currency
                  }
                }
                stop {
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
            attributes {
              attribute {
                name
              }
              values {
                name
                
              }
            }
            color: attribute(slug: "product-color") {
              attribute {
                id
                name
              }
              values {
                id
                name
              }
            }
          }
        }
      }
    }
  `;

  return nonTokenClient.query({
    query: GET_PRODUCTS,
    variables: {
      channel: channel,
      after: afterCursor,
      first: produt_first ? produt_first : 4,
      ...(collections && { collections: collections }),
      ...(categories && categories.length > 0 && { categories: categories }),
      ...(metaFilters.length > 0 && { metaFilters: metaFilters }),
    },
    fetchPolicy: 'network-only'
  });
}

export function getProductDetail(productId, channel) {
  const GET_PRODUCT_DETAIL = gql`
    query GetProductDetail($id: ID!, $channel: String!) {
      product(
        id: $id,
        channel: $channel
      ) {
        id
        channel
        name
        firstMediaUrlByColor
        descriptionJson
        hasMyWish
        created
        rating
        vendor {
          id
          storeNameKo
          storeNameEn
          matchedShippingMethodsKo
          matchedShippingMethodsEn
        }
        translation(languageCode: EN) {
          id
          name
          translatableContent {
            attributeValues {
              name
            }
          }
          descriptionJson
        }
        variants {
          id
          name
          product {
            name
          }
          media {
            url
            id
            alt
          }
          attributes {
            values {
              id
              name
            }
          }
          pricing {
            price {
              gross {
                amount
              }
            }
          }
        }
        pricing {
          onSale
          priceRangeUndiscounted {
            start {
              gross {
                amount
                currency
              }
            }
            stop {
              gross {
                amount
                currency
              }
            }
          }
          discount {
            currency
            gross {
              amount
              currency
            }
          }
          priceRange {
            start {
              gross {
                amount
                currency
              }
            }
            stop {
              gross {
                amount
                currency
              }
            }
          }
        }
        images {
          alt
          id
          url
        }
        media {
          id
          alt
          metadata {
            key
            value
          }
          url
          sortOrder
        }

        attributes {
          attribute {
            id
            name
          }
          values {
            id
            name
          }
        }

        color: attribute(slug: "product-color") {
          attribute {
            id
            name
          }
          values {
            id
            name
          }
        }

      }
    }
  `;
  const loggedInUser = localStorage.getItem('user');
  const tokenClient = loggedInUser ? client : nonTokenClient; // 로그인 여부에 따라 클라이언트 설정

  return tokenClient.query({
    query: GET_PRODUCT_DETAIL,
    variables: {
      id: productId,
      channel: channel,
    },
    fetchPolicy: 'network-only'
  });
}

export function getProductByVendor(
  afterCursor, // 다음 페이지 ID
  categories, // 카테고리 ID 배열
  collections, // 콜렉션 ID
  channel,
  meta, // 메타 데이터 키 배열
  vid = '',
  sortField = 'CREATED_AT', // 기본적으로 등록일 기준 정렬
  sortDirection = 'DESC', // 기본 정렬 방향은 최신순(내림차순)
  search,
  isRecentlyViewed,
  isMyWishProduct
) {

  let metaFilters = [];

  if (meta && meta.length > 0) {
    metaFilters = meta.map(key => ({
      key,
    }));
  }

  if (vid) {
    metaFilters.push({
      key: 'ZZ_VID',
      value: vid,
    });
  }

  const GET_PRODUCTS_VENDOR = gql`
    query GetProducts(
      $channel: String!,
      $after: String,
      $first: Int!,
      ${collections ? '$collections: [ID!],' : ''} 
      ${categories && categories.length > 0 ? '$categories: [ID!],' : ''} 
      ${metaFilters.length > 0 ? '$metaFilters: [MetadataFilter!],' : ''},
      ${search ? '$search: String,' : ''}  
      $sortField: ProductOrderField!, 
      $sortDirection: OrderDirection!,
      $isMyWishProduct: Boolean,
      $isRecentlyViewed: Boolean
    ) {
      products(
        channel: $channel, 
        after: $after, 
        first: $first,
        isMyWishProduct: $isMyWishProduct,
        isRecentlyViewed: $isRecentlyViewed,
        sortBy: {
          field: $sortField, 
          direction: $sortDirection
        },
        filterActiveVendor: true,
        filter: {
          isPublished:true,
          stocks: {quantity: {gte: 1}},
          ${collections ? `collections: $collections,` : ''}
          ${categories && categories.length > 0 ? `categories: $categories,` : ''}
          ${metaFilters.length > 0 ? `metadata: $metaFilters,` : ''}
          ${search ? `search: $search,` : ''},
        }
      ) {
        pageInfo {
          hasNextPage
        }
        totalCount
        edges {
          cursor
          node {
            id
            name
            channel
            firstMediaUrlByColor
            created
            hasMyReview
            hasMyWish
            translation(languageCode: EN) {
              id
              name
              translatableContent {
                attributeValues {
                  name
                }
              }
            }

            category {
              slug
            }
            collections {
              slug
            }
            metadata {
              key
              value
            }
            vendor {
              id
              storeNameKo
              storeNameEn
            }
            thumbnail {
              alt
              url
            }
            pricing {
              onSale
              priceRangeUndiscounted {
                start {
                  gross {
                    amount
                    currency
                  }
                }
                stop {
                  gross {
                    amount
                    currency
                  }
                }
              }
              discount {
                currency
                gross {
                  amount
                  currency
                }
              }
              priceRange {
                start {
                  gross {
                    amount
                    currency
                  }
                }
                stop {
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
            attributes {
              attribute {
                name
              }
              values {
                name
              }
            }
            color: attribute(slug: "product-color") {
              attribute {
                id
                name
              }
              values {
                id
                name
              }
            }
            variants {
              name
              attributes {
                attribute {
                  name
                  id
                }
                values {
                  id
                  name
                }
              }
              media {
                url
                id
                alt
              }
            }
          }
        }
      }
    }
  `;

  // 로그인 상태를 확인하여 client 선택
  const loggedInUser = localStorage.getItem('user');
  const tokenClient = loggedInUser ? client : nonTokenClient; // 로그인 여부에 따라 클라이언트 설정

  return tokenClient.query({
    query: GET_PRODUCTS_VENDOR,
    variables: {
      channel: channel,
      after: afterCursor,
      first: 10,
      ...(collections && { collections: collections }),
      ...(categories && categories.length > 0 && { categories: categories }),
      ...(metaFilters.length > 0 && { metaFilters: metaFilters }),
      ...(search && { search: search }),  // search 변수를 조건부로 추가
      sortField, // 정렬 필드
      sortDirection, // 정렬 방향,
      isRecentlyViewed,
      isMyWishProduct,
    },
    fetchPolicy: 'network-only'
  });
}

export function toggleProductWishList(productId) {
  const TOGGLE_PRODUCT_WISHLIST = gql`
    mutation toggleProductWishList($id: ID!) {
      productWishlistCreate(productId: $id) {
        response
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: TOGGLE_PRODUCT_WISHLIST,
    variables: {
      id: productId,  // productId는 Base64 인코딩된 문자열 ID
    },
  });
}

export function getWishlistItems() {

  if (localStorage.getItem('user') === null) {
    // 즉시 resolve되는 Promise를 반환하여 thenable 구조를 유지
    return Promise.resolve();
  }
  
  const GET_WISH_LIST = gql`
    query getUserWishlistItems {
      userWishlistItems {
        id
        addedOn
        user {
          id
          email
        }
        product {
          id
        }
      }
    }
  `;

  return client.query({
    query: GET_WISH_LIST,
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

export function addRecentlyViewedProduct(productId) {

  const ADD_RECENTLY_VIEWED_PRODUCT = gql`
    mutation productRecentlyViewCreate($productId: ID!) {
      productRecentlyViewCreate(productId: $productId) {
        response
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: ADD_RECENTLY_VIEWED_PRODUCT,
    variables: { productId }
  });
}

export function getRecentlyItems() {
  const GET_RECENTLY_LIST = gql`
    query getUserWishlistItems {
      userRecentlyItems {
        id
        user {
          id
        }
        product {
          id
        }
      }
    }
  `;

  return client.query({
    query: GET_RECENTLY_LIST,
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

export function getSearchList(
  searchKeyword, // 검색 키워드
  channel, // 채널 ID
  first, // 한 페이지에 표시할 항목 수
  afterCursor // 다음 페이지 ID
) {
  const SEARCH_PRODUCTS = gql`
    query SearchProducts(
      $channel: String!,
      $search: String!,
      $first: Int!,
      $after: String
    ) {
      products(
        channel: $channel,
        first: $first,
        after: $after,
        filter: {
          search: $search,
        }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  `;

  return nonTokenClient.query({
    query: SEARCH_PRODUCTS,
    variables: {
      channel: channel,
      search: searchKeyword,
      first: first,
      after: afterCursor
    },
    fetchPolicy: 'network-only'
  });
}