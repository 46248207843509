import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import 'swiper/css';
// import "../../assets/styles/scss/structure/articles/_front-guide.scss";

import TopNav from '../../layouts/top_bar/TopNav';
import BottomNav from '../../layouts/bottom_bar/BottomNav';
import useMainStore from '../../store/Pages/useMainStore'
import GridSection from '../../sections/grid/GridSection';
import H3Title from '../../layouts/title/H3Title';
import SwipeGridSection from '../../sections/grid/SwipeGridSection';
import BannerASection from '../../sections/banner/BannerASection';
import BannerDSection from '../../sections/banner/BannerDSection';
import BigTab from '../../components/Tabs/BigTab'; 
import { getCollection } from '../../services/GQ_apis/collection'; 
import { useLanguage } from '../../services/languageContext';

const GlobalBizPage = () => {
    
    const [activeSmallTab, setActiveSmallTab] = useState("all");
    const navigate = useNavigate();
    const [collection, setCollection] = useState([]);
    const { getTranslatedNameById } = useLanguage();

    const me = useMemo(()=>{
      const meData = JSON.parse(localStorage.getItem('me'));
      return meData;
    },[localStorage.getItem('me')])

    const tabs = [
      { name: getTranslatedNameById('TWVudUl0ZW06MjYw'), class: 'home', route: '/' }, 
      { name: getTranslatedNameById('TWVudUl0ZW06MjYx'), class: 'hotdeal', route: '/hotdeal' },
      { name: getTranslatedNameById('TWVudUl0ZW06MjYy'), class: 'ranking', route: '/ranking' },
      // { name: '해외직구', class: 'overseas', route: '/overseas' },
      { name: getTranslatedNameById('TWVudUl0ZW06MjY0'), class: 'couponBenefit', route: '/couponBenefit' },
      { name: getTranslatedNameById('TWVudUl0ZW06MjY1'), class: 'brand', route: '/brand' },
    ];
    
    // 빅탭 리스트
    const bigTabs = [
      { title: getTranslatedNameById('TWVudUl0ZW06NTkz') }, // 임신
      { title: getTranslatedNameById('TWVudUl0ZW06NTk0') }, // 베이비 0~24M
      { title: getTranslatedNameById('TWVudUl0ZW06NTk1') }, // 키즈 24M~8Y
      { title: getTranslatedNameById('TWVudUl0ZW06NTk2') }, // 주니어 8Y~13Y
      { title: getTranslatedNameById('TWVudUl0ZW06NTk3') }, // 해외직구
      { title: getTranslatedNameById('TWVudUl0ZW06NTk4') }, // 공동구매
      { title: getTranslatedNameById('TWVudUl0ZW06NTk5') }, // 건지면대박
      { title: getTranslatedNameById('TWVudUl0ZW06NjAw') }, // 패션 
      { title: getTranslatedNameById('TWVudUl0ZW06NjAx') }, // 라이프 
      { title: 'Food' }, 
      { title: getTranslatedNameById('TWVudUl0ZW06NjAy') }, // 토이교구
      { title: getTranslatedNameById('TWVudUl0ZW06NjAz') }, // 문화
      { title: getTranslatedNameById('TWVudUl0ZW06NjA0') }, // 오가닉유기농
      { title: getTranslatedNameById('TWVudUl0ZW06NjA1') }, // 해외배송
      { title: getTranslatedNameById('TWVudUl0ZW06NjA2') }, // 국내배송오늘출발
    ];
    
    // 스몰탭 리스트
    const smallTabs = [
        { key: 'all', label: 'All' },
        { key: 'category1', label: '맞춤' },
        { key: 'category2', label: '남아의류' },
        { key: 'category3', label: '신발' },
        { key: 'category4', label: '코트' },
    ];
    
    const slugs = [
      'biz1',
      'biz2',
      'biz3',
      'biz4',
      'biz5',
      'biz6',
      'biz7',
    ];

    const { activeTab, setCurrentPage, setActiveTab } = useMainStore();

    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
      getCollections();
    }, []);

    useEffect(() => {

      if( !me?.data?.me?.isStaff ) {
        alert('잘못된 접근입니다.');
        navigate('/');
      } 

    }, [me])


    const getCollections = async () => {
      try {
        const res = await getCollection(slugs);
        let data = [...res.data.collections.edges];
  
        data.sort((a, b) => {
          const numA = parseInt(a.node.name.match(/\d+/), 10);
          const numB = parseInt(b.node.name.match(/\d+/), 10);
          
          return numA - numB;
        });
  
        const formattedData = data.map(el => {
          let title = getTranslatedNameById('TWVudUl0ZW06Mjkx');
          if (el.node.description) {
            const parsedDescription = JSON.parse(el.node.description);
  
            title = parsedDescription.blocks
              .map(block => block.data.text)
              .join('<br>');
          }
          
          return {
            title: title,
            id: el.node.id
          };
        });
  
        setCollection(formattedData);
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };

    useEffect(() => {
        const currentTab = tabs.find(tab => location.pathname === tab.route);
        const pageIndex = currentTab ? tabs.indexOf(currentTab) : 0;
        setCurrentPage(pageIndex); 
        setActiveTab(currentTab ? currentTab.name : 'HOME'); 
        if(swiper) swiper.slideTo(pageIndex, 0);
    }, [location, swiper, setCurrentPage, setActiveTab]);

    const handleTabClick = (tab) => {
        const pageIndex = tabs.findIndex((t) => t.name === tab.name);
        setCurrentPage(pageIndex);
        setActiveTab(tab.name);
        swiper?.slideTo(pageIndex)
    };

    return (
        <>
            <TopNav/>
            <article id="GlobalBizPage">
              <section>
                {/*[D] 배너[START] */}
                <BannerDSection id='biz_b1'/>
                {/*[D] 배너[END] */}
                <div className="white-wrap">
                  {collection[0] && (
                    <>
                      <div className="banner-wrap">
                          {/* 빅탭 [START] */}
                          <BigTab tabs={bigTabs} />
                          {/* 빅탭 [END] */}

                          <br />

                          {/*[A] 배너[START] */}
                          <div className="banner-wrap vw100 ">
                          <BannerASection id='biz_b2'/>
                          </div>
                          {/*[A] 배너[END] */}

                          <br/>

                          {/*[A] 배너[START] */}
                          <div className="banner-wrap vw100 mb16">
                          <BannerASection id='biz_b3'/>
                          </div>
                          {/*[A] 배너[END] */}

                          <H3Title id={collection[0].id} title={collection[0].title}/>

                          <div className="banner-wrap mb16">
                              {/*[D] 배너[START] */}
                              <BannerDSection id='biz_b4'/>
                              {/*[D] 배너[END] */}
                          </div>

                          {/* [SMALL] 상품 리스트 [START] */}
                              <SwipeGridSection 
                              collection={collection[0].id}
                              />
                          {/* [SMALL] 상품 리스트 [END] */}

                          <br />

                          <div className="banner-wrap mb16">
                              {/*[D] 배너[START] */}
                              <BannerDSection id='biz_b5'/>
                              {/*[D] 배너[END] */}
                          </div>

                          {/* <button 
                          type="button" 
                          className="btn big round shortcuts mt20"
                          onClick={() => navigate(`/product-list?id=${collection[0].id}&title=${collection[0].title}`)}
                          >
                          셀러 바로가기
                          </button> */}
                      </div>
                    </>
                  )}
                </div>

                <div className="white-wrap">
                    <>
                      <div className="banner-wrap mb16">
                          {/*[D] 배너[START] */}
                          <BannerDSection id='biz_b6'/>
                          {/*[D] 배너[END] */}
                      </div>

                      {/* <button 
                      type="button" 
                      className="btn big round shortcuts mt20"
                      onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                      >
                      셀러 바로가기
                      </button> */}
                    </>
                </div>

                <div className="white-wrap">
                    <>
                      <div className="banner-wrap mb16">
                          {/*[D] 배너[START] */}
                          <BannerDSection id='biz_b7'/>
                          {/*[D] 배너[END] */}
                      </div>

                      {/* <button 
                      type="button" 
                      className="btn big round shortcuts mt20"
                      onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                      >
                      셀러 바로가기
                      </button> */}
                    </>
                </div>

                <div className="white-wrap">
                    <>
                      <div className="banner-wrap mb16">
                          {/*[D] 배너[START] */}
                          <BannerDSection id='biz_b8'/>
                          {/*[D] 배너[END] */}
                      </div>

                      {/* <button 
                      type="button" 
                      className="btn big round shortcuts mt20"
                      onClick={() => navigate(`/product-list/요즈음 뜨는 국내 인기 핫템!!`)}
                      >
                      셀러 바로가기
                      </button> */}
                    </>
                </div>

                <div className="white-wrap">
                  {collection[1] && (
                    <>
                      <H3Title id={collection[1].id} title={collection[1].title}/>

                      <div className="banner-wrap mb16">
                      {/*[D] 배너[START] */}
                      <BannerDSection id='biz_b9'/>
                      {/*[D] 배너[END] */}
                      </div>
                      
                      <GridSection 
                      type="big" 
                      option={false}
                      collection={collection[1].id}
                      />
                      <button 
                      type="button" 
                      className="btn big round more mt40"
                      onClick={()=>navigate(`/product-list?id=${collection[1].id}&title=${collection[1].title}`)}
                      >
                      {getTranslatedNameById('TWVudUl0ZW06Mjcz')} {/* 더 보기 */}
                      </button>
                    </>
                  )}
                </div>

                <div className="white-wrap">
                  {collection[2] && (
                    <>
                      <H3Title id={collection[2].id} title={collection[2].title}/>

                      {/*[D] 배너[START] */}
                      <div className="banner-wrap mb16">
                      <BannerDSection id='biz_b10'/>
                      </div>
                      {/*[D] 배너[END] */}

                      <GridSection 
                      type="big" 
                      option={false}
                      collection={collection[2].id}
                      />

                      <button 
                      type="button" 
                      className="btn big round more mt40"
                      onClick={() => navigate(`/product-list?id=${collection[2].id}&title=${collection[2].title}`)}
                      >
                      {getTranslatedNameById('TWVudUl0ZW06Mjcz')} {/* 더 보기 */}
                      </button>
                    </>
                  )}
                </div>

                <div className="white-wrap">
                  {collection[3] && (
                    <>
                      <H3Title id={collection[3].id} title={collection[3].title}/>

                      {/*[D] 배너[START] */}
                      <div className="banner-wrap mb16">
                      <BannerDSection id='biz_b11'/>
                      </div>
                      {/*[D] 배너[END] */}

                      {/* [SMALL] 상품 리스트 [START] */}
                      <SwipeGridSection 
                      collection={collection[3].id}
                      />
                      {/* [SMALL] 상품 리스트 [END] */}
                    </>
                  )}
                </div>
                
                <div className="white-wrap">
                  {collection[4] && (
                    <>
                      <H3Title id={collection[4].id} title={collection[4].title}/>

                      {/*[D] 배너[START] */}
                      <div className="banner-wrap mb16">
                      <BannerDSection id='biz_b12'/>
                      </div>
                      {/*[D] 배너[END] */}

                      {/* [SMALL] 상품 리스트 [START] */}
                      <SwipeGridSection 
                      collection={collection[4].id}
                      />
                      {/* [SMALL] 상품 리스트 [END] */}
                    </>
                  )}
                </div>

                <div className="white-wrap">
                  {collection[5] && (
                    <>
                      <H3Title id={collection[5].id} title={collection[5].title}/>

                      {/* <div className="slide-tabs no-fixed pl0 pr0">
                          <div className="tabs">
                          {tabs.map((tab, index) => (
                              <button
                              key={index}
                              onClick={() => handleTabClick(tab)}
                              className={`tab ${tab.class} ${activeTab === tab.name ? 'active' : ''}`}
                              >
                              {tab.name}
                              </button>
                          ))}
                          </div>
                      </div> */}

                      <GridSection 
                      type="big" 
                      option={false}
                      collection={collection[5].id}
                      />
                      <button 
                      type="button" 
                      className="btn big round more mt40"
                      onClick={()=>navigate(`/product-list?id=${collection[5].id}&title=${collection[5].title}`)}
                      >
                      {getTranslatedNameById('TWVudUl0ZW06Mjcz')} {/* 더 보기 */}
                      </button>
                      <div className="bottom-border mb20"></div>
                    </>
                  )}
                </div>

                <div className="white-wrap">
                  {collection[6] && (
                    <>
                      <H3Title id={collection[6].id} title={collection[6].title}/>

                      {/*[D] 배너[START] */}
                      <div className="banner-wrap mb16">
                      <BannerDSection id='biz_b13'/>
                      </div>
                      {/*[D] 배너[END] */}

                      <GridSection 
                      type="big" 
                      option={false}
                      collection={collection[6].id}
                      />
                      <button 
                      type="button" 
                      className="btn big round more mt40"
                      onClick={()=>navigate(`/product-list?id=${collection[6].id}&title=${collection[6].title}`)}
                      >
                      {getTranslatedNameById('TWVudUl0ZW06Mjcz')} {/* 더 보기 */}
                      </button>
                      <div className="banner-wrap mt40 mb16">
                          {/*[D] 배너[START] */}
                          <BannerASection id='biz_b14'/>
                          {/*[D] 배너[END] */}
                      </div>
                      <div className="banner-wrap mb16">
                          {/*[D] 배너[START] */}
                          <BannerASection id='biz_b15'/>
                          {/*[D] 배너[END] */}
                      </div>
                    </>
                  )}
                  
                </div>

              </section>
            </article>
            <BottomNav/>
        </>

      );
};

export default GlobalBizPage;
