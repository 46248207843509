import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getProductByVendor, toggleProductWishList, getWishlistItems, getSearchList } from '../../services/GQ_apis/product';
import { getCollectionByProducts } from '../../services/GQ_apis/collection';
import useAuthStore from '../../store/common/useAuthStore';
import ThumbnailImage from '../../components/common/ThumbnailImage';
import { useLanguage } from '../../services/languageContext';

const GridSection = ({
  type='middle',
  option=false, 
  category='',
  collection='',
  meta='',
  infinity=false,
  vid='',
  sortType='',
  slugs='',
  search='', 
  onTotalCountChange,
  isRecentlyViewed=false,
  isMyWishProduct=false,
}) => {

  const [gridType, setGridType] = useState(type);
  const { getTranslatedNameById, language } = useLanguage();
  const { globalObserver, observerUpdate } = useAuthStore();

  const toggleGridType = () => {
    setGridType(prevType => (prevType === 'middle' ? 'big' : 'middle'));
  };

  const [gridLi, setGridLi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [afterCursor, setAfterCursor] = useState(null);
  const lastGridElementRef = useRef(null); 
  const [totalCount, setTotalCount] = useState(0);

  const [sortOption, setSortOption] = useState('1');  
  const [sortField, setSortField] = useState('CREATED_AT');
  const [sortDirection, setSortDirection] = useState('DESC');
  const previousSortOption = useRef(sortOption); 

  const componentObserver = useRef(null); 

  const handleResponse = (res, isCollection = false) => {
    let data, totalCount, hasNextPage;
  
    if (isCollection) {
      const collectionNode = res.data.collections.edges?.[0]?.node;
  
      if (!collectionNode || !collectionNode.products) {
        console.error("컬렉션 데이터가 비어 있습니다.");
        setLoading(false);
        setHasMore(false); 
        return;
      }
  
      data = collectionNode.products.edges;
      totalCount = collectionNode.products.totalCount;
      hasNextPage = collectionNode.products.pageInfo.hasNextPage;
    } else {

      console.log('grid data :', res);

      data = res.data.products.edges;
      totalCount = res.data.products.totalCount;
      hasNextPage = res.data.products.pageInfo.hasNextPage;
    }
  
    setTotalCount(totalCount);
    if (onTotalCountChange) {
      onTotalCountChange(totalCount);
    }

    if (!data || data.length === 0) {
      setHasMore(false);
      setLoading(false);
      return;
    }

    setGridLi(prev => (prev.length === 0 ? data : [...prev, ...data]));
  
    if (data && data.length > 0) {
      setAfterCursor(data[data.length - 1].cursor);
    }
  
    setHasMore(hasNextPage);
    setLoading(false);
  };
  
  const fetchProductData = () => {
    if (loading || !hasMore) return;
    setLoading(true);

    let localSortField, localSortDirection;
    let channel = window.WSgetChannel();
    let localSlugs = []

    if(channel == 'EN') {
      localSlugs = 'global_' +slugs
    } else {
      localSlugs = slugs
    }

    channel = channel.toLowerCase()

    if (sortType.length !== 0 && sortType === 'collection') {
      localSortField = 'COLLECTION';
      localSortDirection = 'ASC';

      getCollectionByProducts({
        slugs: localSlugs,
        channel,
        after: afterCursor,
        metaFilters: meta || [],
        sortField: localSortField,
        sortDirection: localSortDirection
      })
        .then(res => handleResponse(res, true))
        .catch(error => {
          console.error('Error fetching collection-products:', error);
          setLoading(false);
        });
    } else {
      localSortField = sortField;
      localSortDirection = sortDirection;
      const lowerChannel = channel.toLowerCase()

      getProductByVendor(
        afterCursor,
        category,
        collection,
        lowerChannel,
        meta,
        vid,
        localSortField,
        localSortDirection,
        search,
        isRecentlyViewed,
        isMyWishProduct
      )
        .then(res => {
          handleResponse(res, false)
        })
        .catch(error => {
          console.error('Error fetching products:', error);
          setLoading(false);
        });
    }
  };

  const updateWishList = (productId) => {
    toggleProductWishList(productId).then(()=>{
      setLoading(false);
      observerUpdate(); 
    })
  }

  const handleSortChange = (event) => {
    const option = event.target.value
    setSortOption(option);

    let sortField = null;
    let sortDirection = 'DESC';

    if (option === '0') {
      sortField = 'POPULAR';
    } else if (option === '1') {  
      sortField = 'CREATED_AT';
    } else if (option === '2') {  
      sortField = 'SALE';
    } else if (option === '3') {  
      sortField = 'PRICE';
    } else if (option === '4') {  
      sortField = 'PRICE';
      sortDirection = 'ASC';
    } 

    if (previousSortOption.current === option) {
      previousSortOption.current = option;
      return;
    }

    setSortField(sortField);
    setSortDirection(sortDirection);
    setAfterCursor(null);

    previousSortOption.current = option;
  };

  const [selectedThumbnails, setSelectedThumbnails] = useState({});

  const colorClick = (color, variants, productId) => {
    const selectedVariant = variants.find(variant =>
      variant.attributes.some(attr => 
        attr.values.some(value => value.name.toLowerCase().trim() === color.toLowerCase().trim())
      )
    );

    if (selectedVariant && selectedVariant.media.length > 0) {
      const newThumbnailUrl = selectedVariant.media[0].url;
      setSelectedThumbnails(prev => ({
        ...prev,
        [productId]: newThumbnailUrl  
      }));
    }
  };

  useEffect(() => {
    setGridLi([]); 
    setHasMore(true); 
    setAfterCursor(null); 
  }, [sortField, sortDirection, category, meta, vid, globalObserver]);


  useEffect(() => {
    const currentObserver = componentObserver.current;
    if (currentObserver) currentObserver.disconnect();
  
    componentObserver.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !loading) {
        if (gridLi.length === 0 || (hasMore && infinity)) {
          fetchProductData();
        }
      }
    }, {
      threshold: 1.0
    });
  
    if (lastGridElementRef.current) {
      componentObserver.current.observe(lastGridElementRef.current);
    }
  
    return () => {
      if (currentObserver) currentObserver.disconnect();
    };
  }, [sortOption, sortField, sortDirection, afterCursor, vid, meta, category, hasMore, infinity, loading, isRecentlyViewed, isMyWishProduct]);

  const filteredGridLi = vid ? gridLi.filter(item => item.node.vendor?.id === vid) : gridLi;
  
  if (filteredGridLi.length === 0 && loading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }
  
  return (
    <>
      {/* 옵션 [START] */}
      {option && (
        <div className="grid-option-wrap">
          <span className='mt8'>
            {getTranslatedNameById('TWVudUl0ZW06Mjky')} <b className="f-purple">
              {infinity ? totalCount : filteredGridLi.length}
              {/* 언어에 따른 공백 처리 */}
              {language === 'EN' ? ` ${getTranslatedNameById('TWVudUl0ZW06Mjk4')}` : getTranslatedNameById('TWVudUl0ZW06Mjk4')}
            </b>
          </span>
          <div className="options">
            <div className="input-box">
              <div className="input">
                <select className='option-select' value={sortOption} onChange={handleSortChange}>
                  <option value="0">{getTranslatedNameById('TWVudUl0ZW06Mjkz')}</option>
                  <option value="1">{getTranslatedNameById('TWVudUl0ZW06Mjk0')}</option>
                  <option value="2">{getTranslatedNameById('TWVudUl0ZW06Mjk1')}</option>
                  <option value="3">{getTranslatedNameById('TWVudUl0ZW06Mjk2')}</option>
                  <option value="4">{getTranslatedNameById('TWVudUl0ZW06Mjk3')}</option>
                </select>
              </div>
            </div>
            <div className="view-options mt8">
              <button className={`option ${gridType}`} onClick={toggleGridType}>
              </button>
            </div>
          </div>
        </div>
      )}
      {/* 옵션 [END] */}
      {/* 그리드(리스트) [START] */}
      <section className="section-grid">
        <div className="grid-wrap">
          <ul className={`grid gap16 ${gridType === 'big' ? 'grid2' : gridType === 'middle' ? 'grid1' : 'grid3'}`}>
            {filteredGridLi.map((item, index) => (
              <li 
                className={`card ${gridType}`} 
                key={index}
              >
                <div className="card-inner">
                  <div className="img-wrap">
                    <Link 
                      title="클릭시 해당 상품 페이지로 이동"
                      to={"/product/"+item.node.id}
                    > 
                      {
                        selectedThumbnails[item.node.id] ? 
                        <ThumbnailImage thumbnailUrl={selectedThumbnails[item.node.id]} thumbnailAlt={item?.node?.thumbnail?.alt} /> :
                        <ThumbnailImage thumbnailUrl={item?.node?.thumbnail?.url} thumbnailAlt={item?.node?.thumbnail?.alt} />
                      }
                    </Link>
                    <button 
                      type="button" 
                      className={`icon-heart white ${ item.node.hasMyWish ? 'active' : ''}`}
                      onClick={()=>{updateWishList(item.node.id)}}
                    >
                      {item.node.hasMyWish}
                    </button>
                  </div>
                  <div className="info-wrap">
                    {item.node.vendor !== null && 
                      <Link 
                        title="클릭시 해당 상품 페이지로 이동"
                        to={"/brand/detail/"+item.node.vendor.id}
                        className="brand"
                      >
                        {
                          item.node.channel === 'ko' ? (
                            item.node.vendor.storeNameKo ? item.node.vendor.storeNameKo : 'no-store-name'
                          ) : item.node.channel === 'en' ? (
                            item.node.vendor.storeNameEn ? item.node.vendor.storeNameEn : 'no-store-name'
                          ) : (
                            'no-store-name'
                          )
                        }
                      </Link>
                    }
                    <Link 
                      title="클릭시 해당 상품 페이지로 이동"
                      to={"/product/"+item.node.id}
                    >
                      <div className="title">
                        {
                          item?.node?.channel === 'ko' && item?.node?.name ? (
                            item?.node?.name
                          ) : item?.node?.channel === 'en' && item?.node?.translation?.name ? (
                            item?.node?.translation?.name
                          ) : (
                            item?.node?.name
                          )
                        }
                      </div>
                      <div className="price-wrap">
                        {
                          item?.node?.pricing?.onSale &&
                          (
                            <div className="before-price">
                              <span className="f-purple">Sale</span>
                              <span className="price">
                                {window.WSformatPrice(item?.node?.pricing?.priceRangeUndiscounted?.start?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.stop?.gross?.currency)}
                                {
                                  item?.node?.pricing?.priceRangeUndiscounted?.start?.gross?.amount != item?.node?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount &&
                                  <>
                                    <>~</>
                                    {window.WSformatPrice(item?.node?.pricing?.priceRangeUndiscounted?.stop?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.stop?.gross?.currency)}
                                  </>
                                }
                              </span>
                            </div>
                          )
                        }
                        <div className="now-price">
                          {/* <span className="f-purple">{item.discount.discounted.raw}%</span> */}
                          <span className="price">
                            {window.WSformatPrice(item?.node?.pricing?.priceRange?.start?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.start?.gross?.currency)}
                            {item?.node?.pricing?.priceRange?.stop?.gross?.amount !== item?.node?.pricing?.priceRange?.start?.gross?.amount && <>
                              ~
                              {window.WSformatPrice(item?.node?.pricing?.priceRange?.stop?.gross?.amount)}{window.WSCurrency(item?.node?.pricing?.priceRange?.stop?.gross?.currency)}
                            </>}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <div className="colors-wrap">
                      <ul className="colors">
                        {item.node.color.values.length > 0 &&
                          item.node.color.values.map((colorItem, colorIndex) => (
                            <li className="color" key={colorItem + colorIndex}>
                              <button
                                type="button"
                                className={colorItem.name}
                                onClick={() => colorClick(colorItem.name, item.node.variants, item.node.id)}  // productId 추가
                              >
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* 그리드(리스트) [END] */}
      
      {/* 스크롤 타켓 [START] */}
      <div className="scroll-target" ref={lastGridElementRef}>
        {
          hasMore && loading &&
          <div className="loading-wrap">
            <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        }
      </div>
      {/* 스크롤 타켓 [END] */}
    </>
  );
};

export default GridSection;
