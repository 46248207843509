import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { addAddress, updateAddress, setDefaultAddress, fetchAddress, updateAccountAddress } from '../../services/GQ_apis/address';
import { useLanguage } from '../../services/languageContext';

const DeliveryOverseasSection = () => {

  const { language, getTranslatedNameById } = useLanguage();

  const [addressName, setAddressName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [countryArea, setCountryArea] = useState('');
  const [city, setCity] = useState('');
  const [customsCode, setCustomsCode] = useState('');
  const [message, setMessage] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [country, setCountry] = useState('US');
  const [streetAddress1, setStreetAddress1] = useState('');

  const navigate = useNavigate();
  const { Page3depth } = useParams();
  const [fromOrder, setFromOrder] = useState(false); // fromOrder 상태 관리

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fromOrderParam = urlParams.get("from-order");

    if (fromOrderParam == "") {
      setFromOrder(true);
    } else {
      setFromOrder(false);
    }
  }, [])

  useEffect(() => {
    if (!Page3depth) return;

    fetchAddress(Page3depth).then((res) => {

      var address = res.data.address;
      console.log('res.data.address', res.data.address);

      if (!address) return;

      setAddressName(address.metadata[0].value);
      setFirstName(address.firstName);
      setPhoneNumber(window.WSformatPhoneNumber(address.phone));
      setPostalCode(address.postalCode);
      setStreetAddress1(address.streetAddress1);
      setCustomsCode(address?.metadata[1]?.value);
      setMessage(address?.metadata[2]?.value);
      setCity(address.city);
      setCountryArea(address.countryArea);
    });
  }, []);

  const saveAddress = () => {
    let input = {
      "firstName": firstName,
      "phone": phone,
      "postalCode": postalCode,
      "countryArea": countryArea,
      "city": city,
      "country": country,
      "streetAddress1": streetAddress1,
      "streetAddress2": streetAddress1,
      "metadata": [
        { "key": "addressName", "value": addressName },
        { "key": "customsCode", "value": customsCode },
        { "key": "message", "value": message }
      ]
    };

    if (!Page3depth) {
      // 배송지 생성
      addAddress(input).then((res) => {
        if (res.data.accountAddressCreate.errors.length > 0) {
          alert(getTranslatedNameById("TWVudUl0ZW06MzQ3")); // "잘못된 주소입니다."
        } else {
          if (!isDefault) {
            if (fromOrder) { window.location.href = '/delivery/list?from-order' }
            else { window.location.href = '/delivery/list' }
          } else {
            setDefaultAddress(res.data.accountAddressCreate.address.id, 'SHIPPING').then((res) => {
              console.log('SHIPPING res :', res);
            });
            setDefaultAddress(res.data.accountAddressCreate.address.id, 'SHIPPING').then((res) => {
              console.log('BILLING res :', res);
              if (fromOrder) { window.location.href = '/pay/process' }
              else { window.location.href = '/delivery/list' }
            });
          }
        }
      });
    }
    else {
      // 배송지 업데이트
      updateAccountAddress(Page3depth, input).then((res) => {
        console.log('updateAddress res :', res);
        if (!isDefault) return;
        setDefaultAddress(Page3depth, 'SHIPPING').then((res) => {
          console.log('SHIPPING res :', res);
        });
        setDefaultAddress(Page3depth, 'BILLING').then((res) => {
          console.log('BILLING res :', res);
          window.location.href = '/delivery/list';
        });
      });
    }
  };

  return (
    <>
      <section className="section-delivery-overseas">
        <div className="inner">
          <button
            type="button"
            className="btn purple delivery"
            onClick={() => location.search === "?from-order" 
              ? navigate('/delivery/domestic?from-order') 
              : navigate('/delivery/domestic')
            }
          >
            <span className="icon-change"></span>
            {language === 'KO' ? '국내 배송' : 'Domestic Shipping'}
          </button>
          <div className="input-box">
            <label htmlFor="inputAddressName" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzQw")} {/* "배송지명" */}
            </label>
            <div className="input">
              <input
                id="inputAddressName"
                className="space"
                type="text"
                placeholder={getTranslatedNameById("TWVudUl0ZW06MzQw")} // 배송지명
                onInput={(e) => setAddressName(e.target.value)}
                value={addressName}
              />
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputId" className="title starred">
              {language === 'KO' ? '배송국가' : 'Shipping Country'}
            </label>
            <select onInput={(e) => setCountry(e.target.value)}>
              <option>{language === 'KO' ? '배송국가를 선택하세요' : 'Select a country'}</option>
              <option value='US'>{language === 'KO' ? '미국' : 'United States'}</option>
            </select>
          </div>
          <div className="input-box">
            <label htmlFor="inputName" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzQx")} {/* "받는사람" */}
            </label>
            <div className="input">
              <input
                id="inputName"
                className="space"
                type="text"
                placeholder={language === 'KO' ? '영문으로 작성해주세요' : 'Please write in English'}
                onInput={(e) => setFirstName(e.target.value)}
                value={firstName}
              >
              </input>
            </div>
          </div>
          <div className="input-box">
            <label htmlFor="inputNumber" className="title starred">
              {getTranslatedNameById("TWVudUl0ZW06MzE3")} {/* "연락처" */}
            </label>
            <div className="input">
              <input
                id="inputNumber"
                type="text"
                placeholder="0001112222"
                onInput={(e) => setPhoneNumber(e.target.value)}
                value={phone}
              >
              </input>
            </div>
          </div>
          <div className="input-box">
            <strong className="title starred">
              Address
            </strong>
            <div className="input">
              <label htmlFor="inputState" className="sub-title">
                State
              </label>
              <input
                id="inputState"
                type="text"
                className="space"
                placeholder={language === 'KO' ? '영문/숫자 작성해 주세요' : 'Please write in English or numbers'}
                onInput={(e) => setCountryArea(e.target.value)}
                value={countryArea}
              >
              </input>
            </div>
            <div className="input">
              <label htmlFor="inputCity" className="sub-title">
                City
              </label>
              <input
                id="inputCity"
                type="text"
                className="space"
                placeholder={language === 'KO' ? '영문/숫자 작성해 주세요' : 'Please write in English or numbers'}
                onInput={(e) => setCity(e.target.value)}
                value={city}
              >
              </input>
            </div>
            <div className="input">
              <label htmlFor="inputCounty" className="sub-title">
                Address
              </label>
              <input
                id="inputCounty"
                className="space"
                type="text"
                placeholder={language === 'KO' ? '영문/숫자 작성해 주세요' : 'Please write in English or numbers'}
                onInput={(e) => setStreetAddress1(e.target.value)}
                value={streetAddress1}
              >
              </input>
            </div>
            <div className="input">
              <label htmlFor="inputZipCode" className="sub-title">
                Zip Code
              </label>
              <input
                id="inputZipCode"
                type="number"
                placeholder={language === 'KO' ? '숫자만 작성해 주세요' : 'Please enter numbers only'}
                onInput={(e) => setPostalCode(e.target.value)}
                value={postalCode}
              >
              </input>
            </div>
          </div>
          <div className="input-box">
            <div className="input check bg">
              <label htmlFor="check0">
                <input
                  id="check0"
                  type="checkbox"
                  onChange={(e) => setIsDefault(e.target.checked)}
                />
                {getTranslatedNameById("TWVudUl0ZW06MzQ1")} {/* "해당 주소를 기본 배송지로 등록" */}
              </label>
            </div>
          </div>
        </div>
      </section>

      <div className="bottom-bar">
        <div className="inner">
          <button
            type="button"
            className="btn big purple"
            onClick={saveAddress}
          >
            {getTranslatedNameById("TWVudUl0ZW06MzQ2")} {/* "저장하기" */}
          </button>
        </div>
      </div>
    </>
  );
};

export default DeliveryOverseasSection;
