import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom';

import MainPage from "../pages/main/MainPage"; // 메인 (HOME, 핫딜, 랭킹, 해외직구, 쿠폰/혜택, 브랜드)
import LoginPage from "../pages/login/LoginPage" // 로그인
import SearchPage from "../pages/search/SearchPage" // 검색
import AlretPage from "../pages/etc/AlertPage" // 알람
import CustomerCenterPage from "../pages/etc/CustomerCenterPage"; // 고객센터(공지사항, FAQ)
import RegisterPage from "../pages/register/RegisterPage" // 회원가입
import VendorPage from "../pages/vendor/VendorPage" // 벤더
import BrandDetailPage from "../pages/main/brand/BrandDetailPage"; // 메인 - 브랜드 상세
import CategoryPage from "../pages/category/CategoryPage"; // 카테고리
import BizPage from "../pages/biz/BizPage"; // 비즈
import ProductPage from "../pages/product/ProductPage"; // 상품
import ProductListPage from "../pages/product/ProductListPage"; // 상품
import PromotionPage from "../pages/promotion/PromotionPage"; // 프로모션
import OrderPage from "../pages/order/OrderPage"; // 주문메뉴
import MymenuPage from "../pages/mymenu/MymenuPage"; // 마이메뉴
import PayPage from "../pages/pay/PayPage"; // 결제

import TossCheckoutPage from "../pages/pay/TossCheckoutPage"; // 토스 결제 체크아웃
import TossSuccessPage from "../pages/pay/TossSuccessPage"; // 토스 결제 성공
import TossFailPage from "../pages/pay/TossFailPage"; // 토스 결제 실패

import EasyPayCheckoutPage from "../pages/pay/EasyPayCheckoutPage"; // 이지페이 결제 체크아웃
import EasyPaySuccessPage from "../pages/pay/EasyPaySuccessPage"; // 이지페이 결제 성공
import EasyPayFailPage from "../pages/pay/EasyPayFailPage"; // 이지페이 결제 실패

import DeliveryPage from "../pages/delivery/DeliveryPage"; // 배송지 관리 등
import ZaeLookPage from "../pages/zaelook/ZaeLookPage"; // 재룩
import ZaeLookDetailPage from "../pages/zaelook/ZaeLookDetailPage"; // 재룩 - 상세
import CouponPage from "../pages/coupon/CouponPage"; // 쿠폰
import QuestionPage from "../pages/etc/QuestionPage"; // 1:1 문의하기

import ErrorPage from "../pages/ErrorPage";
import FrontGuidePage from "../pages/FrontGuidePage";
import FrontGuide2Page from "../pages/FrontGuide2Page";
import GlobalBizPage from "../pages/biz/GlobalBizPage"; // 글로벌비즈

// 보호된 경로 설정
const protectedRoutes = [
  { path: "/alert", element: <AlretPage /> },
  { path: "/mymenu/:Page2depth", element: <MymenuPage /> },
  { path: "/mymenu/:Page2depth/:Page3depth", element: <MymenuPage /> },
  { path: "/order/:Page2depth", element: <OrderPage /> },
  { path: "/order/:Page2depth/:Page3depth", element: <OrderPage /> },
  { path: "/product/:Page2depth", element: <ProductPage /> },
  { path: "/product/:Page2depth/:Page3depth", element: <ProductPage /> },
  { path: "/question-write/:Page2depth", element: <QuestionPage /> },
  { path: "/delivery/:Page2depth", element: <DeliveryPage /> },
  { path: "/delivery/:Page2depth/:Page3depth", element: <DeliveryPage /> },
  { path: "/register/kid-1", element: <RegisterPage /> },
  { path: "/register/kid-2", element: <RegisterPage /> },
  { path: "/register/kid-3", element: <RegisterPage /> },
  { path: "/register/kid-4", element: <RegisterPage /> },
];

const ProtectedRoute = ({ children }) => {

  if (! localStorage.getItem('user')) {

    alert('로그인 후 이용해주세요.');

    return <Navigate to="/login/main2" replace />;
  }
  return children;
};

const PagesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage/>}/>
      <Route path="/:Page2depth" element={<MainPage/>} />
      <Route path="/brand/detail/:Page3depth" element={<BrandDetailPage/>} />
      <Route path="/promotion" element={<PromotionPage/>} />
      <Route path="/search/:Page2depth" element={<SearchPage/>} />
      <Route path="/customer-center" element={<CustomerCenterPage/>} />

      <Route path="/login/:Page2depth" element={<LoginPage/>}/>
      <Route path="/auth/kakao/callback" element={<LoginPage/>}/>
      <Route path="/auth/google/callback" element={<LoginPage/>}/>
      <Route path="/register/:Page2depth" element={<RegisterPage/>}/>
      <Route path="/vendor/:Page2depth" element={<VendorPage/>}/>

      <Route path="/category" element={<CategoryPage/>} />
      <Route path="/category/:Page2depth" element={<CategoryPage/>} />
      <Route path="/product-list/:Page2depth" element={<ProductListPage/>} />
      <Route path="/product-list" element={<ProductListPage/>} />
      <Route path="/biz" element={<BizPage />} /> 
      <Route path="/globalbiz" element={<GlobalBizPage />} /> 
      <Route path="/pay/:Page2depth" element={<PayPage/>} />
      <Route path="/pay/:Page2depth/:Page3depth" element={<PayPage/>} />

      <Route path="/pay/toss/checkout" element={<TossCheckoutPage/>} />
      <Route path="/pay/toss/success" element={<TossSuccessPage/>} />
      <Route path="/pay/toss/fail" element={<TossFailPage/>} />

      <Route path="/pay/easypay/checkout" element={<EasyPayCheckoutPage/>} />
      <Route path="/pay/easypay/success" element={<EasyPaySuccessPage/>} />
      <Route path="/pay/easypay/fail" element={<EasyPayFailPage/>} />

      <Route path="/zaelook/:Page2depth" element={<ZaeLookPage/>} />
      <Route path="/zaelook/:Page2depth/:Page3depth" element={<ZaeLookDetailPage/>} />
      <Route path="/coupons/:Page2depth" element={<CouponPage/>} />

      <Route path="*" element={<ErrorPage/>} />
      <Route path="/front-guide" element={<FrontGuidePage/>} />
      <Route path="/front-guide2" element={<FrontGuide2Page/>} />

      {/* 로그인 필요 페이지  */}
      {protectedRoutes.map(route => (
        <Route key={route.path} path={route.path} element={<ProtectedRoute>{route.element}</ProtectedRoute>} />
      ))}

    </Routes>
  );
};

export default PagesRoute;