import api from '../api';

export const getAlertByEmail = async (email) => {
    try {
        const response = await api.post('/api-tg/alert/get-by-email', { email: email });
        return response.data;
    } catch (error) {
        console.error('Error fetching banners by alert:', error);
        throw error;
    }
};

export const readAlert = async (id) => {
    try {
        const response = await api.post('/api-tg/alert/read', { id: id });
        return response.status;
    } catch (error) {
        console.error('Error fetching banners by alert:', error);
        throw error;
    }
}