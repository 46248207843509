import React from "react";
import { useParams, useLocation } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';

import LoginMain01Section from '../../sections/login/LoginMain01Section'; 
import LoginMain02Section from '../../sections/login/LoginMain02Section';
import LoginFindIdSection from '../../sections/login/LoginFindIdSection';
import LoginFindPwSection from '../../sections/login/LoginFindPwSection';
import KakaoLoginSection from '../../sections/login/KakaoLoginSection';
import GoogleLoginSection from '../../sections/login/GoogleLoginSection';  // GoogleLoginSection 추가
import { useLanguage } from '../../services/languageContext';

function LoginPage() {
  const { Page2depth } = useParams();
  const location = useLocation();  // 현재 경로를 확인하기 위한 useLocation 훅
  const { getTranslatedNameById, language } = useLanguage();

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      case 'findid' :
        return getTranslatedNameById("TWVudUl0ZW06NTAx") 
      case 'findpw' :
        return getTranslatedNameById("TWVudUl0ZW06NTAy")
      default:
        return getTranslatedNameById("TWVudUl0ZW06NTAx") 
    }
  };

  const isKakaoCallback = location.pathname === '/auth/kakao/callback'; // 카카오
  const isGoogleCallback = location.pathname === '/auth/google/callback'; // 구글

  return (
    <>
      {
        (Page2depth === 'findid' || Page2depth === 'findpw') && 
        <TopBar  
          title={getTitle(Page2depth)}
        />
      }

      <article 
        id="loginPage" 
        className={`${Page2depth === 'main1' || Page2depth === 'main2' ? 'main' : ''}`}
      >
        {Page2depth === 'main1' && <LoginMain01Section />}
        {Page2depth === 'main2' && <LoginMain02Section />}
        {Page2depth === 'findid' && <LoginFindIdSection />}
        {Page2depth === 'findpw' && <LoginFindPwSection />}
        {isKakaoCallback && <KakaoLoginSection />}
        {isGoogleCallback && <GoogleLoginSection />}
      </article>
    </>
  );
}

export default LoginPage;
