import React, { useState, useEffect } from "react";
import OrderStatusCard from '../../components/Card/OrderStatusCard'

const OrderCancelListSection = ({orderList}) => {

  return (
    <>
      <section className="section-mymenu-orderlist">
        <div className="order-list-wrap">
          <ul className="order-list">
            {
              orderList.map((order,index)=>(
                (
                  order.node.status === 'CANCELED' ||
                  order.node.status === 'REQUEST_CANCEL'
                ) 
                ? <OrderStatusCard order={order} index={index}/> : ''
                // <div className="mt40 a-center">
                //   {
                //     index < 1 && '취소 주문 정보가 없습니다.'
                //   }
                // </div>
              ))
            }
          </ul>
        </div>
      </section>
    </>
  );
};

export default OrderCancelListSection;

