import React, { useState, useEffect, useRef } from "react";
import sampleImage from "../../assets/images/common/icons/icon_profile.svg";
import { fetchPages } from "../../services/GQ_apis/page";
import TopBar from '../../layouts/top_bar/TitleBar'; // TopBar import 추가
import SmallTab from '../../components/Tabs/SmallTab';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import BasicImageSwiperGridSection from '../../sections/grid/BasicImageSwiperGridSection';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

const Product04Section = () => {
  const navigate = useNavigate();
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const location = useLocation();
  const [activeSmallTab, setActiveSmallTab] = useState("all");
  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const { Page2depth } = useParams();
  const [sortOption, setSortOption] = useState('latest'); //  "최신순"
  const focusRef = useRef(null);
  
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const handleSmallTabClick = (key) => {
    setActiveSmallTab(key);
    // 여기에 클릭 시 추가 로직이 필요하면 추가 가능
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const focusId = queryParams.get('focus');

    // 페이지 데이터가 로드된 후에 비교
    if (pageData.length > 0 && focusId) {
      const focusedPage = pageData.find(page => page.node.id === focusId);
      if (focusedPage) {
        // 일치하는 페이지가 있으면 해당 요소로 스크롤
        focusRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [pageData, location.search]);

  useEffect(() => {
    const filter = {
      pageTypes: "UGFnZVR5cGU6MjA=", // 문의 페이지타입
      metadata: [{ key: "product_id", value: Page2depth }] // 선택한 상품만 선택
    };

    setIsLoading(true); // 로딩 시작

    fetchPages({ filter, first: 10, sortOption }).then((res) => {
      console.log('Fetched pages:', res.data.pages.edges);
      const { edges, pageInfo } = res.data.pages;
      setPageData(edges);
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);
    }).catch(error => {
      console.error('Error fetching pages:', error);
    }).finally(() => {
      setIsLoading(false); // 로딩 완료
    });
  }, [Page2depth, sortOption]);

  const loadMoreInquiries = () => {
    if (!hasNextPage || isMoreLoading) return; // 중복 요청 방지

    setIsMoreLoading(true); // 더 불러오는 중 상태
    const filter = {
      pageTypes: "UGFnZVR5cGU6MjA=",
      metadata: [{ key: "product_id", value: Page2depth }]
    };

    fetchPages({ filter, first: 10, after: endCursor, sortOption }).then((res) => {
      const { edges, pageInfo } = res.data.pages;
      setPageData((prevData) => [...prevData, ...edges]);
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);

    }).catch(error => {
      console.error('Error fetching more inquiries:', error);
    }).finally(() => {
      setIsMoreLoading(false); // 로딩 완료
    });
  };


  return (
    <>
      <section className="section-product-info">
        <div className="pl16 pr16 pb16 pt20">
          <div className="h4-class-wrap flex between mb20">
            <h4 className="title">
              {getTranslatedNameById("TWVudUl0ZW06NDI1")} {/* "Inquiry" */} <b className="f-purple">{pageData.length}</b>
            </h4>
            <Link to={`/question-write/${Page2depth}`}>
              <b className="f14 f-purple a-more-btn">{getTranslatedNameById("TWVudUl0ZW06NDI2")}</b> {/* "Write an inquiry" */}
            </Link>
          </div>
        </div>

        {isLoading ? (
          <div className="loading-wrap">
            <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
        ) : (
          pageData.map((page, index) => (
            <div className={`card review2 ${page.node.id === new URLSearchParams(location.search).get('focus') ? 'highlight' : ''}`}
              key={index}
              ref={page.node.id === new URLSearchParams(location.search).get('focus') ? focusRef : null}>
              <div className="profile-wrap">
                <div className="img-wrap">
                  <img
                    src={page?.node?.writer?.metafields?.profileImg || sampleImage}
                    alt="Profile"
                  />
                </div>
                <strong className="f14">
                  {page?.node?.writer?.metafields?.nickName?.length > 2
                    ? `${page?.node?.writer?.metafields?.nickName.substring(0, 2)}***`
                    : page?.node?.writer?.metafields?.nickName || 'Unknown'}
                </strong>

                <span
                  className={!page?.node?.metafields?.answer
                    ? "status"
                    : "answer-status" }
                >
                  {!page?.node?.metafields?.answer
                    ? getTranslatedNameById("TWVudUl0ZW06NDI3") // "Waiting for a response"
                    : getTranslatedNameById("TWVudUl0ZW06NDI4") // "Response completed"
                  }
                </span>
                <span className="date">
                  {page?.node?.created
                    ? new Date(page.node.created).toLocaleDateString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }).replace(/\./g, '. ').replace(/\.\s?$/, '')  // 마지막 .을 제거
                    : "Unknown date"}
                </span>
              </div>
              <div className="review mt10">
                <div className="mb10">
                  <BasicImageSwiperGridSection photos={page?.node?.media?.map((mediaItem) => mediaItem.pageImgUrl) || []} />
                </div>
                <p className="f13">
                  {page?.node?.title || "No title available"}
                </p>
              </div>
              <div className="card answer flex between mt10">
                <p className="f13 ">
                  {page?.node?.metafields?.answer || getTranslatedNameById("TWVudUl0ZW06NDI5")} {/* "Awaiting response..." */}
                </p>
                <p className="f13">
                  {page?.node?.metafields?.answer_date
                    ? new Date(page.node.metafields.answer_date).toLocaleDateString().replace(/\.$/, '')
                    : ""}
                </p>
              </div>
            </div>
          ))
        )}

        {/* 더 많은 문의 불러오기 버튼 */}
        {hasNextPage && pageData.length > 0 && !isLoading && (
          <div className="white-wrap mt20">
            <button
              type="button"
              className={isMoreLoading ? "btn big round more f14 active" : "btn big round more f14"}
              onClick={loadMoreInquiries}
            >
              {getTranslatedNameById("TWVudUl0ZW06NDMw")} {/* "View more inquiries" */}
            </button>
          </div>
        )}
      </section>
    </>
  );
};

export default Product04Section;
