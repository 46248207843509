import React from "react";
import { Link, useNavigate } from "react-router-dom";

import SampleImage from "../../assets/images/sample/banner.png";
import { useLanguage } from '../../services/languageContext';

const MyMenuReturnDetailSection = () => {

  const navigate = useNavigate();
  const { getTranslatedNameById, language } = useLanguage();

  return (
    <>
      <section className="section-mymenu-exchange-detail">
        <div className="order-card selected">
          <div className="title-wrap">
            <strong className="title">반품완료 6/9(금)</strong>
            <Link to="/mymenu/order-detail" className="a-more-btn" title="">{getTranslatedNameById("TWVudUl0ZW06NDEz")} {/* "주문 상세보기" -> "View Order Details" */}</Link>
          </div>
          <div className="item-info-wrap">
            <div className="img-wrap">
              <img src={SampleImage} alt=""></img>
            </div>
            <div className="text-wrap">
              <p className="date">2023-12-28</p>
              <p className="brand">베리클로젯</p>
              <p className="name">스타일 레더 패딩 (겨울)</p>
              <p className="price">72,900 / 1개</p>
            </div>
          </div>
          <div className="btn-wrap mt16">
            <button 
              type="button" 
              className="btn purple10"
              onClick={()=>navigate('/pay/shopping')}
            >
              장바구니 담기
            </button>
          </div>
        </div>
        <div className="white-wrap">
          <h4 className="title">
            반품 사유
          </h4>
          <div className="text-wrap">
            <span>상품이 파손되어 배송됨</span>
          </div>
        </div>
        <div className="white-wrap">
          <h4 className="title">
            환불 정보
          </h4>
          <ul className="order-infos">
            <li className="info"><strong>총 주문금액</strong><span>109,000원</span></li>
            <li className="info"><strong>총 배송비</strong><span>109,000원</span></li>
            <li className="info"><strong>총 즉시할인금액</strong><span>109,000원</span></li>
            <li className="info"><strong>총 쿠폰할인금액</strong><span>109,000원</span></li>
            <li className="info"><strong>총 사용적립금</strong><span>109,000원</span></li>
            <li className="info"><strong>총 환불예상금액</strong><span>109,000원</span></li>
            <li className="info"><strong>환불수단</strong><span>롯데카드 / 일시불 88,600원</span></li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default MyMenuReturnDetailSection;

