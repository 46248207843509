import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { 
  fetchUserVouchers
} from '../../../../services/GQ_apis/discounts';
import { getCheckout } from '../../../../services/GQ_apis/checkout';
import useAuthStore from '../../../../store/common/useAuthStore';
import { addPromoCodeToCheckout } from '../../../../services/GQ_apis/discounts';
import usePopupStore from '../../../../store/common/usePopupStore';
import { useLanguage } from '../../../../services/languageContext';

const Coupon1 = ({closePopup, checkoutId, productsIds}) => {

  const { user, observerUpdate } = useAuthStore();
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const { getTranslatedNameById, language } = useLanguage(); 

  const [couponList, setCouponList] = useState([]);
  const [couponChannel, setCouponChannel] = useState('ko');

  useEffect(()=>{
    console.log('checkoutId', checkoutId);
    fetchUserVouchers(user.email,productsIds).then((res)=>{
      console.log('vouchers');
      console.log(res.data.TGUserVouchers);
      setCouponList(res.data.TGUserVouchers);
      console.log('vouchers');
    })
    console.log(user);
  },[checkoutId])

  const useCoupon = (voucherCode) => {
    getCheckout(checkoutId).then((res) => {

      console.log('------현재 체크아웃 정보------');
      console.log(res.data);
      console.log('------현재 체크아웃 정보------');

      addPromoCodeToCheckout(
        res.data.checkout.id,
        voucherCode
      ).then((res) => {
        console.log('쿠폰 사용 ');
        console.log(res);
        console.log('쿠폰 사용 ');
        closePopup();
        observerUpdate();
        openSnackBarPopup(language === 'KO' ? '정상적으로 쿠폰이 적용되었습니다.' : 'The coupon was successfully applied.', '', '');
      });
    });
  };

  const channelListing = (channelListings, channel) => {
    if (!channelListings) return null;
    if (channel === 'ko') {
      return channelListings.find((item) => item.currency === 'KRW');
    } else if (channel === 'en') {
      return channelListings.find((item) => item.currency === 'USD');
    }
    return null;
  };

  return (
    <>
      <div className="popup-body">
        <div className="section-coupons">
          <div className="inner w100p">
            <strong className="title">
              {getTranslatedNameById("TWVudUl0ZW06NjIx")} {/* 사용할 수 있는 쿠폰 */} 
              {language === 'KO' ? (<><b className="f-purple">{couponList.length}개</b></>
              ) : (
                <><b className="f-purple">{couponList.length}</b></>
              )}
            </strong>
            <ul className="coupons-list">
              {
                couponList.length > 0 ? couponList.map((coupon, index) => (
                  <li className="card coupon" key={index}>
                    <div className="top-wrap">
                      <strong className="f-purple">
                        {
                          coupon?.voucher?.discountValueType === 'FIXED' ? 
                          <>
                            -{window.WSformatPrice(channelListing(coupon?.channelListings, couponChannel)?.discountValue)}{window.WSCurrency(channelListing(coupon.channelListings, couponChannel)?.currency)}
                          </> 
                          : 
                          <>
                            {channelListing(coupon.channelListings, couponChannel)?.discountValue}%
                          </>
                        }
                      </strong>
                      <button 
                        type="button" 
                        className="btn white round"
                        onClick={() => useCoupon(coupon.voucher.code)}
                      >
                        <span className="icon-coupon white"></span>
                        {language === 'KO' ? '사용하기' : 'Use'}
                      </button>
                    </div>
                    <div className="bottom-wrap">
                      <strong className="title">{coupon.voucher.name}</strong>
                      <strong className="d-day">
                        {language === 'KO' ? '만료까지 D-3' : 'Expires in D-3'}
                        <span className="caption">{language === 'KO' ? '10,000원 이상 구매 시 사용 가능' : 'Available on purchases over 10,000 KRW'}</span>
                      </strong>
                    </div>
                  </li>
                )) : (
                  <>{language === 'KO' ? '사용할 수 있는 쿠폰이 없습니다.' : 'No available coupons.'}</>
                )
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button type="button" className="btn purple" onClick={closePopup}>
            {language === 'KO' ? '취소' : 'Cancel'}
          </button>
        </div>
      </div>
    </>
  );
};

export default Coupon1;
