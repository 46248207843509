import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../../services/languageContext'; 

const PopupKidNext = ({ closePopup }) => {
  const navigate = useNavigate();
  const { getTranslatedNameById, language } = useLanguage(); 

  return (
    <>
      <div className="popup-body">
        <div className="h3-title-wrap">
          <h3 className="title">
            {getTranslatedNameById('TWVudUl0ZW06NDcy')}{/* 알림 */}
          </h3>
          <p className="mt16 f16">
            {language === 'KO' ? ( <>내 아이 프로필을 다음에 등록할까요?</>) : (<>Would you like to register your child's profile later?</>)}
          </p>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button 
            type="button" 
            className="btn gray w280" 
            onClick={() => {
              closePopup();
              navigate('/');
            }}
          >
            {language === 'KO' ? ( <>다음에 하기</>) : (<>Do it later</>)}
          </button>
          <button 
            type="button" 
            className="btn purple" 
            onClick={closePopup}
          >
            {language === 'KO' ? ( <>계속하기</>) : (<>Continue</>)}
          </button>
        </div>
      </div>
    </>
  );
};

export default PopupKidNext;
