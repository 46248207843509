import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { meData, meDefaultAddress } from '../../services/GQ_apis/me';
import { getCheckout, updateShippingMethod, updateCheckoutAddress } from '../../services/GQ_apis/checkout';
import { updateBillingAddress } from '../../services/GQ_apis/address';
import EasyPayCheckoutPage from "../../pages/pay/EasyPayCheckoutPage"; // 이지페이 결제 체크아웃
import usePopupStore from '../../store/common/usePopupStore';
import useAuthStore from '../../store/common/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../services/languageContext';

const PayProcessSection = () => {

  const navigate = useNavigate();

  const { openSnackBarPopup, openToastPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
    openToastPopup: state.openToastPopup
  }));

  const { globalObserver } = useAuthStore();
  const [isOrderOpen, setIsOrderOpen] = useState({});
  const [tabList, setTabList] = useState([]);
  const [addressActiveTab, setAddressActiveTab] = useState({});
  const [checkouts, setCheckouts] = useState({});
  const [orderLists, setOrderLists] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [checkoutPromises, setCheckoutPromises] = useState([]);
  const [message, setMessage] = useState('');
  const [shippingMethod, setShippingMethod] = useState(false);
  const { getTranslatedNameById, language } = useLanguage();

  const [usdToKrw, setUsdToKrw] = useState(1351.35);

  const newAddress = useMemo(() => ({
    city: addressActiveTab?.city,
    cityArea: addressActiveTab?.cityArea,
    firstName: addressActiveTab?.firstName,
    phone: addressActiveTab?.phone,
    country: addressActiveTab?.country?.code,
    countryArea: addressActiveTab?.countryArea,
    postalCode: addressActiveTab?.postalCode,
    streetAddress1: addressActiveTab?.streetAddress1,
    streetAddress2: addressActiveTab?.streetAddress2,
    metadata: addressActiveTab?.metadata?.map(item => ({
      key: item.key,
      value: item.value
    }))
  }), [addressActiveTab]);

  const setAddress = (isFirst = false) => {

    // 1. 배송지 선택 [START]---------------------
    meDefaultAddress().then((res) => {
      setTabList(res.data.me.addresses);
      if (!isFirst) return;
      const defaultShippingAddress = res?.data?.me?.defaultShippingAddress;
      console.log('defaultShippingAddress :', defaultShippingAddress);
      if (defaultShippingAddress) {
        setAddressActiveTab(defaultShippingAddress);
      } else {
        openSnackBarPopup(language === 'KO' ? '기본 배송지를 먼저 등록해주세요.' : 'Please register a default shipping address first.', '', 'error');
        navigate('/delivery/list?from-order');
      }
    });

    if (addressActiveTab?.metadata?.length > 0 && addressActiveTab?.metadata[2]?.value) setMessage(addressActiveTab?.metadata[2]?.value);
    // 배송지 선택 [END]--------------------------

  };

  const fetchCheckout = () => {

    if (!addressActiveTab.city) return;

    const vendorIds = Object.keys(localStorage).filter(key => key.startsWith('paymentToken_'));

    const checkoutList = vendorIds.map(vendorKey => {
      const paymentToken = localStorage.getItem(vendorKey);

      updateCheckoutAddress(paymentToken, newAddress).then((res) => {
        console.log('newAddress :', newAddress);
        console.log('updateCheckoutAddress ', res);
        updateBillingAddress(paymentToken, newAddress);
      });

      return getCheckout(paymentToken).then(res => {
        const checkoutData = res.data.checkout;
        console.log('Vendor : ', vendorKey, ',checkoutData :', res);
        if (res?.data?.checkout?.deliveryMethod) {
          console.log('res.data.checkout.deliveryMethod', res.data.checkout.deliveryMethod);
          setIsLoading(false);
        }
        return {
          checkout: checkoutData,
          orderList: checkoutData?.lines,
          vendorId: vendorKey.split('paymentToken_')[1],
        };
      });

    });
    setCheckoutPromises(checkoutList);
  };

  const fetchCheckoutLines = () => {

    if (!addressActiveTab.city) return;

    Promise.all(checkoutPromises).then(allCheckouts => {
      const updatedCheckouts = {};
      const updatedOrderLists = {};

      allCheckouts.forEach(({ checkout, orderList, vendorId }) => {
        updatedCheckouts[vendorId] = checkout;
        updatedOrderLists[vendorId] = orderList;

        console.log('before updateShippingMethod');
        if (checkout?.availableShippingMethods?.length > 0 && !shippingMethod) {
          updateShippingMethod(checkout.id, checkout.availableShippingMethods[0].id).then((res) => {
            console.log('after updateShippingMethod', res);
            if (!checkout.deliveryMethod) {
              fetchCheckout();
            }
          });
        } else {
          fetchCheckout();
        }
      });
      setCheckouts(updatedCheckouts);
      setOrderLists(updatedOrderLists);

      // console.log('orderList',updatedOrderLists);

    }).catch(error => {
      console.error('Error fetching checkout information:', error);
    });
  };

  // 토글 버튼 클릭 핸들러
  const handleToggle = (vendorId) => {
    setIsOrderOpen(prevState => ({
      ...prevState,
      [vendorId]: !prevState[vendorId] // 해당 vendorId의 상태를 반전시킴
    }));
  };

  const calculateTotals = () => {
    let totalOrderAmount = 0;
    let totalShippingAmount = 0;
    let couponDiscountAmount = 0;
    let promotionDiscountAmount = 0;
    let totalDiscountAmount = 0;
    let totalPaymentAmount = 0;

    Object.values(checkouts).forEach(checkout => {
      totalOrderAmount += checkout.subtotalPrice.gross.amount + checkout.discount.amount;
      totalShippingAmount += checkout.shippingPrice.gross.amount;
      totalDiscountAmount += checkout.totalDiscount.amount;
      couponDiscountAmount += checkout.discount.amount;
      promotionDiscountAmount += checkout.promotionDiscount.amount;
      totalPaymentAmount += checkout.totalPrice.gross.amount;
    });

    return {
      totalOrderAmount,
      totalShippingAmount,
      totalDiscountAmount,
      couponDiscountAmount,
      promotionDiscountAmount,
      totalPaymentAmount,
    };
  };

  const totals = calculateTotals();

  // 배송지 변경하기 [START]
  const changeAddress = (tab) => {
    if (!tab || !addressActiveTab?.country || !addressActiveTab?.metadata) return;
    setAddressActiveTab(tab);
    setShippingMethod(false);
  };

  // 1. init
  useEffect(() => {
    console.log('[INIT START]');
    setAddress(true);
  }, [])

  useEffect(() => {
    fetchCheckout();
  }, [newAddress]);

  // 쿠폰 감지 기능
  useEffect(() => {
    fetchCheckout();
    fetchCheckoutLines();
  }, [globalObserver]);


  useEffect(() => {
    fetchCheckoutLines();
  }, [checkoutPromises]);

  if (Object.keys(orderLists).length === 0 || isLoading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  const payDataArray = Object.values(checkouts);

  return (
    <>
      <section className="section-pay-process">
        <div className="white-wrap">
          <div className="h4-title-wrap">
            <h4 className="title">{language === 'KO' ? '배송지' : 'Shipping Address'}</h4>
            <Link to="/delivery/list?from-order" title="" className="a-more-btn">
              {language === 'KO' ? '배송지 관리' : 'Manage Address'}
            </Link>
          </div>
          <div className="location-tabs pt14">
            <ul className="tabs">
              {tabList.map((tab) => (
                <li key={tab.id}>
                  <button
                    type="button"
                    className={`tab ${addressActiveTab.id === tab.id ? "active" : ""}`}
                    onClick={() => changeAddress(tab)}
                  >
                    {tab?.metadata[0].value}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {addressActiveTab ? (
            <div className="address-wrap">
              <strong className="user-info">
                <span className="name">{addressActiveTab.firstName}</span>
                {addressActiveTab.phone}
                {addressActiveTab.isDefaultShippingAddress && (
                  <span className="badge white">{language === 'KO' ? '기본배송지' : 'Default Shipping Address'}</span>
                )}
              </strong>
              <p className="address">{addressActiveTab.streetAddress1} {addressActiveTab.streetAddress2}</p>
              <div className="input-box">
                <div className="input">
                  <input
                    type="text"
                    placeholder={language === 'KO' ? '배송 메모가 없습니다.' : 'No shipping memo.'}
                    value={message}
                    disabled
                  >
                  </input>
                </div>
              </div>
            </div>
          ) : (
            <div>{language === 'KO' ? '기본 배송지를 등록해주세요.' : 'Please register a default shipping address.'}</div>
          )}
        </div>

        {Object.keys(orderLists).map(vendorId => (
          <>
            <div key={vendorId} className={isOrderOpen[vendorId] ? "white-wrap toggle-wrap active" : "white-wrap toggle-wrap"}>
              <div className="h4-title-wrap mb10">
                {orderLists[vendorId].length > 0 &&
                  <h4 className="title mb0">{orderLists[vendorId][0].variant.product.vendor.storeNameKo}</h4>
                }
                <button
                  type="button"
                  title={language === 'KO' ? '토글버튼' : 'Toggle Button'}
                  className="toggle big active"
                  onClick={() => handleToggle(vendorId)}
                >
                  <span className="icon-under"></span>
                </button>
              </div>
              <ul className="shopping-list">
                {orderLists[vendorId].map((order) => (
                  <li className="card shopping" key={order.id}>
                    <div className="img-wrap">
                      <img
                        src={order.variant.product.thumbnail.url}
                        alt={order.variant.product.thumbnail.alt}
                      />
                    </div>
                    <div className="info-wrap">
                      <div className="order-number">
                        {order.id}
                      </div>
                      <div className="title">
                        {order.variant.product.name}
                      </div>
                      <div className="price-wrap">
                        {order.undiscountedTotalPrice.amount !== order.totalPrice.gross.amount && (
                          <div className="sale-price">
                            <b className="f-purple f10 mr4">Sale</b>
                            <del className="f10 f-black50">{window.WSformatPrice(order.undiscountedTotalPrice.amount)}{language === 'KO' ? "원" : "$"}</del>
                          </div>
                        )}
                        <div className="now-price">
                          {window.WSformatPrice(order.totalPrice.gross.amount)}{language === 'KO' ? "원" : "$"}
                        </div>
                        <div className="after-price">
                          {order.variant.name} / {order.variant?.attributes[0]?.values[0]?.name} / {language === 'KO' ? '수량' : 'Quantity'}: {order.quantity}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="white-wrap">
              <div className="h4-title-wrap">
                <h4 className="title">
                  {language === 'KO' ? '쿠폰' : 'Coupon'}
                </h4>
              </div>
              <div className="input-box">
                <label htmlFor="input1" className="hide">
                  {language === 'KO' ? '쿠폰 조회' : 'Coupon Lookup'}
                </label>
                <div className="input">
                  <input
                    id="input1"
                    type="text"
                    value={totals.couponDiscountAmount === 0 ? '0' : `- ${window.WSformatPrice(totals.couponDiscountAmount)}`}
                    readOnly
                  />
                  <button
                    type="button"
                    className="btn purple"
                    onClick={() => {
                      const productIds = orderLists[vendorId].map(order => order.variant.product.id);
                      openToastPopup('쿠폰조회', checkouts[vendorId].token, productIds);
                    }}
                  >
                    {language === 'KO' ? '쿠폰 조회' : 'Coupon Lookup'}
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="input-box">
              <label htmlFor="input2" className="hide">모두 사용</label>
              <div className="input type2">
                <input id="input2" type="number" value="0" readOnly />
                <button type="button" className="btn purple" disabled>모두 사용</button>
              </div>
            </div>
            <span className="f-purple f14 a-right mt10">보유 적립금 : 0</span> */}
          </>
        ))}
        {/* 결제 페이지: 모든 브랜드의 데이터를 배열로 전달 */}
        <EasyPayCheckoutPage payData={payDataArray} />
        <div className="white-wrap pb10">
          <div className="h4-title-wrap border">
            <h4 className="title">{language === 'KO' ? '결제 금액' : 'Payment Amount'}</h4>
          </div>
          <ul className="order-list">
            <li><span>{language === 'KO' ? '총 주문 금액' : 'Total Order Amount'}</span><b>{window.WSformatPrice(totals.totalOrderAmount)}{language === 'KO' ? "원" : "$"}</b></li>
            <li><span>{language === 'KO' ? '총 배송비' : 'Total Shipping Cost'}</span><b>{window.WSformatPrice(totals.totalShippingAmount)}{language === 'KO' ? "원" : "$"}</b></li>
            <li><span>{language === 'KO' ? '총 할인 금액' : 'Total Discount Amount'}{language === 'KO' ? "원" : "$"}</span>
              <b>
                {totals.totalDiscountAmount === 0 ? '0' : `- ${window.WSformatPrice(totals.totalDiscountAmount)}`}{language === 'KO' ? "원" : "$"}
              </b>
            </li>
            {
              window.WSgetChannel() == 'KO' &&
              <>
                <li>
                  <span>총 결제 금액</span><b className="f-purple f16">
                    {window.WSformatPrice(totals.totalPaymentAmount - totals.promotionDiscountAmount)}{language === 'KO' ? "원" : "$"}
                  </b>
                </li>
              </>
            }
            {
              window.WSgetChannel() == 'EN' &&
              <>
                <li>
                  <span>*현재 USD/KRW 환율:</span> <b>1$ = {usdToKrw ? usdToKrw : "불러오는 중..."}₩</b>  {/* 환율 표시 */}
                </li>
                <li>
                  <span>총 결제 금액</span><b className="f-purple f16">
                    {window.WSformatPrice(totals.totalPaymentAmount)}$ x {usdToKrw} = {window.WSformatPrice(parseInt(totals.totalPaymentAmount * usdToKrw))}₩
                  </b>
                </li>
              </>
            }
          </ul>
        </div>
      </section>
    </>
  );
};

export default PayProcessSection;
