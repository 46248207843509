import React, { useEffect } from 'react';
import LogoImage from "../../assets/images/common/kistore_logo.svg";
import { useNavigate, Link } from 'react-router-dom';

const BizPage = () => {
  const navigate = useNavigate();

  const routeBiz = (channel) => {
    window.WSsetChannel(channel)
    navigate('/globalbiz')
  };

  useEffect(()=>{
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('checkoutToken_')) {
        localStorage.removeItem(key);
      }
    });
  },[])

  return (
    <article id="BizPage" className="biz-page">
      <section className="logo-section">
        <Link to="/" title="zaezae 메인으로 이동">
          <div className="logo mt102 mb70">
            <img src={LogoImage} alt="zaezae" />
          </div>
        </Link>
      </section>

      <section className="welcome-section mb6">
        <h3>Welcome!</h3>
      </section>

      <section className="info-section">
        <h2 className='biz-title mb24'>BIZ</h2>
      </section>

      <section className="button-section">
        <a title="globalbiz 페이지로 이동" onClick={() => routeBiz('EN')}>
          <button className="global-button bg-white mr20" aria-label="Global">
            GLOBAL
          </button>
        </a>
        <a title="globalbiz 페이지로 이동" onClick={() => routeBiz('KO')}>
          <button className="korea-button bg-white" aria-label="Korea">
            KOREA
          </button>
        </a>
      </section>
    </article>
  );
};

export default BizPage;
