import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { orderRequestExchange, orderAddNote, fetchOrderById } from '../../services/GQ_apis/order';
import usePopupStore from '../../store/common/usePopupStore';
import { useLanguage } from '../../services/languageContext';

const OrderRequestExchangeSection = () => {

  const navigate = useNavigate();
  const { Page3depth } = useParams();
  const [selectedReason, setSelectedReason] = useState(""); // 취소 사유를 저장할 상태
  const [otherReason, setOtherReason] = useState(""); // '기타 사유' 입력값을 저장할 상태

  const [lines, setLines] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({});

  const [selectedLines, setSelectedLines] = useState([]);
  const { getTranslatedNameById, language } = useLanguage();
  
  const { openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  useEffect(() => {
    fetchOrderById(Page3depth).then((res) => {
      setLines(res.data.order.lines);
      setShippingAddress(res.data.order.shippingAddress);
  
      const initialSelectedLines = res.data.order.lines.map(item => ({
        id: item.id,
        productName: item.productName,
        quantity: 0  // 초기 수량을 0으로 설정
      }));
      setSelectedLines(initialSelectedLines);
    })
  }, [Page3depth]);

  // 수량 변경 핸들러
  const handleQuantityChange = (productId, newQuantity) => {
    const updatedSelectedLines = selectedLines.map(item =>
      item.id === productId ? { ...item, quantity: newQuantity } : item
    );
    setSelectedLines(updatedSelectedLines);
  };

  // 사유 선택 핸들러
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
  };

  // '기타 사유' 입력값 변경 핸들러
  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };
  
  const textReason = useMemo(() => {
    if (selectedReason !== 'etc') {
      return selectedReason;
    } else { 
      return otherReason;
    }
  }, [selectedReason, otherReason]);

  const confirmOrderExchange = () => {
    if (!Page3depth || !textReason) {
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTY4"), '', ''); // "교환 사유를 선택해주세요."
      return;
    }

    const formatSelectedLines = (lines) => {
      return lines.map(line => `상품명 : ${line.productName}, 수량: ${line.quantity}`).join('\n');
    };

    const formattedLines = formatSelectedLines(selectedLines);
    const message = `교환 사유 : ${textReason}, ${formattedLines}`;
    
    orderRequestExchange(Page3depth, textReason).then((res1) => {
      navigate('/order/exchange-list');
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NTY5"), '', ''); // "교환 요청을 보냈습니다."
      orderAddNote(Page3depth, message).then((res2) => {
        console.log('res', res2);
      });
    });
  };

  return (
    <>
      <section className="section-pay-shopping">
        <div className="white-wrap shopping-list-wrap">
          <h4 className="title mb10">{getTranslatedNameById("TWVudUl0ZW06NTcw")}</h4> {/* "교환하실 상품의 수량을 입력해주세요." */}
          {
            lines.length > 0 ? 
            <ul className="shopping-list">
              {lines.map((item, index) => (
                <li key={index}>
                  <div className="card shopping">
                    <div className="img-wrap">
                      <img src={item.thumbnail.url} alt={item.thumbnail.alt} />
                    </div>
                    <div className="info-wrap">
                      <div className="title">
                        {item.productName}
                      </div>
                      <div className="price-wrap">
                        <div className="now-price">
                          {window.WSformatPrice(item.totalPrice.gross.amount)}
                        </div>
                      </div>
                      <div className="variant-wrap mt10">
                        <div>
                            <input 
                              type="number" 
                              className="w40 a-center" 
                              onInput={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                            ></input> / max : {item.quantity}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            :
            <>
              <div className="loading-wrap flex center">
                <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
              </div>
            </>
          }
        </div>
      </section>
      <section className="section-mymenu-request-exchange">

        <div className="white-wrap pt16">
          <h4 className="title mb10">{getTranslatedNameById("TWVudUl0ZW06NTY4")}</h4> {/* "교환 사유를 선택해주세요." */}
          <div className="input-box">
            <ul className="input-list">
              <li className="input radio">
                <label htmlFor="radio1">
                  <input 
                    id="radio1" 
                    name="radio" 
                    type="radio"
                    value={getTranslatedNameById("TWVudUl0ZW06NTcx")} 
                    onChange={handleReasonChange}
                  ></input> {getTranslatedNameById("TWVudUl0ZW06NTcx")} {/* "상품이 마음에 들지 않음 (단순변심)" */}
                </label>
              </li>
              <li className="input radio">
                <label htmlFor="radio2">
                  <input 
                    id="radio2" 
                    name="radio" 
                    type="radio"
                    value={getTranslatedNameById("TWVudUl0ZW06NTcy")} 
                    onChange={handleReasonChange}
                  ></input> {getTranslatedNameById("TWVudUl0ZW06NTcy")} {/* "다른 상품 추가 후 재주문 예정" */}
                </label>
              </li>
              <li className="input radio etc">
                <label htmlFor="radio3">
                  <input 
                    id="radio3" 
                    name="radio" 
                    type="radio"
                    value="etc" 
                    onChange={handleReasonChange}
                  ></input> {getTranslatedNameById("TWVudUl0ZW06NTcz")} {/* "기타 사유" */}
                </label>
                <textarea 
                  disabled={selectedReason !== "etc"}
                  placeholder={getTranslatedNameById("TWVudUl0ZW06NTc0")} // "상세 사유를 입력하세요"
                  value={otherReason}
                  onChange={handleOtherReasonChange}
                ></textarea>
              </li>
            </ul>
          </div>
        </div>
        <div className="white-wrap">
          <div className="common-tab-wrap">
            <p className="f13 f-purple mb16">{getTranslatedNameById("TWVudUl0ZW06NTc1")}</p> {/* "수거지주소는 배송지주소와 동일합니다." */}
            <div className="tabs">  
              <button type="button" className="tab">
                {getTranslatedNameById("TWVudUl0ZW06NTc2")} {/* "수거지 선택" */}
              </button>
              <button type="button" className="tab active">
                {getTranslatedNameById("TWVudUl0ZW06NTc3")} {/* "배송지 정보 통일" */}
              </button>
              <button type="button" className="tab">
                {getTranslatedNameById("TWVudUl0ZW06NTc4")} {/* "수거지 변경" */}
              </button>
            </div>
            <ul className="contents">
              <li className="content">
                <div className="text-wrap">
                  <strong className="title">
                    {getTranslatedNameById("TWVudUl0ZW06NTc5")} {/* "수거지 주소" */}
                  </strong>
                  <p className="text">
                    <span>{shippingAddress.firstName}</span>
                    <span>({shippingAddress.postalCode}) {shippingAddress.streetAddress1}</span>
                    <span>{shippingAddress.streetAddress2}</span>
                    <span className="f-black60">{shippingAddress.phone}</span>
                  </p>
                </div>
                <div className="text-wrap">
                  <strong className="title">
                    {getTranslatedNameById("TWVudUl0ZW06NTgw")} {/* "배송비 안내" */}
                  </strong>
                  <p className="text">
                    <span>{getTranslatedNameById("TWVudUl0ZW06NTgx")}</span> {/* "취소/교환/반품하시는 상품 및 신청사유에 따라..." */}
                    <span>{getTranslatedNameById("TWVudUl0ZW06NTgy")}</span> {/* "배송비는 쇼핑몰 정책에 따라 책정됩니다." */}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* 교환 요청 버튼 [START]*/}
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button"
              onClick={() => confirmOrderExchange()}
              disabled={!selectedReason}
              className="btn purple big"
            >
              {getTranslatedNameById("TWVudUl0ZW06NTgz")} {/* "교환 요청" */}
            </button>
          </div>
        </div>
      </div>
      {/* 교환 요청 버튼 [END]*/}

    </>
  );
};

export default OrderRequestExchangeSection;
