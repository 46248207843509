import React, { useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom';
import TopBar from '../../layouts/top_bar/TitleBar';
import { createPage, updateMetadata, createPageMedia } from "../../services/GQ_apis/page"; 
import { useNavigate } from 'react-router-dom'; 
import { meData } from "../../services/GQ_apis/me";
import useProductStore from '../../store/common/useProductStore';
import ImageSwipeGridSection from '../../sections/grid/ImageSwipeGridSection';
import usePopupStore from '../../store/common/usePopupStore';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

function QuestionPage() {
  const { Page2depth } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState(""); // 문의 내용 상태
  const [me, setMe] = useState({});
  const [photoFiles, setPhotoFiles] = useState([]); // 파일 업로드를 위한 파일 객체 상태
  const [photoURLs, setPhotoURLs] = useState([]); // 이미지 미리보기 URL 상태
  const fileInputRef = useRef(null); // 파일 입력 요소를 위한 ref
  const { nowProductData } = useProductStore((state) => ({ nowProductData: state.nowProductData }));

  const { openToastPopup, openSnackBarPopup } = usePopupStore((state) => ({
    openSnackBarPopup: state.openSnackBarPopup,
    openToastPopup: state.openToastPopup,
  }));

  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  // 문의 내용을 처리하는 함수
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  // 타임스탬프를 원하는 형식으로 변환하는 함수
  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    let dateString = date.toLocaleDateString('ko-KR', options);
    if (dateString.endsWith('.')) {
      dateString = dateString.slice(0, -1);
    }
    return dateString;
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const readFiles = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ file, url: e.target.result });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });
    Promise.all(readFiles).then((results) => {
      const newPhotoFiles = results.map(result => result.file);
      const newPhotoURLs = results.map(result => result.url);
      setPhotoURLs(prev => [...prev, ...newPhotoURLs]);
      setPhotoFiles(prev => [...prev, ...newPhotoFiles]);
    }).catch((error) => {
      console.error("Error reading files:", error);
    });
  };

  const handleAttachPhotoClick = () => {
    fileInputRef.current.click();
  };

  const removePhoto = (index) => {
    setPhotoFiles(photoFiles.filter((_, i) => i !== index));
    setPhotoURLs(photoURLs.filter((_, i) => i !== index));
  };

  const handleInquiryCompletion = () => {
    navigate(`/product/${Page2depth}`, {
      state: { activeTab: 'inquiry' } 
    });
  };

  const formattedDate = convertTimestampToDate(nowProductData.created);

  useEffect(() => {
    meData().then((res) => {
      setMe(res.data.me);
    });
  }, []);

  const pageTypeId = 'UGFnZVR5cGU6MjA='; // 문의
  const publicationDate = new Date().toISOString().split('T')[0];

  const saveQuestion = () => {
    createPage(
      title, 
      pageTypeId,
      publicationDate,
    )
    .then((res) => {
      const pageId = res.data.pageCreate.page.id;
      const metadata = [
        { key: "product_id", value: Page2depth },
        { key: "publication_date", value: publicationDate },
        { key: "answer", value: "" },
        { key: "ZZ_VID", value: nowProductData.vendor.id },
        { key: "ZZ_UID", value: me.id },
      ];
      if (photoFiles.length > 0) {
        const mediaUploadPromises = photoFiles.map((file) =>
          createPageMedia(Page2depth, pageId, file)
        );
        return Promise.all(mediaUploadPromises).then((mediaRes) => {
          console.log('All media uploaded:', mediaRes);
          return updateMetadata(pageId, metadata);
        });
      } else {
        return updateMetadata(pageId, metadata);
      }
    })

    .then((finalRes) => {
      openSnackBarPopup(getTranslatedNameById("TWVudUl0ZW06NDMx"), '', ''); // "문의가 접수되었습니다. 확인 후 신속하게 답변드리겠습니다"
      console.log('Media creation complete:', finalRes);
      handleInquiryCompletion();
    })
    .catch((error) => {
      console.error("Error creating page:", error);
      if (error.message.includes("PermissionDenied")) {
        alert("You do not have sufficient permissions to create a page.");
      } else {
        alert("An error occurred while creating the page.");
      }
    });
  };

  console.log('nowProductData?.media[0].url', nowProductData?.media[0].url);

  return (
    <>
      <TopBar title={getTranslatedNameById("TWVudUl0ZW06NDMy")} /> {/* "Write an inquiry" */}
      <section id="questionSection" className="mt50">
        <div className="white-wrap">
          <div className="card order">
            <div className="img-wrap w100 h100">
              <img src={nowProductData?.media[0].url} alt="Product" />
            </div>
            <div className="text-wrap">
              <p className="date">
                {formattedDate} {/* 변환된 날짜 출력 */}
              </p> 
              <p className="brand">
                {nowProductData?.vendor?.storeNameKo}
              </p>
              <p className="name">
                {nowProductData?.name}
              </p>
              <p className="price">
                {window.WSformatPrice(nowProductData?.pricing?.priceRange?.start?.gross?.amount)}
              </p>
            </div>
          </div>
        </div>
        <div className="white-wrap">
          <div className="input-box textarea">
            <label htmlFor="textarea" className="title">
              {getTranslatedNameById("TWVudUl0ZW06NDMz")} {/* "Content" */}
            </label>
            <textarea 
              id="textarea" 
              placeholder={getTranslatedNameById("TWVudUl0ZW06NDM0")}  
              value={title}
              onChange={handleTitleChange}
            />
          </div>
          <input 
            type="file" 
            accept="image/*"
            multiple 
            onChange={handleFileChange} 
            ref={fileInputRef} 
            style={{ display: 'none' }} 
          />
          <button 
            type="button" 
            className="btn white2 big mt16" 
            onClick={handleAttachPhotoClick}
          >
            <span className="icon-camera black"></span>
            {getTranslatedNameById("TWVudUl0ZW06NDM1")} {/* "Attach a photo" */}
          </button>
          <div className="pt16"> 
            <ImageSwipeGridSection photos={photoURLs} onRemovePhoto={removePhoto}/>
          </div>
        </div>
      </section>
      <div className="bottom-bar">
        <div className="bottom-bar-inner pr0 pl0">
          <div className="btn-wrap">
            <button 
              type="button" 
              className="btn purple big" 
              disabled={title === ""}
              onClick={saveQuestion}
            >
              {getTranslatedNameById("TWVudUl0ZW06NDM2")} {/* "Submit" */}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionPage;
