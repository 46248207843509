import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import SampleImage from "../../assets/images/sample/banner.png";
import { fetchOrderById } from "../../services/GQ_apis/order";
import { fetchPages } from "../../services/GQ_apis/page";
import BasicImageSwiperGridSection from "../../sections/grid/BasicImageSwiperGridSection";
import { useLanguage } from '../../services/languageContext';

// 별점 컴포넌트 정의
const StarRating = ({ rating = 0, maxRating }) => {
  const stars = Array.from({ length: maxRating }, (_, index) => index + 1);

  return (
    <div className="stars gap4">
      {stars.map((star) => (
        <a
          key={star}
          href="#none"
          title={`${star}점`}
          className={star <= rating ? "icon-star-fill" : "icon-star-empty"}
        ></a>
      ))}
    </div>
  );
};

const MyReviewDetailSection = () => {
  const location = useLocation();
  const { Page3depth } = useParams();
  const { orderId } = location.state || {}; // state에서 orderId 가져오기
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const { getTranslatedNameById, language } = useLanguage();

  useEffect(() => {
    if (orderId) {
      fetchOrderById(orderId).then((res) => {
        setOrderData(res.data.order);
        setLoading(false);
      });
    }
  }, [orderId]);

  console.log("orderData", orderData);

  useEffect(() => {
    if (Page3depth) {
      fetchPages({
        filter: {
          pageTypes: "UGFnZVR5cGU6MTI=", // 리뷰 페이지 타입
          metadata: [{ key: "page_id", value: Page3depth }]
        },
        first: 20
      })
        .then((pageRes) => {
          setPageData(pageRes.data.pages.edges);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching pages:", error);
        });
    }
  }, [Page3depth]);

  if (loading) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  const currentPageData = pageData.length > 0 ? pageData[0].node : null;
  console.log("currentPageData", currentPageData);

  // 리뷰 작성된 상품과 주문 상품 매칭하기
  const filteredLines = orderData.lines?.filter((line) => {
    const productId = line.variant?.product?.id;
    // 리뷰 상품과 매칭되는 상품만 필터링
    return currentPageData && currentPageData.metafields.product_id === productId;
  }) || [];

  return (
    <>
      <section className="section-mymenu-review-detail">
        <div className="card">
          <div className="order-card">
            {filteredLines.length > 0 ? (
              filteredLines.map((line, index) => (
                <div key={index} className="item-info-wrap">
                  <div className="img-wrap mb10">
                    <Link to={`/product/${line.variant.product.id}`}>
                      <img
                        src={line.thumbnail?.url || SampleImage} // thumbnail이 없으면 기본 이미지 사용
                        alt={line.thumbnail?.alt || "상품 이미지"}
                      />
                    </Link>
                  </div>
                  <div className="text-wrap">
                    <p className="brand">
                      {line.variant.product.vendor?.storeNameKo || "Unknown Brand"}
                    </p>
                    <p className="name">{line.productName}</p>
                    {language === 'KO' ? (
                      <>
                        <p className="price">
                          {line.variant?.product?.pricing?.priceRange ? 
                            window.WSformatPrice(line.variant.product.pricing.priceRange.start.gross.amount) 
                            : '가격 정보 없음'}
                          / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="price">
                          {line.variant?.product?.pricing?.priceRange ? 
                            window.WSformatPrice(line.variant.product.pricing.priceRange.start.gross.amount) 
                            : 'Price info not available'}
                          / {line.quantity} {getTranslatedNameById("TWVudUl0ZW06Mjk4")} {/* 개 */}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>리뷰와 매칭되는 주문 상품이 없습니다.</p>
            )}
          </div>
        </div>
        <div className="white-wrap">
          <div className="card review-detail">
            <div className="stars-wrap">
              {currentPageData ? (
                <>
                  <StarRating rating={currentPageData.metafields.rating} maxRating={5} />
                  <strong className="date">{currentPageData.metafields.publication_date}</strong>
                </>
              ) : (
                <p>리뷰 정보가 없습니다.</p>
              )}
            </div>
            <div className="text-wrap">
              <p className="f14 mb10">{currentPageData ? currentPageData.title : "리뷰가 없습니다."}</p>
              {currentPageData && currentPageData.media && currentPageData.media.length > 0 ? (
                <BasicImageSwiperGridSection photos={currentPageData.media.map((mediaItem) => mediaItem.pageImgUrl)} />
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyReviewDetailSection;
