import React, { useState, useEffect } from "react";
import sampleImage from "../../assets/images/common/icons/icon_profile.svg";
import { fetchPages } from "../../services/GQ_apis/page";
import useProductStore from '../../store/common/useProductStore';
import BasicImageSwiperGridSection from '../../sections/grid/BasicImageSwiperGridSection';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

// 별점 컴포넌트 정의
const StarRating = ({ rating, maxRating }) => {
  const fullStars = Math.floor(rating); // 정수 부분
  const halfStar = rating % 1 !== 0; // 소수점 확인

  return (
    <div className="stars gap4">
      {Array.from({ length: maxRating }, (_, index) => {
        if (index + 1 <= fullStars) {
          return (
            <a key={index} href="#none" title={`${index + 1} stars`} className="icon-star-fill"></a>
          );
        } else if (halfStar && index === fullStars) {
          return (
            <a key={index} href="#none" title={`${index + 0.5} stars`} className="icon-star-half"></a>
          );
        } else {
          return (
            <a key={index} href="#none" title={`${index + 1} stars`} className="icon-star-empty"></a>
          );
        }
      })}
    </div>
  );
};

const Product03Section = () => {
  const [pageData, setPageData] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const { nowProductData } = useProductStore((state) => ({ nowProductData: state.nowProductData }));
  const [photoReviewChecked, setPhotoReviewChecked] = useState(false);
  const [sortOption, setSortOption] = useState('best'); // "베스트순" 또는 "최신순"
  const [isMore, setIsMore] = useState(false);
  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const filter = {
    pageTypes: "UGFnZVR5cGU6MTI=",
    metadata: [{ key: "product_id", value: nowProductData.id }]
  };

  useEffect(() => {
    setIsLoading(true); // 데이터 로딩 시작
    fetchPages({ filter, first: 10, sortOption }).then((res) => {
      const { edges, pageInfo } = res.data.pages;
      setPageData(edges);
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);

      // 리뷰 총 평균 구현
      const totalCount = res.data.pages.totalCount;
      const totalRating = nowProductData.rating;
      const average = totalRating / totalCount;
      console.log('totalRating', totalRating);
      console.log('totalCount', totalCount);
      console.log('average', average);
      setAverageRating(average);
    }).catch(error => {
      console.error('Error fetching pages:', error);
    }).finally(() => {
      setIsLoading(false); // 데이터 로딩 완료
    });
  }, [nowProductData.id, sortOption]);
  
  // 더 많은 리뷰 불러오기 함수
  const loadMoreReviews = () => {
    if (!hasNextPage || isMore) return; // 중복 요청 방지
    
    setIsMore(true);  // 더 많은 리뷰를 불러오는 중이라는 상태 표시
    fetchPages({ filter, first: 10, after: endCursor, sortOption }).then((res) => {
      const { edges, pageInfo } = res.data.pages;
      setPageData((prevData) => [...prevData, ...edges]);
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);
      setIsMore(false);  // 로딩 완료 후 상태 업데이트
      console.log('pageInfo', pageInfo);
      console.log(atob(endCursor));
    }).catch(error => {
      console.error('Error fetching more pages:', error);
      setIsMore(false);  // 에러 발생 시 로딩 상태 종료
    });
  };
  
  // 필터링된 후기 데이터
  const filteredPageData = photoReviewChecked 
    ? pageData.filter(page => page.node.media && page.node.media.length > 0) // 이미지가 있는 후기만 필터링
    : pageData; // 전체 후기

  console.log('pageData', pageData);
  console.log('nowProductData', nowProductData);

  return (
    <>
      <section className="section-product-info">
        <div className="white-wrap">
          <div className="h4-class-wrap flex between">
            <h4 className="title">
              {getTranslatedNameById("TWVudUl0ZW06NDIw")} {/* "Review" */}
              <b className="f-purple">{pageData.length}</b>
            </h4>
          </div>
          {isLoading ? (
            <div className="loading-wrap">
              <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
              </svg>
            </div>
          ) : (
            <>
              <StarRating rating={averageRating} maxRating={5} /> {/* 별점 평균 구현 */}
              <div className="review-option-wrap flex between mt20">
                <div className="input check bg">
                  <label htmlFor="photoReview">
                    <input 
                      type="checkbox" 
                      id="photoReview" 
                      checked={photoReviewChecked} 
                      onChange={(e) => setPhotoReviewChecked(e.target.checked)} 
                    />
                    {getTranslatedNameById("TWVudUl0ZW06NDIx")} {/* "Photo review" */}
                  </label>
                </div>
                <ul className="options">
                  <li className={`option ${sortOption === 'best' ? 'active' : ''}`}>
                    <button type="button" onClick={() => setSortOption('best')}>
                      {getTranslatedNameById("TWVudUl0ZW06NDIy")} {/* "Best ranking" */}
                    </button>
                  </li>
                  <li className={`option ${sortOption === 'latest' ? 'active' : ''}`}>
                    <button type="button" onClick={() => setSortOption('latest')}>
                      {getTranslatedNameById("TWVudUl0ZW06NDIz")} {/* "Latest order" */}
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>

        {!isLoading && filteredPageData.map((page, index) => (
          <div className="card review2 mt20" key={index}>
            <div className="profile-wrap">
              <div className="img-wrap">
                <img src={page?.node?.writer?.metafields?.profileImg || sampleImage} alt="Profile" />
              </div>
              <strong className="f12">
                {page?.node?.writer?.metafields?.nickName?.length > 2 
                ? `${page?.node?.writer?.metafields?.nickName.substring(0,2)}***`
                : page?.node?.writer?.metafields?.nickName || 'Unknown'}
              </strong>
              <span className="date">
                {page?.node?.created 
                  ? new Date(page.node.created).toLocaleDateString('ko-KR', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }).replace(/\./g, '. ').replace(/\.\s?$/, '')  // 마지막 .을 제거
                  : "Unknown date"}
              </span>
            </div>
            {page.node.attributes.length > 0 && <StarRating rating={page.node.attributes[4].values[0].name} maxRating={5} />}

            <div className="review">
              <div className="flex between">
                <p className="f13 pr10">
                  {nowProductData.name}
                </p>
                <StarRating rating={page.node.metafields.rating} maxRating={5} />
              </div>
              <p className="f13 mt6">
                {page.node.metafields.size} / {page.node.metafields.color}
              </p>
              <div className="mt10">
                <BasicImageSwiperGridSection photos={page.node.media.map((mediaItem) => mediaItem.pageImgUrl)} />
              </div>
              <p className="f13 mt20">
                {page.node.title}
              </p>
            </div>
          </div>
        ))}
        <div className="white-wrap">
          {hasNextPage && pageData.length > 0 && !isLoading && (
            <button 
              type="button" 
              className={isMore ? "btn big round more f14 active" : "btn big round more f14"}
              onClick={loadMoreReviews}
            >
              {getTranslatedNameById("TWVudUl0ZW06NDI0")} {/* "View more reviews" */}
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export default Product03Section;
