import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/common/useAuthStore';
import { useLanguage, language } from '../../services/languageContext'; 

// Function to handle registration of the transaction
async function registerTransaction(data) {
  // const url = "https://testpgapi.easypay.co.kr/api/ep9/trades/webpay";
  const url = "https://testpgapi.easypay.co.kr/api/trades/webpay";
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return response.json(); // Parse JSON response
  } catch (error) {
    console.error('Register Transaction Error:', error);
    throw error; // Re-throw to handle it in the calling function
  }
}

const EasyPayCheckoutPage = ({ payData }) => {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const [paymentWindow, setPaymentWindow] = useState(null);
  const [authId, setAuthId] = useState('');
  const { getTranslatedNameById, language } = useLanguage(); 

  const [easyPayData] = useState({
    mallId: 'T0020516', // main mallId 정산몰 (재재)
    shopTransactionId : `${user.id}-${new Date().toISOString().replace(/[^0-9]/g, '').slice(0,14)}`,
    shopOrderNo: `${user.id}-${new Date().toISOString().replace(/[^0-9]/g, '').slice(0, 14)}`,
    approvalReqDate: new Date().toISOString().split('T')[0].replace(/-/g, ''),
  });

  useEffect(() => {
    
    const checkPopupClosed = () => {
      if (paymentWindow && paymentWindow.closed) {
        clearInterval(intervalId);
        if (
          easyPayData &&
          easyPayData.mallId && 
          easyPayData.shopTransactionId &&
          easyPayData.shopOrderNo &&
          easyPayData.approvalReqDate &&
          authId
        ) {
          const params = new URLSearchParams({
            mallId: easyPayData.mallId,
            shopTransactionId: easyPayData.shopTransactionId,
            shopOrderNo: easyPayData.shopOrderNo,
            approvalReqDate: easyPayData.approvalReqDate,
            authorizationId: authId
          }).toString();
          navigate(`/pay/easypay/success?${params}`);
        } else {
          alert('에러발생');
        }
      }
    };

    let intervalId = setInterval(checkPopupClosed, 500);

    return () => clearInterval(intervalId);

  }, [paymentWindow, navigate, payData]);

  // 총 결제 금액과 총 수량 계산
  const totals = useMemo(() => {

    let totalAmount = 0;
    let totalQuantity = 0;
    let totalTaxAmount = 0;

    payData.forEach(checkout => {
      totalAmount += (checkout.totalPrice.gross.amount - checkout.promotionDiscount.amount);
      totalTaxAmount += checkout?.totalPrice?.tax?.amount;
      totalQuantity += checkout.lines.reduce((sum, line) => sum + line.quantity, 0);
    });

    return {
      totalAmount,
      totalQuantity,
      totalTaxAmount
    };
  }, [payData]);


  useEffect(()=>{
    console.log('NOW payData :', payData);
    console.log('totals :', totals);
  },[payData])

  const handlePayment = async () => {
    if (
      !easyPayData.mallId ||
      !easyPayData.shopTransactionId ||
      !easyPayData.shopOrderNo ||
      !easyPayData.approvalReqDate
    ) {
      console.error('Payment data is incomplete:', easyPayData);
      alert('Payment data is incomplete. Please check your input.');
      return;
    }


    // 모든 체크아웃의 basketInfoList를 합치기
    const allBasketInfoList = payData.flatMap(checkout => {
      const items = checkout.lines.map(line => ({
        productNo: line.variant.id,
        productName: line.variant.product.name,
        productAmount: window.WSgetChannel() === 'EN' ? line.totalPrice.gross.amount * 1351.35 : line.totalPrice.gross.amount,
        sellerId: JSON.parse(line.variant.product.vendor.easypaySyncInfo)?.mall_id || "T0020662",
        feeUsed: true,
        feeTypeCode: "P",
        feeCharge: line.variant.product.vendor.commissionData.totalRate * 10000 * 100
      }));

      // 배송비가 있는 경우 이를 basketInfoList에 추가
      if (checkout.shippingPrice && checkout.shippingPrice.gross.amount > 0) {
        items.push({
          productNo: "SHIPPING___" + checkout.shippingPrice.gross.amount,
          productName: "배송비-" + checkout.shippingPrice.gross.amount,
          productAmount: window.WSgetChannel() === 'EN' ? checkout.shippingPrice.gross.amount * 1351.35 : checkout.shippingPrice.gross.amount,
          sellerId: JSON.parse(checkout.lines[0].variant.product.vendor.easypaySyncInfo)?.mall_id || "T0020662",
          feeUsed: true,
          feeTypeCode: "P",
          feeCharge: 3.3 * 10000
        });
      }

      return items;
    });

    const getDeviceType = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
      // PC일 경우만 pc로 인식, 나머지는 모두 mobile
      if (!/android|iPad|iPhone|iPod|Mobile/i.test(userAgent)) {
        return 'pc';
      }
    
      return 'mobile';
    };
    
    const deviceTypeCode = getDeviceType();

    const registerData = {
      mallId: easyPayData.mallId, // 재재 ID
      payMethodTypeCode: '11', // 카드 결제 선택
      currency: "00", // 원화 선택
      amount: window.WSgetChannel() === 'EN' ? totals.totalAmount * 1351.35 : totals.totalAmount, // 총 결제 금액
      clientTypeCode: '00',
      returnUrl: `${process.env.REACT_APP_REST_API_URL}/api-tg/pay/easypay/success${window.location.search}`, // 성공시 Url
      deviceTypeCode: deviceTypeCode,
      shopOrderNo: easyPayData.shopOrderNo,
      // usedSpayCode:['SAM','TOS','APY','NPAY','KKO'],
      orderInfo: {
        goodsName: "ZaeZae Test",
        customerInfo: {
          customerId: user.id,
          customerName: user.name,
        }
      },
      basketInfoList: allBasketInfoList,
      // taxInfo : {
      //   taxAmount:totals.totalAmount - totals.totalTaxAmount,
      //   freeAmount:0,
      //   vatAmount:totals.totalTaxAmount
      // }
    };

    try {
      const registerResult = await registerTransaction(registerData);

      if (registerResult.resCd === '0000' && registerResult.authPageUrl) {

        const authUrl = new URL(registerResult.authPageUrl);
        const urlParams = new URLSearchParams(authUrl.search);
        setAuthId(urlParams.get('authorizationId'));

        console.log('registerData', registerData);
        console.log('registerResult', registerResult);
        console.log('urlParams', urlParams);

        window.location.href = registerResult.authPageUrl;

        // const width = 500;
        // const height = 600;
        // const left = (window.innerWidth / 2) - (width / 2);
        // const top = (window.innerHeight / 2) - (height / 2);
        // const newWindow = window.open(registerResult.authPageUrl, 'PaymentAuthorization', `width=${width},height=${height},top=${top},left=${left}`);
        // setPaymentWindow(newWindow);
      } else {
        alert('Transaction Registration Failed: ' + registerResult.error);
        console.log('registerResult', registerResult);
      }
    } catch (error) {
      alert('Payment processing error: ' + error.message);
      console.log('error', error);
    }
  };

  return (
    <div className="wrapper w-100">
      <div className="max-w-540 w-100">
        <div className="bottom-bar">
          <button type="button" className="btn pay" onClick={handlePayment}>
            <span>{language === 'KO' ? ( <>총 {totals.totalQuantity} 개</>) : (<>Total {totals.totalQuantity} </>)}</span>
            <strong>{window.WSformatPrice(totals.totalAmount)}{language === 'KO' ? "원" : "$"} {language === 'KO' ? ( <>결제하기</>) : (<>Proceed to Payment </>)}</strong>
          </button>
        </div>
      </div>
    </div>
  );
}

export default EasyPayCheckoutPage;
