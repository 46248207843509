import React,{ useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../../store/common/useAuthStore';
import { useLanguage } from '../../../../services/languageContext'; // 언어 컨텍스트 불러오기

const PopupLogout = ({ closePopup }) => {

  const navigate = useNavigate();
  const { logout } = useAuthStore();
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  const handleLogout = () => {
    logout();
    closePopup();
    navigate('/');
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('checkoutToken_')) {
        localStorage.removeItem(key);
      }
    });
  }

  return (
    <>
      <div className="popup-body">
        <div className="h3-title-wrap">
          <h3 className="title">
            {getTranslatedNameById("TWVudUl0ZW06NDE1")} {/* "로그아웃" -> "Logout" */}
          </h3>
          <p className="mt16 f16">
            {getTranslatedNameById("TWVudUl0ZW06NDE2")} {/* "정말로 로그아웃하시겠습니까?" -> "Are you sure you want to log out?" */}
          </p>
        </div>
      </div>
      <div className="popup-footer">
        <div className="btn-wrap">
          <button type="button" className="btn gray" onClick={closePopup}>
            {getTranslatedNameById("TWVudUl0ZW06NDE3")} {/* "취소" -> "Cancel" */}
          </button>
          <button type="button" className="btn purple" onClick={handleLogout}>
            {getTranslatedNameById("TWVudUl0ZW06NDE1")} {/* "로그아웃" -> "Logout" */}
          </button>
        </div>
      </div>
    </>
  );
};

export default PopupLogout;
