import React, { useEffect } from 'react';
import { useLanguage } from '../../services/languageContext'; // 언어 컨텍스트 불러오기

const DaumAddress = ({ onSelectAddress }) => {
  const { getTranslatedNameById } = useLanguage(); // 번역 함수 사용

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    script.onload = () => console.log("Daum Postcode script loaded successfully.");
    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  const handleOpenPostcode = () => {
    new window.daum.Postcode({
      oncomplete: onSelectAddress 
    }).open();
  };

  return (
    <>
      <button type="button" className="btn purple" onClick={handleOpenPostcode}>
        {getTranslatedNameById("TWVudUl0ZW06MzUy")} {/* "우편번호 찾기" */}
      </button>
    </>
  );
};

export default DaumAddress;
