// address.js(배송지관리)
import { gql } from '@apollo/client';
import { client } from '../apolloClient';

// 주소 추가
export function addAddress(input) {
  const ADD_ADDRESS = gql`
    mutation addAddress($input: AddressInput!) {
      accountAddressCreate(input: $input) {
        address {
          id
          firstName
          lastName
          streetAddress1
          streetAddress2
          city
          countryArea
          postalCode
          country {
            code
            country
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: ADD_ADDRESS,
    variables: { input },
  });
}

// 주소 불러오기
export function fetchAddress(addressId) {
  const FETCH_ADDRESS = gql`
    query getAddress($id: ID!) {
      address(id: $id) {
        id
        firstName
        lastName
        streetAddress1
        streetAddress2
        city
        countryArea
        postalCode
        phone
        isDefaultBillingAddress
        isDefaultShippingAddress
        metadata {
          key
          value
        }
        country {
          code
          country
        }
      }
    }
  `;

  return client.query({
    query: FETCH_ADDRESS,
    variables: { id: addressId },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

// 주소 업데이트
export function updateAddress(id, input) {
  const UPDATE_ADDRESS = gql`
    mutation addressUpdate($id: ID!, $input: AddressInput!) {
      addressUpdate(id: $id, input: $input) {
        address {
          id
          firstName
          lastName
          streetAddress1
          streetAddress2
          city
          postalCode
          country {
            code
            country
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: UPDATE_ADDRESS,
    variables: { id, input },
  });
}

// 사용자 주소 업데이트
export function updateAccountAddress(id, input) {
  const UPDATE_ADDRESS = gql`
    mutation accountAddressUpdate($id: ID!, $input: AddressInput!) {
      accountAddressUpdate(id: $id, input: $input) {
        address {
          id
          firstName
          lastName
          streetAddress1
          streetAddress2
          city
          postalCode
          country {
            code
            country
          }
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: UPDATE_ADDRESS,
    variables: { id, input },
  });
}

// 기본 배송지 설정
export function setDefaultAddress(addressId, type) {
  const SET_DEFAULT_ADDRESS = gql`
    mutation setDefaultAddress($id: ID!, $type: AddressTypeEnum!) {
      accountSetDefaultAddress(id: $id, type: $type) {
        errors {
          field
          message
        }
        user {
          id
          email
          defaultShippingAddress {
            id
            streetAddress1
            streetAddress2
            city
          }
          defaultBillingAddress {
            id
            streetAddress1
            streetAddress2
            city
          }
        }
      }
    }
  `;

  return client.mutate({
    mutation: SET_DEFAULT_ADDRESS,
    variables: { id: addressId, type },
  });
}

// 배송지 삭제
export function deleteAddress(addressId) {
  const DELETE_ADDRESS = gql`
    mutation deleteAddress($id: ID!) {
      accountAddressDelete(id: $id) {
        address {
          id
        }
        errors {
          field
          message
          addressType
        }
      }
    }
  `;

  return client.mutate({
    mutation: DELETE_ADDRESS,
    variables: { id: addressId },
  });
}

// 배송지 변경
export function updateCheckoutShippingAddress(checkoutId, newShippingAddress) {

  const UPDATE_SHIPPING_ADDRESS = gql`
    mutation UpdateShippingAddress($checkoutId: ID!, $shippingAddress: AddressInput!) {
      checkoutShippingAddressUpdate(checkoutId: $checkoutId, shippingAddress: $shippingAddress) {
        checkout {
          id
          shippingAddress {
            firstName
            lastName
            streetAddress1
            streetAddress2
            city
            postalCode
            countryArea
            phone
            country {
              code
            }
          }
        }
        errors {
          code
          field
          message
        }
        checkoutErrors {
          field
          message
          addressType
          code
        }
      }
    }
  `;

  return client.mutate({
    mutation: UPDATE_SHIPPING_ADDRESS,
    variables: {
      checkoutId: checkoutId,
      shippingAddress: newShippingAddress
    }

  });
}

// 결제 어드레스 등록
export async function updateBillingAddress(token, billingAddress) {
  const response = await client.mutate({
    mutation: gql`
      mutation UpdateBillingAddress($token: UUID!, $billingAddress: AddressInput!) {
        checkoutBillingAddressUpdate(token: $token, billingAddress: $billingAddress) {
          checkout {
            id
            billingAddress {
              firstName
              lastName
              streetAddress1
              streetAddress2
              city
              postalCode
              country {
                code
              }
              phone
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    variables: {
      token,
      billingAddress
    }
  });

  return response.data;
}
