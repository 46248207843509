import { gql } from '@apollo/client';
import { nonTokenClient } from '../apolloClient';

const GET_MENU_TRANSLATION = gql`
    query GetMenuTranslation($name: String!, $languageCode: LanguageCodeEnum!) {
        menu(name: $name) {
            items {
            id
            name
            translation(languageCode: $languageCode) {
                name
            }
        }
    }
}
`;

export function getMenuTranslation(name, languageCode) {
    return nonTokenClient.query({
        query: GET_MENU_TRANSLATION,
        variables: {
            name,
            languageCode
        }
    });
    
}
