import axios from 'axios';

//// const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';
////const API_URL = process.env.REACT_REST_API_URL || 'http://localhost:8000'; // kdy 개발용
//const API_URL = 'http://3.36.209.168:8000'; // kdy 개발용

//const API_URL = 'http://api.kistor.kr:8000'; // kdy 개발용
const API_URL = 'https://api.kistor.kr'; // kdy 개발용
//const API_URL = process.env.REACT_REST_API_URL || 'http://localhost:8000'; // kdy 개발용

// URL 끝의 '/' 문자 제거
const normalizedAPIUrl = API_URL.replace(/\/+$/, '');

const api = axios.create({
  baseURL: normalizedAPIUrl
});

export default api;