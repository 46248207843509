import { gql } from '@apollo/client';
import { client } from '../apolloClient';

export function meData() {
  const ME_DATA = gql`
    query meData {
      me {
        id
        email
        firstName
        metafields
        isStaff
        defaultShippingAddress {
          id
          city
          cityArea
          streetAddress1
          streetAddress2
        }
        orders(first: 30) {
          edges {
            node {
              id
              created
              status
              paymentStatus
              metafields(keys: "serviceId")
              lines {
                id
                reviewId
                productSku
                quantity
                productName
                thumbnail {
                  alt
                  url
                }
                totalPrice {
                  gross {
                    amount
                  }
                }

                variant {
                  name
                  attributes {
                    values {
                      id
                      name
                    }
                  }
                  
                  preorder {
                    endDate
                  }
                  product {
                    id
                    attributes {
                      values {
                        name
                      }
                      attribute {
                        name

                      }
                    }
                    vendor {
                      id
                      storeNameEn
                      storeNameKo
                    }
                    
                  }
                }

              }
              fulfillments {
                metafields(keys: "serviceId")
              }
              isPaid
              shippingMethod {
                name
                maximumDeliveryDays
              }
            }
          }
        }
        addresses {
          id
          firstName
          city
          cityArea
          countryArea
          country {
            code
            country
          }
          metadata {
            key
            value
          }
          streetAddress1
          streetAddress2
          phone
          postalCode
          isDefaultBillingAddress
          isDefaultShippingAddress
        }
        checkout {
          id
          shippingAddress {
            id
            city
            cityArea
            companyName
            countryArea
            firstName
            isDefaultShippingAddress
            lastName
            metadata {
              key
              value
            }
            phone
            streetAddress1
            streetAddress2
          }
          lines {
            id
            quantity
            variant {
              name
              id
              product {
                name
                thumbnail {
                  url
                  alt
                }
                attributes {
                  values {
                    name
                  }
                  attribute {
                    name
                  }
                }
              }
            }
          }
        }
        checkouts(first: 50) {
          edges {
            node {
              id
              lines {
                id
                quantity
                variant {
                  product {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return client.query({
    query: ME_DATA,
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

export function myReviewData() {
  const MY_REVIEW_DATA = gql`
    query meData {
      me {
        id
        email
        firstName
        metafields
        defaultShippingAddress {
          id
          city
          cityArea
          streetAddress1
          streetAddress2
        }
        orders(first: 100) {
          edges {
            node {
              id
              created
              status
              paymentStatus
              lines(hasReview: true) {
                id
                reviewId
                productSku
                quantity
                productName
                thumbnail {
                  alt
                  url
                }
                totalPrice {
                  gross {
                    amount
                  }
                }

                variant {
                  name
                  attributes {
                    values {
                      id
                      name
                    }
                  }
                  
                  preorder {
                    endDate
                  }
                  product {
                    id
                    attributes {
                      values {
                        name
                      }
                      attribute {
                        name

                      }
                    }
                    vendor {
                      id
                      storeNameEn
                      storeNameKo
                    }
                    
                  }
                }

              }
              fulfillments {
                metafields(keys: "serviceId")
              }
              isPaid
              shippingMethod {
                name
                maximumDeliveryDays
              }
            }
          }
        }
        addresses {
          id
          firstName
          city
          cityArea
          countryArea
          country {
            code
            country
          }
          metadata {
            key
            value
          }
          streetAddress1
          streetAddress2
          phone
          postalCode
          isDefaultBillingAddress
          isDefaultShippingAddress
        }
        checkout {
          id
          shippingAddress {
            id
            city
            cityArea
            companyName
            countryArea
            firstName
            isDefaultShippingAddress
            lastName
            metadata {
              key
              value
            }
            phone
            streetAddress1
            streetAddress2
          }
          lines {
            id
            quantity
            variant {
              name
              id
              product {
                name
                thumbnail {
                  url
                  alt
                }
                attributes {
                  values {
                    name
                  }
                  attribute {
                    name
                  }
                }
                pricing {
                  priceRange {
                    start {
                      gross {
                        currency
                        amount
                      }
                      currency
                    }
                    stop {
                      gross {
                        amount
                        currency
                      }
                      currency
                    }
                  }
                }
              }
            }
          }
        }
        checkouts(first: 50) {
          edges {
            node {
              id
              lines {
                id
                quantity
                variant {
                  product {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return client.query({
    query: MY_REVIEW_DATA,
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

export function meDefaultAddress() {

  const ME_DEFAULT_ADDRESS = gql`
    query meData {
      me {
        defaultShippingAddress {
          id
          firstName
          city
          cityArea
          countryArea
          country {
            code
            country
          }
          metadata {
            key
            value
          }
          streetAddress1
          streetAddress2
          phone
          postalCode
        }
        addresses {
          id
          firstName
          city
          cityArea
          countryArea
          country {
            code
            country
          }
          metadata {
            key
            value
          }
          streetAddress1
          streetAddress2
          phone
          postalCode
          isDefaultBillingAddress
          isDefaultShippingAddress
        }
      }
    }
  `;

  return client.query({
    query: ME_DEFAULT_ADDRESS,
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}