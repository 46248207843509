import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import BrandDetailSection from '../../../sections/brand/BrandDetailSection';
import TopBar from '../../../layouts/top_bar/TitleActionBar';
import BottomNav from '../../../layouts/bottom_bar/BottomNav';
import { getVendorByVid } from '../../../services/REST_apis/vendor';

function BrandDetailPage() {
  const [vendor, setVendor] = useState({});
  const { Page3depth } = useParams();

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const vendorData = await getVendorByVid(Page3depth);
        setVendor(vendorData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchVendorData();
  }, [Page3depth]);

  const channel = window.WSgetChannel();
  const storeName = (channel === 'KO' || !vendor.store_name_en) ? vendor.store_name_ko : vendor.store_name_en;

  return (
    <>
      <TopBar title={storeName || ''} />
      <article id="brandDetailPage">
        <BrandDetailSection store={vendor} />
      </article>
      <BottomNav />
    </>
  );
}

export default BrandDetailPage;
