import React from "react";
import { useParams, useLocation } from 'react-router-dom';

import TopBar from '../../layouts/top_bar/TitleBar';
import { useLanguage } from '../../services/languageContext'; 

import DeliveryListSection from '../../sections/delivery/DeliveryListSection'; 
import DeliveryDomesticSection from '../../sections/delivery/DeliveryDomesticSection'; 
import DeliveryOverseasSection from '../../sections/delivery/DeliveryOverseasSection'; 

function DeliveryPage() {

  const { Page2depth } = useParams();
  const { language } = useLanguage();
  const location = useLocation(); // 현재 경로 확인

  const getTitle = (Page2depth) => {
    switch (Page2depth) {
      default:
        return language === 'KO' ? '배송지 관리' : 'Manage Address';
    }
  };

  console.log('location.state', location);

  let topBarUrl;

  if (Page2depth === 'domestic' || Page2depth === 'overseas') {
    if (location.search === '?from-order') {
      // 'order'에서 왔을 때
      topBarUrl = '/delivery/list?from-order';
    } else {
      // 'list'에서 왔을 때
      topBarUrl = '/delivery/list';
    }
  } else if (Page2depth === 'list') {
    if (location.search === '?from-order') {
      // 'order'에서 왔을 때
      topBarUrl = '/pay/process';
    } else {
      // 'list'에서 왔을 때
      topBarUrl = '/mymenu/profile-modify';
    }
  } else {
    topBarUrl = "";
  }

  return (
    <>
      {
        <TopBar  
          title={getTitle(Page2depth)}
          url={topBarUrl} // 조건부로 URL 설정
        />
      }

      <article 
        id="deliveryPage" 
      >
        {Page2depth === 'list' && <DeliveryListSection/>}
        {Page2depth === 'domestic' && <DeliveryDomesticSection/>}
        {Page2depth === 'overseas' && <DeliveryOverseasSection/>}
      </article>
    </>
  );
}

export default DeliveryPage;
