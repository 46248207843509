// App.js
import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import "./assets/styles/scss/app.scss";
import Init from './components/common/Init';
import PagesRoute from './routes/PagesRoute';
import ToastPopup from './components/Popups/ToastPopup';
import SnackBarPopup from './components/Popups/SnackBarPopup';
import TrackPageChanges from './components/common/TrackPageChanges';
import { LanguageProvider } from './services/languageContext'; // languageProvider import
function App() {
  return (
    <div id="wrap">
      {/* 번역 기능 추가 */}
      <LanguageProvider> 
        <Router>
          <TrackPageChanges /> 
          <Init />
          <PagesRoute />
          <ToastPopup />
          <SnackBarPopup />
        </Router>
      </LanguageProvider>
    </div>
  );
}

export default App;
