import React, { useState, useEffect } from "react";
import OrderStatusCard from '../../components/Card/OrderStatusCard'

const OrderOrderListSection = ({orderList}) => {

  return (
    <>
      <section className="section-mymenu-orderlist">
        <div className="order-list-wrap">
          <ul className="order-list">
            {
              orderList.map((order,index)=>(
                (
                  order.node.status === 'DRAFT' || // 상품 준비중
                  order.node.status === 'UNCONFIRMED' || // 상품 준비중
                  order.node.status === 'UNFULFILLED' || // 상품 준비중
                  order.node.status === 'PARTIALLY_FULFILLED' || // 상품 준비중
                  order.node.status === 'FULFILLED' || // 배송중
                  order.node.status === 'DELIVERED' // 배송완료
                ) 
                ? <OrderStatusCard order={order} index={index}/> :''
                // <div className="mt40 a-center">
                //   {
                //     index < 1 && '주문 정보가 없습니다.'
                //   }
                // </div>
              ))
            }
          </ul>
        </div>
      </section>
    </>
  );
};

export default OrderOrderListSection;

