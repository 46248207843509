import React, {useState, useEffect} from "react";
import { useNavigate, Link } from 'react-router-dom';
import SampleImage from "../../assets/images/common/icons/icon_profile.svg";
import usePopupStore from '../../store/common/usePopupStore';
import { meData } from "../../services/GQ_apis/me";
import { updateChild, getMyChildren } from '../../services/GQ_apis/kid';
import { useLanguage } from '../../services/languageContext';

const MyMenuProfileSection = () => {
  const [me, setMe] = useState({});
  const [kids, setKids] = useState([]);
  const { getTranslatedNameById, language } = useLanguage();

  const { openToastPopup, openSnackBarPopup } = usePopupStore((state) => ({
    openToastPopup: state.openToastPopup,
    openSnackBarPopup: state.openSnackBarPopup,
  }));

  const navigate = useNavigate();

  const selectKid = async (index) => {
    const previousKids = [...kids];

    const newKids = kids.map((kid, i) => ({
      ...kid,
      main: i === index,
    }));
    setKids(newKids);
    console.log('newKids: ', newKids);

    const changedKids = newKids.filter((kid, i) => {
      return kid.main !== previousKids[i].main;
    });

    try {
      const updatePromises = changedKids.map(async (kid) => {
        const response = await updateChild(kid.id, kid);

        const accountChild = response.data.accountChildUpdate;
        if (accountChild.accountErrors.length === 0) {
          console.log(`Kid with ID ${kid.id} updated successfully.`);
        } else {
          console.error(`Error updating kid with ID ${kid.id}`);
        }
      });

      await Promise.all(updatePromises);

      openSnackBarPopup(language === 'KO' ? '메인프로필이 변경되었습니다.' : 'The main profile has been changed.', '', '', '');
    } catch (error) {
      console.error(error);
    }
  };

  // 메뉴 토글
  const toggleMenu = (index) => {
    const newKids = kids.map((kid, i) => ({
      ...kid,
      showMenu: i === index ? !kid.showMenu : kid.showMenu,
    }));
    setKids(newKids);
  };

  useEffect(()=>{
    meData().then((res)=>{
      console.log('meData', res);
      setMe(res.data.me);
    });
  
    getMyChildren().then((res)=>{
      console.log('getMyChildren', res);
      const sortedKids = [...res.data.myChildren].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setKids(sortedKids);
    });
  },[]);
  

  if (!me || !me.metafields) {
    return (
      <div className="loading-wrap">
        <svg className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      </div>
    );
  }

  return (
    <>
      <section className="section-mymenu-profile">
        <div className="my-wrap">
          <div className="profile-wrap">
            <div className="img-wrap">
              <img
                src={
                  me.metafields.profileImg || SampleImage
                }
                alt="유저 이미지"
              />
            </div>
            <div className="text-wrap">
              <div className="title-wrap">
                <strong className="title">
                  {me.firstName}
                </strong>
                <button
                  type="button"
                  className="icon-setting"
                  onClick={() => navigate('/mymenu/profile-modify')}
                >
                </button>
              </div>
              <p className="email">
                {me.email}
              </p>
              <ul className="points-list">
                <li>
                  {/* 적립금
                  <Link to="/mymenu/reward" title="적립금정보 및 링크 이동">
                    <b className="under-line">0</b>
                  </Link> */}
                </li>
                <li>
                  <button
                    // to="/"
                    title=""
                    className="f14"
                    onClick={() => openToastPopup('로그아웃')}
                  >
                    <b className="f-red">Logout</b>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <ul className="actions-list">
            <li>
              <Link to="/coupons/1" state={{ from: 'mypage' }}>
                <span className="icon-coupon color"></span>
                {getTranslatedNameById("TWVudUl0ZW06Mjk5")}
              </Link>
            </li>
            <li>
              <Link to="/mymenu/recent-product">
                <span className="icon-history">
                </span>
                {getTranslatedNameById("TWVudUl0ZW06MzAw")}
              </Link>
            </li>
            <li>
              <Link to="/mymenu/wish-list">
                <span className="icon-hearts">
                </span>
                {getTranslatedNameById("TWVudUl0ZW06MzAx")}
              </Link>
            </li>
            <li>
              <Link to="/mymenu/myreviews">
                <span className="icon-review">
                </span>
                {getTranslatedNameById("TWVudUl0ZW06MzAy")}
              </Link>
            </li>
            <li>
              <Link to="/order/order-list">
                <span className="icon-list">
                </span>
                {getTranslatedNameById("TWVudUl0ZW06MzAz")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="white-wrap my-kids-wrap">
          <div className="h3-title-wrap mt0">
            <h3 className="title">{getTranslatedNameById("TWVudUl0ZW06MzA0")}</h3>
            <Link
              className="more-btn"
              title=""
              to="/register/kid-1"
            >
              {getTranslatedNameById("TWVudUl0ZW06MzA1")}
            </Link>
          </div>
          {
            kids.length <= 0 &&
            <div>
              {getTranslatedNameById("TWVudUl0ZW06MzA2")}
            </div>
          }
          <ul className="my-kids-list type-star">
            {
              kids.map((kid, index) => (
                <li
                  className="kid"
                  key={index}
                  onClick={() => navigate(`/mymenu/mykid-detail/${kid.id}`)}
                >
                  <div className="img-wrap">
                    <img className="circle-img" src={
                      kid.thumbnail || SampleImage
                    } alt="하늘이 이미지" />
                    <span className="badge purple">{kid.status}</span>
                  </div>
                  <div className="text-wrap">
                    <div className="title-wrap">
                      <strong className="title">{kid.name}
                        {/* <span className="info">정보입력 23.07.06</span> */}
                      </strong>
                      <div className="actions-wrap">
                        <button
                          type="button"
                          className={`icon-star-empty gray ${kid.main ? 'active' : ''}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            selectKid(index);
                          }}
                        >
                        </button>
                        <div className="more-dots-wrap">
                          <button
                            type="button"
                            className="icon-more-dots"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleMenu(index)
                            }}
                          >
                          </button>
                          {kid.showMenu && (
                            <>
                              <ul className="more-dots-list">
                                <li>
                                  <Link
                                    to={`/mymenu/mykid-modify/${kid.id}`}
                                    title=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleMenu(index)
                                    }}
                                  >
                                    {getTranslatedNameById("TWVudUl0ZW06MzA3")}
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    title=""
                                    href="#"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleMenu(index)
                                      openToastPopup('아이삭제', kid.id);
                                    }}
                                  >
                                    {getTranslatedNameById("TWVudUl0ZW06MzA4")}
                                  </a>
                                </li>
                              </ul>
                              <div
                                className="dim white"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleMenu(index)
                                }}
                              ></div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="span-wrap">
                        {/* <span className="age">첫째 (만) 9세</span> */}
                        <span className="date">{window.WSdate(kid.birthDate)}</span>
                      </div>
                    </div>
                    <ul className="body-list">
                      <li><span>{getTranslatedNameById("TWVudUl0ZW06MjY5")}</span><b>{kid.height}cm</b></li>
                      <li><span>{getTranslatedNameById("TWVudUl0ZW06Mjcw")}</span><b>{kid.weight}kg</b></li>
                      <li><span>{getTranslatedNameById("TWVudUl0ZW06Mjcy")}</span><b>{kid.footSize}cm</b></li>
                      <li><span>{getTranslatedNameById("TWVudUl0ZW06Mjcx")}</span><b>{kid.headSize}cm</b></li>
                    </ul>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </section>
    </>
  );
};

export default MyMenuProfileSection;
