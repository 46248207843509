import { gql } from '@apollo/client';
import { client, nonTokenClient } from '../apolloClient';

export function fetchPages({ filter, first, after, sortOption }) {
  const GET_PAGES = gql`
    query GetPages($first: Int!, $after: String, $filter: PageFilterInput, $sortBy: PageSortingInput) {
      pages(first: $first, after: $after, filter: $filter, sortBy: $sortBy) {
        edges {
          node {
            id
            title
            metadata {
              key
              value
            }
            content
            contentJson
            isPublished
            publicationDate
            metafields
            created
            writer {
              id
              email
              metafields(keys: ["nickName", "b_no", "profileImg"])
            }
            media {
              createdAt
              etc
              etc2
              id
              pageImgUrl
              productDescription
              updatedAt
            }
            pageType {
              name
              id
            }
            attributes {
              attribute {
                name
                id
              }
              values {
                name
                plainText
                reference
                externalReference
                value
                file {
                  url
                  contentType
                }
              }
            }

          }
        }
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }`
  ;

  return client.query({
    query: GET_PAGES,
    variables: {
      first: first || 20,
      after: after || null,
      filter: filter || {},
      sortBy: sortOption === 'latest'
        ? { direction: 'DESC', field: 'CREATED_AT' } // 최신순 정렬
        : { direction: 'DESC', field: 'RATING' }    // 베스트순 정렬 (RATING 필드가 필요)
    },
    fetchPolicy: 'network-only'
  });
}

// 상품 rating update 뮤테이션
export function updateProduct({ id, rating }) {
  const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($id: ID!, $input: ProductInput!) {
      productUpdate(id: $id, input: $input) {
        product {
          id
          name
          rating
        }
        errors {
          field
          message
        }
      }
    }
  `;

  return client.mutate({
    mutation: UPDATE_PRODUCT,
    variables: {
      id: id, // 이곳에서 ID 변수를 전달
      input: {
        rating: rating // 업데이트할 rating 전달
      }
    },
    fetchPolicy: 'no-cache'
  });
}

// 상품 rating 가져오는 뮤테이션
export function fetchProductRating(id) {
  const FETCH_PRODUCT_RATING = gql`
    query FetchProductRating($id: ID!) {
      product(id: $id) {
        id
        name
        rating
      }
    }
  `;

  return client.query({
    query: FETCH_PRODUCT_RATING,
    variables: {
      id: id // 상품의 ID를 전달
    },
    fetchPolicy: 'no-cache'
  });
}

// 리뷰
export function createReview(productId, text, rating) {
  const CREATE_REVIEW = gql`
    mutation CreateReview($productId: ID!, $text: String!, $rating: Int!) {
      reviewCreate(input: {productId: $productId, text: $text, rating: $rating}) {
        errors
        review {
          id
        }
      }
    }
  `;

  return client.mutate({
    mutation: CREATE_REVIEW,
    variables: {
      productId,
      text,
      rating
    },
    fetchPolicy: 'network-only'
  });
}

export function getReviews({filtering, first}) {
  const GET_REVIEWS = gql`
    query GetReviews(
      $first: Int!, 
      $after: String, 
      $filter: ReviewFilterInput
    ) {
      reviews(
        first: $first, 
        after: $after, 
        filter: $filter
      ) {
        edges {
          node {
            id
            text
            rating
            createdAt
            user {
              id
              email
              metafields
            }
            product {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  `;

  return client.query({
    query: GET_REVIEWS,
    variables: {
      first: first || 20, // 기본값을 지정
      after: null,  // 이 값은 페이징을 위한 커서 값으로, 필요에 따라 업데이트할 수 있습니다.
      filter: filtering
    },
    fetchPolicy: 'network-only'  // 매번 서버로부터 데이터를 새로 가져오도록 설정
  });
}

// 문의
// 페이지 생성 뮤테이션
export function createPage(title, pageTypeId, publicationDate) {
  const CREATE_PAGE = gql`
  mutation CreatePage($title: String!, $pageType: ID!, $publicationDate: String!) {
    pageCreate(
      input: {title: $title, pageType: $pageType, publicationDate: $publicationDate}
    ) {
      page {
        id
        title
        pageType {
          name
          id
        }
      }
        
    }
  }
  `;

  return client.mutate({
    mutation: CREATE_PAGE,
    variables: {
      title,
      pageType: pageTypeId,
      publicationDate,
    },
    fetchPolicy: 'network-only'
  });
}

// 메타데이터 업데이트 뮤테이션
export function updateMetadata(id, input) {
  const UPDATE_METADATA = gql`
  mutation UpdateMetadata($id: ID!, $input: [MetadataInput!]!) {
    updateMetadata(id: $id, input: $input) {
      errors {
        code
        field
      }
    }
  }
  `;

  return client.mutate({
    mutation: UPDATE_METADATA,
    variables: {
      id,       
      input: input  
    },
    fetchPolicy: 'network-only'
  });
}

// 페이지 이미지 등록 뮤테이션
export function createPageMedia(productId, pageId, image) {
  const CREATE_PAGE_MEDIA = gql`
  mutation CreatePageMedia($productId: ID!, $pageId: ID!, $image: Upload!) {
    pageMediaCreate(
      input: {productId: $productId, pageId: $pageId, image: $image}
    ) {
      pageMedia {
        createdAt
        id
        pageImgUrl
        updatedAt
        productDescription
        etc
      }
      pageErrors {
        attributes
        code
        message
        field
        values
      }
      errors {
        attributes
        code
        field
        message
        values
      }
    }
  }
  `;
  return client.mutate({
    mutation: CREATE_PAGE_MEDIA,
    variables: {
      productId,
      pageId,
      image
    },
    context: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    fetchPolicy: 'network-only'
  });
}

// 상품 문의 가져오기
export function getQuestion(filtering) {
  const GET_QUESTION = gql`
    query GetQuestion(
      $first: Int!, 
      $after: String, 
      $filter: ReviewFilterInput
    ) {
      reviews(
        first: $first, 
        after: $after, 
        filter: $filter
      ) {
        edges {
          node {
            id
            text
            rating
            createdAt
            user {
              id
              email
              metafields
            }
            product {
              id
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  `;

  return client.query({
    query: GET_QUESTION,
    variables: {
      first: 10,
      after: null,  // 이 값은 페이징을 위한 커서 값으로, 필요에 따라 업데이트할 수 있습니다.
      filter: filtering
    },
    fetchPolicy: 'network-only'  // 매번 서버로부터 데이터를 새로 가져오도록 설정
  });
}