import api from '../api'

export const createProductLog = async (productId, vendorId, logType, uid, ref) => {
  try {

    const response = await api.post('/api-tg/log/create', {
      pid: productId,
      vid: vendorId,
      type: logType,
      uid, 
      ref 
    });
    return response;
  } catch (error) {
    console.error('Error creating product log:', error);
    throw error;
  }
};