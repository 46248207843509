import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../services/languageContext';

const SearchProcessSection = () => {
  const [recentSearches, setRecentSearches] = useState([]);
  const navigate = useNavigate(); // useNavigate 훅 사용
  const { getTranslatedNameById, language } = useLanguage();

  // 컴포넌트가 마운트될 때 localStorage에서 최근 검색어 목록을 불러옴
  useEffect(() => {
    const searches = JSON.parse(localStorage.getItem('recentSearches')) || [];
    setRecentSearches(searches);
  }, []);

  // 검색어 버튼 클릭 시 검색 결과 페이지로 이동하는 함수
  const handleSearchClick = (searchTerm) => {
    navigate(`/search/result?query=${encodeURIComponent(searchTerm)}`);
  };

  // 검색어 삭제 함수
  const handleDeleteSearch = (indexToDelete, event) => {
    event.stopPropagation(); 
    const updatedSearches = recentSearches.filter((_, index) => index !== indexToDelete);
    setRecentSearches(updatedSearches);
    localStorage.setItem('recentSearches', JSON.stringify(updatedSearches)); // localStorage 업데이트
  };

  return (
    <>
      <section className="section-search">
        <div className="inner">
          <h4 className="title">
            {getTranslatedNameById("TWVudUl0ZW06NDY3")}
          </h4>
          <div className="category-tabs-wrap recent-search">
            <ul className="tabs">
              {recentSearches.length > 0 ? (
                recentSearches.map((search, index) => (
                  <li key={index} className="search-item">
                    <button 
                      type="button" 
                      className="tab"
                      onClick={() => handleSearchClick(search)} // 검색어 클릭 시 이동
                    >
                      {search}
                      <span 
                        className="delete-x ml10" 
                        onClick={(e) => handleDeleteSearch(index, e)} // x 클릭 시 삭제
                      >
                        &times;
                      </span>
                    </button>
                  </li>
                ))
              ) : (
                <li>{getTranslatedNameById("TWVudUl0ZW06NDY4")}</li>
              )}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchProcessSection;
