import { gql } from '@apollo/client';
import { client, nonTokenClient } from '../apolloClient';

const GET_CATEGORY = gql`
  query  GetCategory($level: Int!, $parentId: String) {
    categories(first: 100, level: $level, filter: {parentId:$parentId}) {
      edges {
        node {
          id
          decryptId
          level
          name
          translation(languageCode: EN) {
            id
            name
          }
          parent {
            id
          }
        }
      }
    }
  }
`;

export function getCategory(level, parentId) {
  return nonTokenClient.query({
    query: GET_CATEGORY,
    variables: {
      level,
      parentId
    },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}

const GET_CATEGORY2 = gql`
  query GetCategory2($level: Int!, $search: String) {
      categories(first: 30, level: $level, filter: {search:$search}) {
        edges {
          node {
            id
            decryptId
            level
            name
            parent {
              id
            }
          }
        }
      }
    }
`;

export function getCategory2(level, search) {
  return nonTokenClient.query({
    query: GET_CATEGORY2,
    variables: {
      level,
      search
    },
    fetchPolicy: 'network-only' // 서버에서 항상 최신 정보를 가져오도록 설정
  });
}